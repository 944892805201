import React, { Component } from 'react';
import { render } from "react-dom";
import { ShowBigImage } from '../Utils/Dialog/BigImageDialog';

import {
    YMaps,
    Map,
    TypeSelector,
    ZoomControl,
    FullscreenControl,
    Placemark,
    GeolocationControl
} from 'react-yandex-maps';
import { Button } from "react-bootstrap";
import { getString, strings } from "../Languages/Languages";
import { ServerApi } from "../Server/ServerApi";
import { User } from "../User/User";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {ShowInfrastructureMapDialog} from "../Utils/Dialog/InfrastructureMapDialog";


let a = [];
let b = [];


let serverApi = new ServerApi();

class InfrastructureMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            center: [55.59587, 38.47261],
            zoom: 10,
            type: 'yandex#map',
            bounds: undefined,
            range: 1000,
            coordinates: [],
            height: '500px',
            lon: 0.0,
            lat: 0.0,
            controls: [],
            nodeData: 0.0,
            name: '',
            filename: [],
            nodeDataId: [],
            imgArr: [],
            commArr: [],
        }
        this.nodeAttachGet()
    }

    back = () => {
        window.history.back()
        localStorage.removeItem('lat')
        localStorage.removeItem('lon')
    }

    nodeAttachGet = () => {
       serverApi.getNodeId(User.nodes).then((response) => {
            for (let i = 0; i < response.length; i++) {
                let id = response[i].nodeData.id

                serverApi.attachget(id).then((response) => {
                    b.push({ id: id, data: response.data })
                    this.setState({ filename: b });
                })
                a.push(response[i].nodeData.id)
            }
            this.setState({
                nodeDataId: a
            })
        })
            .catch((error) => {
                console.log(error)
            })
    }

    componentDidMount() {
        let height = document.documentElement.clientHeight
        height = height - 85
        let lat;
        let lon;

        let cor;
        cor = [55.59587, 38.47261];
        let zoom;
        zoom = 10

        serverApi.getNodeId(User.nodes).then((response) => {
            for (let responseKey in response) {
                let names = response[responseKey].nodeData.Name
                let div = document.querySelector('.nData');
                div.innerHTML += `<button  data-dismiss="modal" id=${responseKey} class="InfrastructureButton"
                   data-value="InfrastructureButton" >${names}</button>`
            }
            let handleClick = (event) => {

                let targets = event.target.textContent;
                for (let i = 0; i < response.length; i++) {
                    if (response[i].nodeData.Name === targets) {

                        let array = response[i].nodeData
                        let name = array.Name
                        let notes = array.Zametki
                        let mufta = array.additional_data
                        let clutch;
                        for (let aaaKey in mufta) {
                            clutch = mufta[aaaKey]
                        }

                        let entrance = array.Exit
                        let floor = array.Floor

                        let id = array.id
                        let addressId = array.address_id

                        serverApi.getinfrasAddress(addressId).then(result => {
                            let addressData = result.data
                            for (let addressKey in addressData) {
                                let address = addressData[addressKey].name
                                this.setState({ address });
                            }
                        })
                            .catch((error) => {
                                console.log(error)
                            })
                        let keyArr = [];
                        for (let i = 0; i < this.state.filename.length; i++) {
                            if (this.state.filename[i].id === id) {
                                Object.keys(this.state.filename[i].data).forEach(function (key) {
                                    keyArr.push(key)
                                }, this.state.filename[i].data);
                            }
                        }

                        let bases = [];
                        let basesFull = [];
                        let comm = [];
                        for (let i = 0; i < keyArr.length; i++) {
                            serverApi.getImage(keyArr[i]).then(r => {
                                bases.push(r['Low']);
                                basesFull.push(r['Full']);
                                comm.push(r['comment'])
                                this.setState({ imgArr: bases });
                                this.setState({ commArr: comm });
                            });
                        }

                        lat = response[i].lat;
                        lon = response[i].lon;
                        cor = [lat,lon]
                        zoom = 15
                        console.log(lat)
                        this.setState({ lat, lon, name, notes, entrance, floor, clutch, cor, zoom });
                    }
                }
            }
            let balloon = document.querySelectorAll('.InfrastructureButton')
            balloon.forEach(balloon => {
                balloon.addEventListener('click', handleClick)
            })
        });
        const showFoto = () => {

            let str = ''
            for (let i = 0; i < this.state.imgArr.length; i++) {
                str += `<img class="imgInfra" data-nam="imgInfra" src=${this.state.imgArr[i]}  style="width: 100%; cursor: pointer" data-comm=${this.state.commArr[i]}>`
            }
            return str;
        }
        document.addEventListener("click", function (event) {
            if (event.target.className === 'showFotoClass') {
                const s = document.querySelector('.balloonInfa')
                s.innerHTML += showFoto()
            }
            if (event.target.className === 'hideFotoClass') {
                const s = document.querySelector('.balloonInfa')
                s.innerHTML = ''
            }
            if (event.target.dataset.nam === 'Navigator') {
                navigator.geolocation.getCurrentPosition(function (position) {
                    let latGPS = position.coords.latitude
                    let lonGPS = position.coords.longitude
                    document.location.href = "yandexmaps://maps.yandex.ru/?rtext=" + latGPS + "," + lonGPS + "~" + lat + "," + lon;
                });
            }
            if (event.target.dataset.nam === 'imgInfra') {
                let srcArr = [];
                let commArr = [];
                let imgArr = [...document.querySelectorAll('.imgInfra')]
                imgArr.forEach((i) => srcArr.push(i.src))
                let imgCommArr = [...document.querySelectorAll('.imgInfra')]
                imgArr.forEach((i) => commArr.push(i.dataset.comm))
                render(ShowBigImage(srcArr, [], commArr, event.target.getAttribute("src")), document.querySelector('.bigImageContainer'));
            }
            if (event.target.dataset.nam === 'imgInfraBtnClose') {
                const d = document.querySelector('.first');
                d.innerHTML = ''
            }
        })
        this.setState({height, cor, zoom});
    }

    render() {
        return (

            <div className={"InfrastructureMapForm"} >
                <div className='bigImageContainer ' style={{ position: 'absolute', zIndex: '1000', margin: '0', width: '100%', backgroundColor: 'rgba(33,31,31,0.5)' }}>
                </div>
                <YMaps query={{ apikey: '3261ce08-c60a-4114-96f8-ce820abf124a' }}>
                    <div>
                        <Map
                            /*state={this.state}*/
                            state={{ center:this.state.cor, zoom: this.state.zoom,
                                type: 'yandex#map',
                                bounds: undefined }}
                             height={this.state.height}
                             width='100%'
                             id={"mapId"}
                        >

                            <ZoomControl options={{ float: 'right' }} />
                            <TypeSelector options={{ float: 'right' }} />
                            <Placemark
                                geometry={[this.state.lat, this.state.lon]}
                                options={{
                                    openBalloonOnClick: true,
                                    mapStateAutoApply: true
                                }}
                                onClick={() => {ShowInfrastructureMapDialog(() => {},this.state.name,
                                    this.state.address, this.state.entrance,
                                    this.state.floor, this.state.notes, this.state.clutch,
                                    <div>
                                        <button className="showFotoClass">{getString(strings.ShowImages)}</button>
                                        <button className="hideFotoClass">{getString(strings.HideImages)}</button>
                                    </div>,
                                    <button data-nam='Navigator' className={"Navigator"}>{getString(strings.Navigator)}</button>)}
                                }
                            />
                            <FullscreenControl />
                            <GeolocationControl options={{ float: 'left' }} />
                        </Map>
                        <br />
                        <div>
                            <div>
                                <Button onClick={this.back}
                                        className={"YamapBack"}>{getString(strings.Back)}</Button>
                                <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
                                    <FontAwesomeIcon icon={faBars} />
                                </button>
                            </div>
                            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel"
                                 aria-hidden="true">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <br />
                                        <div id={"nData"} className={'nData'}>
                                        </div>
                                        <br /><br />
                                        <div>
                                            <button type="button" className="btn btn-dangerInfrastructure" data-dismiss="modal">{getString(strings.Cancel)}</button>
                                        </div>
                                        <p />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </YMaps>
            </div>
        );
    }
}

export default InfrastructureMap;

