import React from "react";
import taskAddressIcon from '../../../../Resources/Icons/AboutTask/adress.png';
import taskNotesIcon from '../../../../Resources/Icons/AboutTask/dossier.png';
import taskExitsIcon from '../../../../Resources/Icons/AboutTask/exit 1.png';
import taskKeysIcon from '../../../../Resources/Icons/AboutTask/key.png';
import taskWorkNotesIcon from '../../../../Resources/Icons/AboutTask/paper.png';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome, faMapMarkerAlt, faQuestionCircle} from "@fortawesome/free-solid-svg-icons";

export default function AddressPanel(props) {

  return (
    <div className="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
      {(props["Address"] + '').replace(' ', '') !== '' &&
        <div className="col-sm-12">
          <label className="float-left text-primary mb-0">{props.taskAddress}</label>
          <div className="input-group mb-2">
            <div className="input-group-prepend">
              <span className="input-group-text bg-primary"><img src={taskAddressIcon} alt={taskAddressIcon} width="20px" /></span>
            </div>
            <div className={"form-control h-auto smallText card d-block border-1  px-2 py-2 text-left"}>{props.decodeHtmlCharCodes}</div>
              <div className="input-group-append"
                   onClick={() => {window.open("/addressonmap", '_self', false)}}>
                                                    <span className="input-group-text Colors">
                                                        <FontAwesomeIcon icon={faMapMarkerAlt}/>
                                                    </span>
              </div>
          </div>
        </div>}
      <div id={"notesHolder"} className="col-sm-12">
        <label className="float-left text-primary mb-0">{props.taskNotes}</label>
        <div className="input-group mb-2">
          <div className="input-group-prepend">
            <span className="input-group-text bg-primary"><img src={taskNotesIcon} alt={taskNotesIcon} width="20px" /></span>
          </div>
          <div id={"notes"} className={"form-control h-auto smallText card d-block border-1  px-2 py-2 text-left"} />
        </div>
      </div>
      <div id={"exitsHolder"} className="col-sm-12">
        <label className="float-left text-primary mb-0">{props.taskExits}</label>
        <div className="input-group mb-2">
          <div className="input-group-prepend">
            <span className="input-group-text bg-primary"><img src={taskExitsIcon} alt={taskExitsIcon} width="20px" /></span>
          </div>
          <div id={"exits"} className={"form-control h-auto smallText card d-block border-1  px-2 py-2 text-left"} />
        </div>
      </div>
      <div id={"keysHolder"} className="col-sm-12">
        <label className="float-left text-primary mb-0">{props.taskKeys}</label>
        <div className="input-group mb-2">
          <div className="input-group-prepend">
            <span className="input-group-text bg-primary"><img src={taskKeysIcon} alt={taskKeysIcon} width="20px" /></span>
          </div>
          <div id={"keys"} className={"form-control h-auto smallText card d-block border-1  px-2 py-2 text-left"} />
        </div>
      </div>
      <div id={"workNoteHolder"} className="col-sm-12">
        <label
          className="float-left text-primary mb-0">{props.taskWorkNotes}</label>
        <div className="input-group mb-2">
          <div className="input-group-prepend">
            <span className="input-group-text bg-primary"><img
              src={taskWorkNotesIcon} alt={taskWorkNotesIcon}
              width="20px" /></span>
          </div>
          <div id={"workNote"}
            className={"form-control h-auto smallText card d-block border-1  px-2 py-2 text-left"} />
        </div>
      </div>
    </div>
  )
}