import React from "react";
import backArrow from "../Resources/Icons/back-arrow.svg";
import {ShowDialog} from "../Utils/Dialog/Dialog";
import {getString, strings} from "../Languages/Languages";
import {ServerApi} from "../Server/ServerApi";
import {User} from "../User/User";
import {CloseLoading, ShowLoading} from "../Utils/Dialog/LoadingDialog";

function InventoryGetAmountStaffRes(response,sectionCatalog,inventoryCatalog) {
    let inventory = response
    if (inventory.data == ''){
        document.getElementById('sectionCatalogID').hidden = true
                  ShowDialog({
                      'title': getString(strings.Attention),
                      'body': getString(strings.YouDontEquipment),

                      'pButtonClick': function () {},

                      'pbtn': 'true',
                      'nebtn': 'false',

                      'pbtntitle': getString(strings.Ok),
                  });
    }
    if (inventory.result === 'OK') {
        for (let section in sectionCatalog.data) {
            let sectionId = sectionCatalog.data[section]['id']
            let sectionName = sectionCatalog.data[section]['name']

            let a = document.querySelector('#sectionCatalogID')
            a.innerHTML += `<div id=${sectionId}>
                            <div class="sectionCatalog">${sectionName}</div>
                            <div class="container">
                                <div class="row align-items-start">
                                     <div id=${"Description" + sectionId} class="col divCol">
                                     ${getString(strings.Name)}</p>
                                     </div>
                                     <div id=${"Date" + sectionId} class="col divCol">
                                     ${getString(strings.Date)}</p>
                                     </div>
                                     <div id=${"Room" + sectionId} class="col divCol">
                                     ${getString(strings.SerialNumber)}</p>
                                     </div>
                               </div>
                            </div>
                            </div>`

            document.getElementById(sectionId).hidden = true
            for (let catalogElement in inventoryCatalog.data) {
                let catalogElem = inventoryCatalog.data[catalogElement]

                if (sectionId === catalogElem['inventory_section_catalog_id']) {
                    for (let inventoryItem in inventory.data) {
                        let invItem = inventory.data[inventoryItem]
                        if (catalogElem.id === invItem['catalog_id']) {

                            let Hidden = catalogElem['inventory_section_catalog_id']
                            document.getElementById(Hidden).hidden = false

                            let name = catalogElem["name"]
                            name = name.substr(0, 15)

                            let document_date = invItem["document_date"]
                            let serial_number = invItem["serial_number"]
                            serial_number = serial_number.substr(0, 18)

                            let Description = document.getElementById('Description' + sectionId)
                            Description.innerHTML += `<div class="columns">${name}</div>`

                            let Date = document.getElementById('Date' + sectionId)
                            Date.innerHTML += `<div class="columns">${document_date}</div>`

                            let Room = document.getElementById('Room' + sectionId)
                            Room.innerHTML += `<div class="columns">${serial_number}</div>`
                        }
                    }
                }
            }
        }
        CloseLoading();
    }
}
function TMCemployee()
{
    let serverApi = new ServerApi();
    serverApi.getinventorysectioncatalog().then((sectionCatalog) => {

        if (sectionCatalog.result === 'OK'){
            console.log(sectionCatalog)

            serverApi.getinventorycatalog().then((inventoryCatalog) => {
                if (inventoryCatalog.result === 'OK'){
                    ShowLoading();
                    serverApi.getInventoryamount(User.employeeId).then((response) => {
                        InventoryGetAmountStaffRes(response,sectionCatalog,inventoryCatalog)
                    })
                }
            })
        }
    })
    return (
        <div>
            <div id={'sectionCatalogID'}>
            </div>
            <br/> <br/> <br/>
            <nav className="navbar fixed-bottom navbar-light bg-white shadow-lg">
                <div className="float-left" >
                    <a id={"backBtn"} onClick={() => {window.open("/main", '_self', false)}}
                       className="navbar-brand text-primary mx-2"><img src={backArrow} alt={backArrow} width={"25px"}/></a>
                </div>
            </nav>
        </div>
    );
}
export default TMCemployee;