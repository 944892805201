import React, {Component} from 'react';
import {ServerApi} from "../Server/ServerApi";
import {User} from "../User/User";
import {YMaps, Map, Placemark, Polyline , Clusterer , TypeSelector, ZoomControl, FullscreenControl,GeolocationControl, RouteButton} from 'react-yandex-maps';
import {Button} from "react-bootstrap";
import {getString, strings} from "../Languages/Languages";
import {ShowDot} from "../Utils/Dialog/DotDialog"
//c547e3f2-a1e7-439d-9dd4-a49b2bacaf9f ключ яндекса

let commentsAlreadyDownloaded = false;
let mas = []

let id_temo = User.code
let mas_dop = []
let mas_dop_com = []
let text_start_point,text_end_point

function text_vid(text){
    let serverApi = new ServerApi();
    serverApi.list_lines(id_temo).then(result1 => {
        let objectA = result1.data[id_temo].object_a_name
        let objectB = result1.data[id_temo].object_b_name

        if(text === objectA){
            ShowDot(() => {
                let latA = result1.data[id_temo].path[0].lat
                let lonA = result1.data[id_temo].path[0].lon
                navigator.geolocation.getCurrentPosition(function(position) {
                    let latGPS = position.coords.latitude
                    let lonGPS = position.coords.longitude
                    document.location.href = "yandexmaps://maps.yandex.ru/?rtext=" + latGPS + "," + lonGPS + "~" + latA +"," + lonA;
                });
            }, objectA,getString(strings.StartDot))
        }

        if(text === objectB){
            ShowDot(() => {
                let latB = result1.data[id_temo].path.slice(-1)[0].lat
                let lonB = result1.data[id_temo].path.slice(-1)[0].lon
                navigator.geolocation.getCurrentPosition(function(position) {
                    let latGPS = position.coords.latitude
                    let lonGPS = position.coords.longitude
                    document.location.href = "yandexmaps://maps.yandex.ru/?rtext=" + latGPS + "," + lonGPS + "~" + latB +"," + lonB;
                });
            }, objectB, getString(strings.FinishDot))
        }
    })
}

function comment_vid(objec){
    let length = objec.reserve_length
        ShowDot(() => {
            let coordinates = objec.additional_data.coordinates.split(',');
            let reserveLat = coordinates[0]
            let reserveLon = coordinates[1]
            navigator.geolocation.getCurrentPosition(function(position) {
                let latGPS = position.coords.latitude
                let lonGPS = position.coords.longitude
                document.location.href = "yandexmaps://maps.yandex.ru/?rtext=" + latGPS + "," + lonGPS + "~" + reserveLat +"," + reserveLon;
            });
        },getString(strings.Stock) + length, getString(strings.ReserveLength))
}

class ObjectsTask extends Component {
    state = {
        center: [55.595653, 38.472397],
        zoom: 10,
        type: 'yandex#map',
        bounds: undefined,
        range: 1000,
        coordinates: [],
        height: '500px',
        lon: 0.0,
        lat: 0.0,
        controls: [],
        mas_dop: [],
    }

    componentDidMount() {
        navigator.geolocation.getCurrentPosition(function(position) {});
        navigator.geolocation.getCurrentPosition(function(position) {});

        let height = document.documentElement.clientHeight
        height = height - 76
        let cor;
        cor =[55.595653, 38.472397]
        let zoom;
        zoom = 10;

        let serverApi = new ServerApi();
        serverApi.list_lines(id_temo).then(result1 => {
            if (result1.data[id_temo].path[0].lat != '' || result1.data[id_temo].path[0].lat != undefined &&
                result1.data[id_temo].path[0].lon != '' || result1.data[id_temo].path[0].lon != undefined ){
                cor = [result1.data[id_temo].path[0].lat, result1.data[id_temo].path[0].lon]
                zoom = 15;
                this.setState({cor,zoom})
            } else {
                cor = [55.595653, 38.472397]
                zoom = 10;
                this.setState({cor,zoom})
            }
            this.setState({cor,zoom})

            for (let k2 in result1.data[id_temo].path){
                let temp_mas = []
                temp_mas[0] = result1.data[id_temo].path[k2]['lat']
                temp_mas[1] = result1.data[id_temo].path[k2]['lon']
                mas.push(temp_mas)
            }
            for (let k in result1.data[id_temo].route_object){
                let temp_m = []
                temp_m = result1.data[id_temo].route_object[k].additional_data.coordinates.split(',')

                mas_dop_com[k] = result1.data[id_temo].route_object[k]
                for (let k1 in temp_m){
                    temp_m[k1] = Number(temp_m[k1])
                }
                mas_dop.push(temp_m)

            }

            text_start_point = result1.data[id_temo].start_point_name
            text_end_point = result1.data[id_temo].finish_port_name

            let latA = result1.data[id_temo].path[0].lat
            let lonA = result1.data[id_temo].path[0].lon
            let latB = result1.data[id_temo].path.slice(-1)[0].lat
            let lonB = result1.data[id_temo].path.slice(-1)[0].lon
            this.setState({latA, lonA, latB, lonB});
        })
            .catch((error) => {
                console.log(error)
            })
            this.setState({height});
    }
    render() {
        return (
            <div className={"SubscriberMapForm"} >
                <YMaps id={"ya_mp"}
                       query={{ apikey: 'c547e3f2-a1e7-439d-9dd4-a49b2bacaf9f' }}
                >
                    <div>
                        <Map
                            state={{ center:this.state.cor, zoom: this.state.zoom,
                                type: 'yandex#map',
                                bounds: undefined }}
                            //  state={this.state}
                              height={this.state.height}
                              width='100%'
                        >
                            <GeolocationControl options={{float: 'left'}}/>
                            <ZoomControl options={{ float: 'right' }} />
                            <TypeSelector options={{ float: 'right' }} />
                            <FullscreenControl />
                            <Polyline geometry={mas} options={{
                                balloonCloseButton: false,
                                strokeColor: '#000',
                                strokeWidth: 4,
                                strokeOpacity: 0.5
                            }} />
                            <Clusterer options={{
                                preset: 'islands#invertedVioletClusterIcons',
                                groupByCoordinates: false
                            }}>
                                {mas_dop.map((coordinates, index) =>
                                    <Placemark
                                        options={{
                                            iconColor:'#ffd343'
                                    }}
                                        key={index}
                                        geometry={coordinates}
                                        onClick={() => comment_vid(mas_dop_com[index])}
                                    />)}
                            </Clusterer>
                            <Placemark geometry={mas[0]} onClick={() => text_vid(text_start_point)}/>
                            <Placemark geometry={mas[mas.length - 1]} onClick={() => text_vid(text_end_point)}/>
                            <RouteButton options={{
                                float: 'right'
                            }} />
                        </Map>
                        <br/>
                        <div>
                            <Button onClick={() => {window.history.back();commentsAlreadyDownloaded = false; localStorage.removeItem('code')}}
                                    className={"YamapBackAbon"}>{getString(strings.Back)}</Button>
                        </div>
                    </div>
                </YMaps>
            </div>
        );
    }
}

export default ObjectsTask;

