import React from "react";
import RelatedTasks from "./RelatedTasks";
import Observers from "./Observers";
import Objects from "./Objects";

export default function OtherPanel(props) {

  return (
    <div className="tab-pane fade" id="add" role="tabpanel" aria-labelledby="add-tab">
      {/* Наблюдатели */}
      <Observers
        taskShowWatchers={props.taskShowWatchers}
        addObservers={props.addObservers} />

      {/* Связанные задания */}
      <RelatedTasks
        taskRelated={props.taskRelated} />

      {/* Объекты */}
      <Objects
        taskObjects={props.taskObjects} />
    </div>
  )
}