import React from "react";
import taskAbonIpIcon from '../../../../Resources/Icons/AboutTask/ip.png';

export default function AbonIp(props) {

  return (
    <div id={"ipHolder"} className="col-sm-12">
      <label
        className="float-left text-primary mb-0">{props.taskIp}</label>
      <div className="input-group mb-2">
        <div className="input-group-prepend">
          <span className="input-group-text bg-primary"><img src={taskAbonIpIcon} alt={taskAbonIpIcon} width="20px" /></span>
        </div>
        <div id={"ip"}
          className={"form-control h-auto smallText card d-block border-1  px-2 py-2"} />
      </div>
    </div>
  )
}