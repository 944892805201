import React from "react";
import backArrow from "../Resources/Icons/back-arrow.svg";
import {faCloudUploadAlt, faFileImage, faTrash, faMapMarkerAlt, faCog, faMapMarkedAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ShowSupportsMap} from "../Utils/Dialog/SupportsDialog"
import {ShowSupportDelete} from "../Utils/Dialog/SupportsDeleteDialog";
import {getString, strings} from "../Languages/Languages";

let commentsAlreadyDownloaded = false;



function SupportsInformation()
{

    const PreviewImage = () => {
        let  file = document.getElementById("file").files;
        if (file.length >0) {
            let fileReader = new FileReader();

            fileReader.onload = function (event) {
                document.getElementById("preview").setAttribute("src", event.target.result);
            }
            fileReader.readAsDataURL(file[0]);
            console.log(fileReader)
        }
    }

    return (
        <div>
            <p/>
            <div className={'SupportsInformation'}>Начало сессии:</div>
            <div type="button" id="MarketingId" className="SupportDivInfo" >
                probnaya
            </div>

            <div className={'SupportsInformation'}>Конец сессии:</div>
            <div type="button" id="MarketingId" className="SupportDivInfo" >
                probnaya
            </div>

            <div className={'SupportsInformation'}>Длительномть сессии:</div>
            <div type="button" id="MarketingId" className="SupportDivInfo" >
                probnaya
            </div>

            <div className={'SupportsInformation'}>Количество опор:</div>
            <div type="button" id="MarketingId" className="SupportDivInfo" >
                probnaya
            </div>

            <div className={'SupportsInformation'}>Среднее время на опору:</div>
            <div type="button" id="MarketingId" className="SupportDivInfo" >
                probnaya
            </div>

            <div className={'SupportsInformation'}>Среднее расстояние между опорами:</div>
            <div type="button" id="MarketingId" className="SupportDivInfo" >
                probnaya
            </div>

            <div className={'SupportsInformation'}>Общее пройденное расстояние:</div>
            <div type="button" id="MarketingId" className="SupportDivInfo" >
                probnaya
            </div>

            <div className={'SupportsInformation'}>Средняя скорость движения:</div>
            <div type="button" id="MarketingId" className="SupportDivInfo" >
                probnaya
            </div>
            <div id={'MarketingMapId'}>
                <div type="button" id="MarketingId" className="SupportDivIcons" >

                    <div className="float-right text-right">
                    <a
                        type="button"
                        className="navbar-brand text-primary mr-2" href="#">
                        <FontAwesomeIcon icon={faMapMarkerAlt}/>
                    </a>
                    <a onClick={() => ShowSupportsMap(() => {

                    })}
                        type="button"
                        className="navbar-brand text-primary mr-2" href="#">
                        <FontAwesomeIcon icon={faCog}/>
                    </a>
                    </div>
                </div>
                <div id={"ddd"} className="modal-body">
                    <img className={"img"} id={"preview"}/>
                </div>

            </div>
            <br/><br/><br/>
            <nav className="navbar fixed-bottom navbar-light bg-white shadow-lg">
                <div className="float-left" >
                    <a id={"backBtn"} onClick={() => {window.history.back();commentsAlreadyDownloaded = false;}}
                       className="navbar-brand text-primary mx-2"><img src={backArrow} alt={backArrow} width={"25px"}/></a>
                </div>
                <div className="float-right text-right">
                    <a
                        onClick={() => ShowSupportDelete(() => {


                        },getString(strings.YouSureWantDeleteSession))}
                        type="button"
                       className="navbar-brand text-primary mr-2" href="#">
                        <FontAwesomeIcon icon={faTrash}/>
                    </a>
                    <a onClick={() => ShowSupportDelete(() => {


                    },getString(strings.YouWantUploadPropsSessionToUserside))}
                       type="button"
                       className="navbar-brand text-primary mr-2" href="#">
                        <FontAwesomeIcon icon={faCloudUploadAlt}/>
                    </a>
                    <a type="button"
                       className="navbar-brand text-primary mr-2" href="#">
                        <FontAwesomeIcon icon={faMapMarkedAlt}/>
                    </a>

                    <input  className="btn btn-primary mr-2" type="file" multiple name="file" id="file"
                            onChange={() => PreviewImage()}
                            hidden={true}/>
                    <label htmlFor="file" className="CameraSupports" >
                        <FontAwesomeIcon icon={faFileImage} />
                    </label>

                </div>
            </nav>
        </div>
    );
}
export default SupportsInformation;