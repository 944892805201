import {UserSideRequests} from "./UserSideRequests";
import {ShortTask} from "../Task/Task";
import {Config} from "./Config";
import {getString, strings} from "../Languages/Languages";
import {User} from "../User/User";
import {ServerRequests} from "../Server/ServerRequests";

class UserSideApi
{
    static #ERROR_TAG = "API EXEPTION - ";

    constructor()
    {
        this.requests = new UserSideRequests();
    }

    async sendComment(text, photos)
    {
        if (text.toString().replace(" ", "") === "" && photos.length > 0)
        {
            text = getString(strings.SendedPhoto);
        }
        else if (text.toString().replace(" ", "") === "" && photos.length <= 0)
        {
            return false;
        }

        let comment = await this.requests.addComment(text);

        try
        {
            let commentResult = JSON.parse(JSON.stringify(comment));
            if (commentResult['Result'] === 'OK')
            {
                let serverRequests = new ServerRequests();

                for (let i in photos)
                {
                    let photo = photos[i];
                    await serverRequests.addImageToComment(photo, commentResult['Id']);
                }

                return true;
            }
        }
        catch (e)
        {
            UserSideApi.#printApiError(e);
            return false;
        }
        return false;
    }


    async getAbonCoords(addressId)
    {
        let addressResponse = await this.requests.addressGetHouse(addressId);

        try
        {
            let result = JSON.parse(JSON.stringify(addressResponse));
            return result['data'][addressId]['coordinates'];
        }
        catch (e)
        {
            UserSideApi.#printApiError(e);
            return null;
        }
    }

    async getListWithStaffId(dateDoFrom, dateDoTo, staffId, stateId)
    {
        let response = await this.requests.taskGetListWithStaffId(staffId, dateDoFrom, dateDoTo, stateId);

        try
        {
            let result = JSON.parse(JSON.stringify(response));

            if (result['Result'] !== 'OK') return undefined;

            return {
                'List': result['list'],
                'Count': result['count']
            };
        }
        catch (e)
        {
            UserSideApi.#printApiError(e);
            return undefined;
        }
    }

    async getListWithEmployeeId(dateDoFrom, dateDoTo, employeeId, stateId)
    {
        let response = await this.requests.taskGetListWithEmployeeId(employeeId, dateDoFrom, dateDoTo, stateId);

        try
        {
            let result = JSON.parse(JSON.stringify(response));

            if (result['Result'] !== 'OK') return undefined;

            return {
                'List': result['list'],
                'Count': result['count']
            };
        }
        catch (e)
        {
            UserSideApi.#printApiError(e);
            return undefined;
        }
    }

    async getNearNodes(lat, lon, range)
    {
        let nodeResponse = await this.requests.nodeGetIdis(lat, lon, range);

        let nodesDistance = {};
        let nodeIdis = [];
        try
        {
            let result = JSON.parse(JSON.stringify(nodeResponse));

            for (let nodeIndex in result['data'])
            {
                nodeIdis.push(result['data'][nodeIndex]['id']);
                nodesDistance[result['data'][nodeIndex]['id'].toString()] = result['data'][nodeIndex]['distance'];
            }

            console.log(nodeIdis.toString());
        }
        catch (e)
        {
            UserSideApi.#printApiError(e);
        }

        let nodeInfoResponse = await this.requests.nodeGet(nodeIdis.toString());

        try
        {
            let nodeInfoResult = JSON.parse(JSON.stringify(nodeInfoResponse));
            let nodesInfo = [];

            for (let nodeIndex in nodeInfoResult['data'])
            {
                nodesInfo.push({
                    'Id': nodeInfoResult['data'][nodeIndex]['id'],
                    'Name': nodeInfoResult['data'][nodeIndex]['name'],
                    'Type': nodeInfoResult['data'][nodeIndex]['type'],
                    'Coordinates': [parseFloat(nodeInfoResult['data'][nodeIndex]['coordinates']['lat']), parseFloat(nodeInfoResult['data'][nodeIndex]['coordinates']['lon'])],
                    'Location': nodeInfoResult['data'][nodeIndex]['location'] ,
                    'Distance': nodesDistance[nodeIndex],
                });
            }

            return nodesInfo;
        }
        catch (e)
        {
            UserSideApi.#printApiError(e);
        }
    }

    async getListWithDivisionId(dateDoFrom, dateDoTo, divisionId, stateId)
    {
        let response = await this.requests.taskGetListWithDivisionId(divisionId, dateDoFrom, dateDoTo, stateId);

        try
        {
            let result = JSON.parse(JSON.stringify(response));

            if (result['Result'] !== 'OK') return undefined;

            return {
                'List': result['list'],
                'Count': result['count']
            };
        }
        catch (e)
        {
            UserSideApi.#printApiError(e);
            return undefined;
        }
    }

    async getList(dateDoFrom, dateDoTo, staffId)
    {
        if (Config.UserSideVersion.get() + '' === Config.UserSide315)
        {
            if (Config.ArrCustomer14.get() === '1')
            {
                let divisionId = await this.staffGetDivisionId(staffId);
                if (divisionId !== undefined)
                {
                    let divisionTasksList = await this.getListWithDivisionId(dateDoFrom, dateDoTo, divisionId, Config.StateId.get());
                    if (divisionTasksList !== undefined)
                    {
                        let staffTasksList = await this.getListWithStaffId(dateDoFrom, dateDoTo, staffId, Config.StateId.get());
                        if (staffTasksList !== undefined)
                        {
                            return {
                                'List': `${divisionTasksList['List']},${staffTasksList['List']}`,
                                'Count': `${parseInt(divisionTasksList['Count']) + parseInt(staffTasksList['Count'])}`,
                            };
                        }

                        return undefined;
                    }

                    return undefined;
                }

                return undefined;
            }

            return await this.getListWithStaffId(dateDoFrom, dateDoTo, staffId, Config.StateId.get());
        }

        if (Config.UserSideVersion.get() + '' === Config.UserSide316)
        {
            if (Config.ArrCustomer14.get() === '1')
            {
                let divisionId = await this.staffGetDivisionId(staffId);
                if (divisionId !== undefined)
                {
                    let divisionTasksList = await this.getListWithDivisionId(dateDoFrom, dateDoTo, divisionId, Config.StateId.get());
                    if (divisionTasksList !== undefined)
                    {
                        let staffTasksList = await this.getListWithEmployeeId(dateDoFrom, dateDoTo, staffId, Config.StateId.get());
                        if (staffTasksList !== undefined)
                        {
                            return {
                                'List': `${divisionTasksList['List']},${staffTasksList['List']}`,
                                'Count': `${parseInt(divisionTasksList['Count']) + parseInt(staffTasksList['Count'])}`,
                            };
                        }

                        return undefined;
                    }

                    return undefined;
                }

                return undefined;
            }

            return await this.getListWithEmployeeId(dateDoFrom, dateDoTo, staffId, Config.StateId.get());
        }
    }

    async staffGetDivisionId(staffId)
    {
        let response;

        if (Config.UserSideVersion.get() + '' === Config.UserSide315)
        {
            response = await this.requests.staffGetData(staffId);
        }

        if (Config.UserSideVersion.get() + '' === Config.UserSide316)
        {
            response = await this.requests.employeeGetData(staffId);
        }

        try
        {
            JSON.parse(JSON.stringify(response), function (key, value)
            {
                if (key === 'Result')
                {
                    if (value !== 'OK') return '';
                }
                if (key === 'division_id')
                {
                    return value;
                }
            });
        }
        catch (e)
        {
            UserSideApi.#printApiError(e);
            return '';
        }
    }

    async shortTaskShow(id, taskCount)
    {
        let response = await this.requests.taskShow(id);

        let tasks = [];

        try
        {
            let result = JSON.parse(JSON.stringify(response));


            if ((result['Result'] ?? '') !== 'OK') return null;

            if (taskCount > 1)
            {
                for (let taskId in result["Data"])
                {
                    tasks.push(await this.#shortTaskParse(result['Data'][taskId]));
                }

                return tasks;
            }
            else
            {
                await this.#shortTaskParse(result['Data']).then(task => { tasks.push(task); });
                return tasks;
            }
        }
        catch (e)
        {
            UserSideApi.#printApiError(e);
            return null;
        }
    }

    async #shortTaskParse(json)
    {
        let task = new ShortTask();

        try
        {
            task.Id = json['id'] ?? '';
            task.TypeId = json['type']['id'] ?? '';
            task.Type = json['type']['name'] ?? '';
            task.TypeDescription = await this.getTypeDescription(task.TypeId);
            task.Fio = json['customer']['fullName'] ?? '';

            task.Address = json['address']['text'] ?? '';
            task.AddressId = json['address']['addressId'] ?? '';
            task.CustomerId = json['customer']['id'] ?? '';
            task.RelatedObjects = json['related_object'] ?? '';
            task.Comments = json['comments'] ?? '';

            task.Photos = json['attach'] ?? '';
            task.HistoryCount = json['history'].length ?? 0;
            task.Nodes = json['node']['id'] ?? '';
            task.ComNodes = await this.nodeGetName(json['node']['id'] ?? '');

            let fullTime = json['date']['todo'] ?? '';
            let timeArr = fullTime.split(' ')[1].split(':');
            task.Time = `${timeArr[0]}:${timeArr[1]}`;

            task.Date = json['date']['todo'] ?? '';
            task.Description = (json['description'] ?? '')
                .replace('\\n', '')
                .replace("<br>", "\n");

            task.Priority = (json['priority'].toString() === '1');

            let staff = '';
            try
            {
                let workers = [];

                let arrayName = '';
                if (Config.UserSideVersion.get() === Config.UserSide315) arrayName = 'worker';
                if (Config.UserSideVersion.get() === Config.UserSide316) arrayName = 'employee';

                for (let worker in JSON.parse(JSON.stringify(json['staff'][arrayName])))
                {
                    workers.push(worker);
                }
                staff += await this.getStaffName(workers);
            }
            catch (e)
            {
                UserSideApi.#printApiError(e);
            }

            try
            {
                let divisions = [];
                for (let division in JSON.parse(JSON.stringify(json['staff']['division'])))
                {
                    divisions.push(division);
                }
                staff += await this.getDivisionName(divisions);
            }
            catch (e)
            {
                UserSideApi.#printApiError(e);
            }

            task.Staff = staff.toString().substring(0, staff.toString().length - 2);
            task.Status = json['state']['name'] ?? '';

            let nowDate = new Date();
            let day = nowDate.getDate() < 10 ? `0${nowDate.getDate()}` : nowDate.getDate();
            let month = nowDate.getMonth() < 10 ? `0${nowDate.getMonth()}` : nowDate.getMonth();
            task.Sla = (json['date']['todo'] ?? '').split(' ')[0] === `${nowDate.getFullYear()}-${(parseInt(month) + 1) < 10 
                ? '0' + (parseInt(month) + 1) 
                : (parseInt(month) + 1)}-${day}`;
            task.TimeForDoing = await this.getTimeForDoing(task.TypeId);

            let uDate = new Date(Date.parse(json['date']['todo'] ?? ''));
            uDate.setHours(uDate.getHours() + parseInt(task.TimeForDoing));
            task.SlaTime = nowDate < uDate;

            task.Price = await this.getTaskPrice(task.TypeId) + ` ${getString(strings.Currency)}`;

            return task;
        }
        catch (e)
        {
            UserSideApi.#printApiError(e);
            return null;
        }
    }

    async getStaffName(staffId)
    {
        let response = '';

        if (Config.UserSideVersion.get() + '' === Config.UserSide315)
        {
            response = await this.requests.staffGetData(staffId);
        }

        if (Config.UserSideVersion.get() + '' === Config.UserSide316)
        {
            response = await this.requests.employeeGetData(staffId);
        }

        try
        {
            let result = JSON.parse(JSON.stringify(response));

            if ((result['Result'] ?? '') !== 'OK') return '';

            let names = '';

            for (let operator in result['data'])
            {
                names += `${result['data'][operator]['name']}, `;
            }

            return names;
        }
        catch (e)
        {
            UserSideApi.#printApiError(e);
            return '';
        }
    }

    async getTimeForDoing(typeId)
    {
        let response = await this.requests.taskGetCatalogType(typeId);

        try
        {
            let result = JSON.parse(JSON.stringify(response));

            if (result['Result'] !== 'OK') return '0';

            return result['Data'][typeId]['timescale']['hour_on_doing'];
        }
        catch (e)
        {
            UserSideApi.#printApiError(e);
        }
    }

    async getTaskPrice(typeId)
    {
        let response = await this.requests.taskGetCatalogType(typeId);

        try
        {
            let result = JSON.parse(JSON.stringify(response));

            if (result['Result'] !== 'OK') return '0.0';

            return result['Data'][typeId]['amount'];
        }
        catch (e)
        {
            UserSideApi.#printApiError(e);
        }
    }

    async getDivisionName(divisionId)
    {
        let response = '';

        if (Config.UserSideVersion.get() + '' === Config.UserSide315)
        {
            response = await this.requests.staffGetDivision(divisionId);
        }

        if (Config.UserSideVersion.get() + '' === Config.UserSide316)
        {
            response = await this.requests.employeeGetDivision(divisionId);
        }

        try
        {
            let result = JSON.parse(JSON.stringify(response));

            if (result['Result'] !== 'OK') return '';

            let names = '';

            for (let division in result['data'])
            {
                names += `${result['data'][division]['name']}, `;
            }

            return names;
        }
        catch (e)
        {
            UserSideApi.#printApiError(e);
            return null;
        }
    }

    async getTypeDescription(typeId)
    {
        let response = await this.requests.taskGetCatalogType(typeId);

        try
        {
            let result = JSON.parse(JSON.stringify(response));

            return ((result['Result'] ?? '') === 'OK') ? result['Data'][typeId]['description'] : '';
        }
        catch (e)
        {
            UserSideApi.#printApiError(e);
            return '';
        }
    }

    async employeeCheckPass(login, password)
    {
        let response = await this.requests.employeeCheckPass(login, password);

        try
        {
            return (JSON.parse(JSON.stringify(response))['Result'] === 'OK');
        }
        catch (e)
        {
            UserSideApi.#printApiError(e);
            return false;
        }
    }

    async operatorCheckPass(login, password)
    {
        let response = await this.requests.operatorCheckPass(login, password);

        try
        {
            return (JSON.parse(JSON.stringify(response))['Result'] === 'OK');
        }
        catch (e)
        {
            UserSideApi.#printApiError(e);
            return false;
        }
    }

    async operatorGetIdByLogin(login)
    {
        let response = await this.requests.operatorGetIdByLogin(login);

        try
        {
            let result = JSON.parse(JSON.stringify(response));
            return  result['Result'] === 'OK' ? result['id'] : '';
        }
        catch (e)
        {
            UserSideApi.#printApiError(e);
            return '';
        }
    }

    async employeeGetIdByLogin(login)
    {
        let response = await this.requests.employeeGetIdByLogin(login);

        try
        {
            let result = JSON.parse(JSON.stringify(response));

            return  result['id'] ?? '';
        }
        catch (e)
        {
            UserSideApi.#printApiError(e);
            return '';
        }
    }

    async operatorGetStaffId(id)
    {
        let response = await this.requests.operatorGetData(id);

        try
        {
            let result = JSON.parse(JSON.stringify(response));
            return result['Result'] === 'OK' ? result['data'][id]['staff_id'] : '';
        }
        catch (e)
        {
            UserSideApi.#printApiError(e);
            return '';
        }
    }

    async customerGetData(id)
    {
        let response = await this.requests.customerGetData(id);

        try
        {
            let result = JSON.parse(JSON.stringify(response));
            result = result['Data'];

            let agreement = result['agreement'][0]['number'] ?? '';
            let houseId = result['address'][0]['house_id'] ?? '';
            let comment = '';
            try
            {
                comment = result['comment'];
                comment = User.getKeyPrill() === '100-200' && comment !== null ? comment
                    .replace('<b>', '')
                    .replace('</b>', '')
                    .replace('<\\/b>', '')
                    .replace('pass: ', '') : comment;
            }
            catch(e) { comment = null }
            let login; try { login = result['login'] }catch(e) { login = null }

            let groupId = '';
            try
            {
                JSON.parse(JSON.stringify(result['group']), function (key, value) { if (key === 'id') groupId = value; });
            }
            catch (e) { groupId = null }

            let networkActivity = result['date_activity'] ?? '';
            let internetActivity = result['date_activity_inet'] ?? '';
            let phones = [];
            for (let phone in result['phone'])
            {
                phones.push(result['phone'][phone]['number']);
            }

            let ip = '';
            try
            {
                JSON.parse(JSON.stringify(result['ip_mac']), function (key, value) { if (key === 'ip') ip = value; })
                // eslint-disable-next-line no-mixed-operators
                ip = [ip >>> 24, ip >>> 16 & 0xFF, ip >>> 8 & 0xFF, ip & 0xFF].join('.');
            }
            catch (e) { ip = null; }

            return {
                'Agreement': agreement,
                'HouseId': houseId,
                'Comment': comment,
                'Phones': phones,
                'Ip': ip,
                'Login': login,
                'NetworkActivity': networkActivity,
                'InternetActivity': internetActivity,
                'GroupId': groupId,
            };
        }
        catch (e)
        {
            UserSideApi.#printApiError(e);
            return null;
        }
    }

    async employeeGetData(id)
    {
        let response = await this.requests.customerGetData(id);

        try
        {
            let result = JSON.parse(JSON.stringify(response));
            result = result['data'][id];

            let name = result['name'];

            return {
                'Name': name,
            };
        }
        catch (e)
        {
            UserSideApi.#printApiError(e);
            return undefined;
        }
    }

    async employeeGetName(id)
    {
        let response = await this.requests.employeeGetData(id);

        try
        {
            let result = JSON.parse(JSON.stringify(response));
            result = result['data'][id];

            console.log(JSON.stringify(result));

            return result['name'] ?? '';
        }
        catch (e)
        {
            UserSideApi.#printApiError(e);
            return undefined;
        }
    }

    async addressGetHouse(id)
    {
        let response = await this.requests.addressGetHouse(id);

        try
        {
            let result = JSON.parse(JSON.stringify(response));
            result = result['data'][id];

            let note = result['comment'];
            console.log(note)
            let exit = result['exit_comment'];
            let workNote = result['task_comment'];
            let keys = [];
            for (let key in result['additional_data'])
            {
                keys.push(result['additional_data'][key])
            }

            return {
                'Note': note,
                'Exit': exit,
                'WorkNote': workNote,
                'Keys': keys,
            };

        }
        catch (e)
        {
            UserSideApi.#printApiError(e);
            return null;
        }
    }

    async getNetworkData(customerId, customerGroupId)
    {
        let customerCommutationResponse = await this.requests.commutationGetDataCustomer(customerId);

        let networkData = {};

        try
        {
            let customerCommutationResult = JSON.parse(JSON.stringify(customerCommutationResponse));

            let objectId = customerCommutationResult['data']['0']['object_id'] ?? '';
            let port = customerCommutationResult['data']['0']['interface'] ?? '';
            let objectType = customerCommutationResult['data']['0']['object_type'] ?? '';

            switch (objectType)
            {
                case 'fiber':

                    let fiberResponse = await this.requests.fiberGetList(objectId);

                    try
                    {
                        let fiberResult = JSON.parse(JSON.stringify(fiberResponse));
                        fiberResult = fiberResult['Data'][objectId];

                        let fiberCount = fiberResult['port'] ?? '';
                        let opticaLen = fiberResult['opticalen'] ?? '';
                        let opticaLen2 = fiberResult['opticalen2'] ?? '';
                        let objectName = fiberResult['start_point_name'] ?? '';
                        let colors = '';

                        for (let fiber in fiberResult['fibers'])
                        {
                            let colorName = fiberResult['fibers'][fiber]['color']['name'] ?? '';
                            let moduleColorName = fiberResult['fibers'][fiber]['moduleColor']['name'] ?? '';

                            let color = fiberResult['fibers'][fiber]['color']['htmlCode'] ?? '';
                            let moduleColor = fiberResult['fibers'][fiber]['moduleColor']['htmlCode'] ?? '';

                            if (color === '#FFFFFF')
                            {
                                colors += `<span style="color: ${color}">${colorName}</span>`;
                            }
                            else
                            {
                                colors += `<span style="color: ${color}">${colorName}</span>`;
                            }

                            if (moduleColor === '#FFFFFF')
                            {
                                colors += `/ <span style="color: ${moduleColor}">${moduleColorName}</span>`;
                            }
                            else
                            {
                                colors += `/ <span style="color: ${moduleColor}">${moduleColorName}</span>`;
                            }

                            colors += `<b>(${opticaLen2})м </b>`;

                            networkData['OnPoint'] = `${getString(strings.Vols)} (${fiberCount}), ${opticaLen}m, - ${getString(strings.Before)} ${objectName} - ${colors}`;
                        }
                    }
                    catch (e)
                    {
                        UserSideApi.#printApiError(e);
                    }

                    break;
                case 'switch':

                    let switchResponse = await this.requests.deviseGetDataSwitch(objectId);

                    try
                    {
                        let switchResult = JSON.parse(JSON.stringify(switchResponse));
                        switchResult = switchResult['data'][objectId];

                        let switchHost = switchResult['host'] ?? '';
                        let switchName = switchResult['name'] ?? '';
                        let switchLocation = switchResult['location'] ?? '';

                        networkData['OnPoint'] = `${switchName} - IP ${switchHost}:${port} - ${switchLocation}`;
                    }
                    catch (e)
                    {
                        UserSideApi.#printApiError(e);
                    }

                    break;
                default:
                    break;
            }

            try
            {
                let endPoint = '';

                let finishData = customerCommutationResult['data']['finish'];
                if (finishData + '' !== '')
                {
                    if (finishData['object_type'] + '' === 'switch')
                    {
                        let endObjectId = finishData['object_id'];

                        let endObjectResponse = await this.requests.deviseGetDataSwitch(endObjectId);

                        try
                        {
                            let endResult = JSON.parse(JSON.stringify(endObjectResponse));
                            endResult = endResult['data'][objectId];

                            let endHost = endResult['host'] ?? '';
                            let endName = endResult['name'] ?? '';
                            let endLocation = endResult['location'] ?? '';

                            endPoint = `${endName} - IP ${endHost}:${port} - ${endLocation}`;
                        }
                        catch (e)
                        {
                            UserSideApi.#printApiError(e);

                            let oltResponse = await this.requests.deviseGetDataOlt(endObjectId);

                            try
                            {
                                let oltResult = JSON.parse(JSON.stringify(oltResponse));
                                oltResult = oltResult['data'][endObjectId];

                                let oltIp = oltResult['ip'] ?? '';
                                let oltName = oltResult['name'] ?? '';
                                let oltLocation = oltResult['location'] ?? '';

                                endPoint = `${oltName} - IP ${oltIp} - ${oltLocation}`;
                            }
                            catch (e)
                            {
                                UserSideApi.#printApiError(e);
                            }
                        }
                    }
                    else
                    {
                        endPoint = getString(strings.EndPointNotFound);
                    }
                }

                networkData['EndPoint'] = endPoint;
            }
            catch (e)
            {
                UserSideApi.#printApiError(e);
            }

            let groupListResponse = await this.requests.getAbonGroupList();

            try
            {
                let groupList = JSON.parse(JSON.stringify(groupListResponse));

                networkData['GroupName'] = groupList[customerGroupId]['name'] ?? '';
            }
            catch (e)
            {
                UserSideApi.#printApiError(e);
            }
        }
        catch(e)
        {
            UserSideApi.#printApiError(e);
        }

        return networkData;
    }

    async getSignalPower(limit, customerId)
    {
        let inventoryAmountResponse = await this.requests.getInventoryAmountCustomer(customerId);

        let signals = [];

        try
        {
            let inventoryAmountResult = JSON.parse(JSON.stringify(inventoryAmountResponse));
            inventoryAmountResult = inventoryAmountResult['data'];

            if (inventoryAmountResult === '[]')
            {
                return undefined;
            }

            for (let item in inventoryAmountResult)
            {
                let itemId = inventoryAmountResult[item]['id'] ?? '';

                let itemResponse = await this.requests.inventoryGet(itemId);

                try
                {
                    let itemResult = JSON.parse(JSON.stringify(itemResponse));
                    itemResult = itemResult['data'];

                    if (itemResult['section_name'] + '' === 'GPON' || itemResult['section_name'] + '' === 'ONU')
                    {
                        let powerResponse = await this.requests.getPonPowerHistory(limit, itemResult['serial_number'])

                        try
                        {
                            let powerResult = JSON.parse(JSON.stringify(powerResponse));
                            powerResult = powerResult['data'];

                            for (let item in powerResult)
                            {
                                for (let pon in powerResult[item])
                                {
                                    for (let power in powerResult[item][pon])
                                    {
                                        signals.push(
                                        {
                                            'Power': powerResult[item][pon][power]['level'],
                                            'From': powerResult[item][pon][power]['date_from'],
                                            'To': powerResult[item][pon][power]['date_to']
                                        });
                                    }
                                }
                            }

                            return signals;
                        }
                        catch (e)
                        {
                            UserSideApi.#printApiError(e);
                            return undefined;
                        }
                    }
                }
                catch (e)
                {
                    UserSideApi.#printApiError(e);
                    return undefined;
                }
            }
        }
        catch (e)
        {
            UserSideApi.#printApiError(e);
            return undefined;
        }
    }

    async getOtherData(taskId, relatedObjects)
    {
        let relatedTasksResponse = await this.requests.taskGetRelatedTasks(taskId);

        let otherData = {};

        try {
            let relTasks = [];

            let relatedTasksResult = JSON.parse(JSON.stringify(relatedTasksResponse));

            let relTasksIds = relatedTasksResult['Data'] ?? '';

            if ((relTasksIds + '').indexOf(',', 0) !== -1)
            {
                let relTasksIdsArr = relTasksIds.split(',');

                for (let id in relTasksIdsArr)
                {
                    relTasks.push(relTasksIdsArr[id]);
                }
            }
            else
            {
                relTasks.push(relatedTasksResult['Data']);
            }

            otherData['RelatedTasks'] = relTasks;
        }
        catch (e)
        {
            UserSideApi.#printApiError(e);
        }

        try
        {
            let relObjs = [];

            for (let relatedObject in relatedObjects)
            {
                let objectId = relatedObjects[relatedObject]['id'];
                let fiberList = await this.requests.fiberGetList(objectId);

                let fiberResult = JSON.parse(JSON.stringify(fiberList));
                fiberResult = fiberResult['Data'][objectId];

                let startPoint = fiberResult['start_point_name'] ?? '';
                let endPoint = fiberResult['finish_port_name'] ?? '';
                let path = fiberResult['path'] ?? '';

                let pathCoords = [];
                for (let pathIndex in path)
                {
                    pathCoords.push([parseFloat(path[pathIndex]['lat']), parseFloat(path[pathIndex]['lon'])])
                }

                let routeObjectsCoords = [];
                try
                {
                    let routeObjects = fiberResult['route_object'] ?? '';

                    for (let routeObjectsindex in routeObjects)
                    {
                        let c = routeObjects[routeObjectsindex]['additional_data']['coordinates'].toString().split(',');
                        let lat = c[0];
                        let lon = c[1];
                        routeObjectsCoords.push([lat, lon]);
                    }
                }
                catch (e) { routeObjectsCoords = []; }

                relObjs.push({'Name': `${startPoint} ---> ${endPoint}`, 'Path': pathCoords, 'RouteObjects': routeObjectsCoords})
            }

            otherData['RelatedObjects'] = relObjs;
        }
        catch (e)
        {
            UserSideApi.#printApiError(e);
        }

        return otherData;
    }

    async nodeGetName(id)
    {
        let nodesResponse = await this.requests.nodeGet(id);

        try
        {
            let nodesResult = JSON.parse(JSON.stringify(nodesResponse));
            nodesResult = nodesResult['data'];

            let nodes = [];
            for (let nodeIndex in nodesResult)
            {
                nodes.push({"Id": nodesResult[nodeIndex]['id'], "Name": nodesResult[nodeIndex]['name']});
            }

            return nodes;
        }
        catch (e)
        {
            UserSideApi.#printApiError(e);
            return null;
        }
    }

    async nodeGet(id)
    {
        let nodesResponse = await this.requests.nodeGet(id);

        try
        {
            let nodesResult = JSON.parse(JSON.stringify(nodesResponse));
            nodesResult = nodesResult['data'];

            let nodes = [];
            for (let nodeIndex in nodesResult)
            {
                let nodeCoords = nodesResult[nodeIndex]['coordinates'];

                let lat = parseFloat(nodeCoords['lat']);
                let lon = parseFloat(nodeCoords['lon']);

                nodes.push([lat, lon]);
            }

            return nodes;
        }
        catch (e)
        {
            UserSideApi.#printApiError(e);
            return null;
        }
    }


    async getComments(commentsObject)
    {
        try
        {
            let comments = JSON.parse(JSON.stringify(commentsObject));

            console.log(JSON.stringify(comments));

            let coms = [];

            for (let comment in comments)
            {
                let id = comments[comment]['id'] ?? '';
                if (id + '' === '')
                {
                    continue;
                }

                let attaches = [];
                let dateAdd = comments[comment]['dateAdd'] ?? '';
                let operator = await this.getOperatorName(comments[comment]['operatorId'] ?? '');
                let commentText = comments[comment]['comment'] ?? '';
                for (let attach in comments[comment]['attach'] ?? '')
                {
                    attaches.push(comments[comment]['attach'][attach]['id'] ?? '');
                }

                coms.push({
                    'Operator': operator,
                    'Id': id,
                    'Attaches': attaches,
                    'DateAdd': dateAdd,
                    'CommentText': commentText,
                });
            }

            return coms;
        }
        catch (e)
        {
            UserSideApi.#printApiError(e);
        }
    }

    async getOperatorName(id)
    {
        if (id + '' === '0' || id + '' === '')
        {
            return getString(strings.Sluzhebniy);
        }

        let operatorResponse = await this.requests.operatorGet(id);

        try
        {
            let operatorResult = JSON.parse(JSON.stringify(operatorResponse));
            return operatorResult['data'][id]['name'] ?? '...';
        }
        catch (e)
        {
            UserSideApi.#printApiError(e);
        }
    }

    static #printApiError(e)
    {
        console.error(UserSideApi.#ERROR_TAG + e);
    }
}
export {UserSideApi};
