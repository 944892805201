import {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBars,
    faCalendar,
    faFilter,
    faDoorOpen,
    faCog,
    faQuestionCircle,
    faBriefcase,
    faChartLine,
    faPodcast,
    faClock,
    faCalendarDay,
    faMap,
    faTicketAlt,
    faMapSigns,
} from "@fortawesome/free-solid-svg-icons";
import {ShowFilterDialog} from "../Utils/Dialog/FilterDialog";
import logo from '../Resources/Logo/Logo_white_512x512.png';
import {formateDate} from "../Utils/String/StringUtils";
import {User} from "../User/User";
import {getTasks} from "../Task/Spawners/ShortTasksSpawner";

import {ShowCalendarDialog} from "../Utils/Dialog/CalendarDialog";
import {ServerApi} from "../Server/ServerApi";
import {getString, strings} from "../Languages/Languages";
import {getNearKnotCouplingForm} from "./NearKnotCoupling";
import {ShowAbout} from "../Utils/Dialog/AboutDialog";
import {GetOverdueTasksForm} from "./OverdueTasksForm";
import ShowSettings from "./ShowSetting";
import {render} from "react-dom";
import React from "react";
import getFiltredTasks from "../Task/Spawners/getFiltredTasks";

// Учёт рабочего времени
// import startToWork from "../Utils/StartToWork/StartToWork";
// import stopWorkButton from "../Utils/StartToWork/stopWorkButton";
// import {reactLocalStorage} from "reactjs-localstorage";



/**
 * TODO: add to Lanuages - ?Начать работать?, ?Вы далеко от офиса?, ?Закончить работать?
 */
function Menu() {
    const [isActive, setActive] = useState(false);
    const [startShift, setStartShift] = useState({
        disabled: true,
        color: 'rgb(175 175 175)',
        name: 'Начать работать'
    });
    const [stopShift, setStopShift] = useState({
        disabled: true,
        color: 'rgb(175 175 175)'
    });

    let employeeId = User.employeeId;
    let date = new Date();
    let workDate = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0');
    let lastDayDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let finalDate = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + lastDayDate.toLocaleString('RU', {day: 'numeric'});
    let workTime = date.getHours() + ':' + String(date.getMinutes()).padStart(2, '0');
    let userLat;
    let userLng;

    const Exit = () => {
        window.open("/login", '_self', false);
        localStorage.removeItem('Authorization')
        localStorage.removeItem('login')
        localStorage.removeItem('type_obj')
        localStorage.removeItem('marka')
    }

    // useEffect(() => {
    //     if (!navigator.geolocation) {
    //         alert('Geolocation is not supported by your browser')
    //         setStartShift({disabled: true, color: 'rgb(175 175 175)'})
    //     } else {
    //         navigator.geolocation.getCurrentPosition(function (position) {
    //                 // let userLat;
    //                 // let userLng;
    //                 if (position.coords.accuracy <= 20) {
    //                     alert(`Good! ${position.coords.accuracy}`)
    //                     userLat = position.coords.latitude
    //                     userLng = position.coords.longitude
    //                 } else {
    //                     console.log(`Bad! ${position.coords.accuracy}`)
    //                 }
    //                 // reactLocalStorage.set('userLatitude', position.coords.latitude);
    //                 // reactLocalStorage.set('userLongitude', position.coords.longitude);
    //                 let oficeLat = reactLocalStorage.get('oficeLatitude', '')
    //                 let oficeLon = reactLocalStorage.get('oficeLongitude', '')
    //                 // userLat = position.coords.latitude
    //                 // userLng = position.coords.longitude
    //                 console.log(userLat, userLng)
    //                 alert(startToWork(oficeLat, oficeLon, userLat, userLng))
    //                 if (startToWork(oficeLat, oficeLon, userLat, userLng) > 30) {
    //
    //                     setStartShift({disabled: true, color: 'rgb(175 175 175)', name: 'Вы далеко от офиса'})
    //                 } else {
    //                     setStartShift({disabled: false, color: '#505050', name: 'Начать работать'})
    //                 }
    //                 if (User.keyPril === '100-201') {
    //                     serverApi.getWorkTimeData(workDate, finalDate, User.employeeId).then((response) => {
    //                         console.log(response)
    //                         //Запрет на вознобновление работы в тот же день
    //                         // let timeStart = response.data[employeeId][response.data[employeeId].length - 1].time_start;
    //                         // if(timeStart.includes(workDate)) {
    //                         //     setStartShift({disabled: true, color: 'rgb(175 175 175)'})
    //                         // }
    //                         let timeFinish = response.data[employeeId][response.data[employeeId].length - 1].time_finish;
    //                         if (timeFinish.includes('1970-01-01')) {
    //                             setStartShift({disabled: true, color: 'rgb(175 175 175)', name: 'Начать работать'})
    //                             setStopShift({disabled: false, color: '#505050'})
    //                         }
    //                     })
    //                         .catch((error) => {
    //                             console.log(error)
    //                         })
    //                 }
    //                 if (User.keyPril === '100-200') {
    //                     if (reactLocalStorage.get('timeFinish', '') !== null) {
    //                         //Запрет на вознобновление работы в тот же день
    //                         // let timeStart = reactLocalStorage.get('timeFinish', '')
    //                         // if(timeStart.includes(workDate)) {
    //                         //     setStartShift({disabled: true, color: 'rgb(175 175 175)'})
    //                         // }
    //                         let timeFinish = reactLocalStorage.get('timeFinish', '')
    //                         if (timeFinish.includes('1970-01-01')) {
    //                             setStartShift({disabled: true, color: 'rgb(175 175 175)', name: 'Начать работать'})
    //                             setStopShift({disabled: false, color: '#505050'})
    //                         }
    //                     }
    //                 }
    //
    //             }, function (error) {
    //                 alert(error)
    //             },
    //             {timeout: 10000, enableHighAccuracy: true, maximumAge: 0})
    //     }
    // }, []);


    const handleToggle = () => {
        setActive(!isActive);
        localStorage.removeItem('amount')

    };

    function aboutPrilClick() {
        setActive(!isActive)
        ShowAbout()
    }

    function showSet() {
        setActive(!isActive)
        render(<ShowSettings/>, document.getElementById('app'))

    }
    // const serverApi = new ServerApi();
//Кнопка Начать работать
//     const start = () => {
//
//         serverApi.setTimesheetData(employeeId, workDate, '1', '1').then((response) => {
//             if (response.status === 'OK') {
//                 console.log(`Вы начали работать в ${workTime}`)
//                 setStartShift({disabled: true, color: 'rgb(175 175 175)', name: 'Начать работать'})
//                 setStopShift({disabled: false, color: '#505050'})
//                 serverApi.workTimeStart(employeeId, `${workDate},${workTime}`, '').then((response) => {
//                     reactLocalStorage.set('timeStart', `${workDate} ${workTime}`)
//                     reactLocalStorage.set('timeFinish', `1970-01-01 03:00:00`)
//                     console.log(response)
//                 })
//             } else {
//                 console.log('Error')
//             }
//         })
//     }
//
//     //Кнопка Закончить работу
//     const stop = () => {
//         let timeFinish = date.getTime();
//         if (User.keyPril === '100-201') {
//             serverApi.workTimeFinish(employeeId, `${workDate},${workTime}`, '').then((response) => {
//                 console.log(response)
//             })
//             console.log(`Вы закончили работать в ${workTime}`)
//             serverApi.getWorkTimeData(workDate, finalDate, User.employeeId).then((response) => {
//                 console.log(response)
//                 let timeStart = response.data[employeeId][response.data[employeeId].length - 1].time_start;
//
//                 //Записывает время конца рабочего дня и передает данные,
//                 //Устанавливает кол-во рабочих часов за сегодняшний день
//                 stopWorkButton(timeStart, timeFinish, workDate);
//                 setStopShift({disabled: true, color: 'rgb(175 175 175)'})
//             })
//         }
//         if (User.keyPril === '100-200') {
//
//             reactLocalStorage.set('timeFinish', `${workDate} ${workTime}`)
//             let timeStart = reactLocalStorage.get('timeStart', '');
//
//             stopWorkButton(timeStart, timeFinish, workDate);
//             setStopShift({disabled: true, color: 'rgb(175 175 175)'})
//         }
//     }

    return (
        <>
            <div id="main" className={isActive ? "bg-black" : ""}>
                <div className="container-menu">


                    <nav className="container-menu-nav">
                        <div className={isActive ? "nav-xbootstrap visible" : "nav-xbootstrap"}>
                            <img style={{width: '100%', paddingTop: 30, paddingLeft: 70, paddingRight: 70}}
                                 src={logo}
                                 alt={logo}/>
                            <ul style={{paddingTop: 0, paddingBottom: 100}}>

                                {/*<li key={'fixingworktime'}>*/}
                                {/*    /!* eslint-disable-next-line jsx-a11y/anchor-is-valid *!/*/}
                                {/*    <a href="#" className={"py2-"}*/}
                                {/*       onClick={async () => {*/}
                                {/*           window.open("/fixingworktime", '_self', false)*/}
                                {/*       }}*/}
                                {/*       style={{fontSize: 18}}>*/}
                                {/*        <FontAwesomeIcon style={{fontSize: 25, paddingRight: 10, color: '#0d6efd'}}*/}
                                {/*                         icon={faMapSigns}/>*/}
                                {/*        {'Учёт рабочего времени'}*/}
                                {/*    </a>*/}
                                {/*</li>*/}
                                {/*Учёт рабочего времени*/}
                                {/*<li key={'startWorkButton'}>*/}
                                {/*    /!* eslint-disable-next-line jsx-a11y/anchor-is-valid *!/*/}
                                {/*    <button className={"py2- menu-shift-btn"}*/}
                                {/*            onClick={start}*/}
                                {/*            id={'startWorkButton'}*/}
                                {/*            disabled={startShift.disabled}*/}
                                {/*            style={{*/}
                                {/*                color: `${startShift.color}`,*/}
                                {/*            }}>*/}
                                {/*        <FontAwesomeIcon style={{fontSize: 25, paddingRight: 10, color: '#0d6efd'}}*/}
                                {/*                         icon={faMapSigns}/>*/}
                                {/*        {startShift.name}*/}

                                {/*    </button>*/}
                                {/*</li>*/}
                                {/*/!*Start to work*!/*/}
                                {/*<li key={'stopWorkButton'}>*/}
                                {/*    /!* eslint-disable-next-line jsx-a11y/anchor-is-valid *!/*/}
                                {/*    <button className={"py2- menu-shift-btn"}*/}
                                {/*            onClick={stop}*/}
                                {/*            id={'stopWorkButton'}*/}
                                {/*            disabled={stopShift.disabled}*/}
                                {/*            style={{*/}
                                {/*                color: `${stopShift.color}`,*/}
                                {/*            }}>*/}
                                {/*        <FontAwesomeIcon style={{fontSize: 25, paddingRight: 10, color: '#0d6efd'}}*/}
                                {/*                         icon={faMapSigns}/>*/}
                                {/*        {'Закончить работать'}*/}
                                {/*    </button>*/}
                                {/*</li>*/}
                                {/*Stop to work*/}


                                <li key={getString(strings.MenuOpora)}>
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <a href="#" className={"py2-"}
                                        // onClick={async () => {
                                        //     window.open("/supports", '_self', false)
                                        // }}

                                       style={{fontSize: 18}}>
                                        <FontAwesomeIcon style={{fontSize: 25, paddingRight: 10, color: '#0d6efd'}}
                                                         icon={faMapSigns}/>
                                        {getString(strings.MenuOpora)}
                                    </a>
                                </li>
                                {/*Opora*/}
                                <li key={getString(strings.MenuAd)}>
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <a href="#" className={"py-2"}
                                        //  onClick={() => logOut()}
                                       onClick={async () => {
                                           window.open("/advertising", '_self', false)
                                           localStorage.removeItem('corMarket')
                                       }}
                                       style={{fontSize: 18}}>
                                        <FontAwesomeIcon style={{fontSize: 25, paddingRight: 10, color: '#0d6efd'}}
                                                         icon={faTicketAlt}
                                                         className={'svg-inline--fa fa-calendar-day fa-w-16 '}/>
                                        {getString(strings.MenuAd)}
                                    </a>
                                </li>
                                {/*Ads*/}
                                <li key={getString(strings.MenuTaskMap)}>
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <a href="#" className={"py-2"}
                                        //     onClick={() => logOut()}
                                       onClick={async () => {
                                           window.open("/taskmap", '_self', false)
                                       }}
                                       style={{fontSize: 18}}>
                                        <FontAwesomeIcon style={{fontSize: 25, paddingRight: 10, color: '#0d6efd'}}
                                                         icon={faMap}
                                                         className={'svg-inline--fa fa-calendar-day fa-w-16 '}/>
                                        {getString(strings.MenuTaskMap)}
                                    </a>
                                </li>
                                {/*TaskMap*/}
                                <li key={getString(strings.MenuTaskCalendar)}>
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <a href="#" className={"py-2"}
                                        //  onClick={() => logOut()}
                                       onClick={async () => {
                                           window.open("/taskcalendar", '_self', false)
                                       }}
                                       style={{fontSize: 18}}>
                                        <FontAwesomeIcon style={{fontSize: 25, paddingRight: 10, color: '#0d6efd'}}
                                                         icon={faCalendarDay}
                                                         className={'svg-inline--fa fa-calendar-day fa-w-16 '}/>
                                        {getString(strings.MenuTaskCalendar)}
                                    </a>
                                </li>
                                {/*TaskCalendar*/}
                                <li key={getString(strings.MenuOverdue)}>
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <a href="#" className={"py-2"}
                                       onClick={() => openOverdue()}

                                       style={{fontSize: 18}}>
                                        <FontAwesomeIcon style={{fontSize: 25, paddingRight: 10, color: '#0d6efd'}}
                                                         icon={faClock}
                                                         className={'svg-inline--fa fa-calendar-day fa-w-16 '}/>
                                        {getString(strings.MenuOverdue)}
                                    </a>
                                </li>
                                {/*Overdue*/}
                                <li key={getString(strings.MenuNearKnotCoupling)}>
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <a href="#" className={"py-2"}
                                        //onClick={() => openNearKnotCoupling()}
                                       onClick={async () => {
                                           window.open("/yamap", '_self', false)
                                       }}
                                       style={{fontSize: 18}}>
                                        <FontAwesomeIcon style={{fontSize: 25, paddingRight: 10, color: '#0d6efd'}}
                                                         icon={faPodcast}
                                                         className={'svg-inline--fa fa-calendar-day fa-w-16 '}/>
                                        {getString(strings.MenuNearKnotCoupling)}
                                    </a>
                                </li>
                                {/*NearCK*/}
                                <li key={getString(strings.MenuVedomost)}>
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <a href="#" className={"py-2"}
                                        //  onClick={() => logOut()}
                                        // onClick={async () => { window.open("/worksheet", '_self', false) }}

                                       style={{fontSize: 18}}>
                                        <FontAwesomeIcon style={{fontSize: 25, paddingRight: 10, color: '#0d6efd'}}
                                                         icon={faChartLine}
                                                         className={'svg-inline--fa fa-calendar-day fa-w-16 '}/>
                                        {getString(strings.MenuVedomost)}
                                    </a>
                                </li>
                                {/*Vedomost*/}
                                <li key={getString(strings.MenuTMC)}>
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <a href="#" className={"py-2"}
                                        // onClick={() => logOut()}
                                       onClick={async () => {
                                           window.open("/tmcemployee", '_self', false)
                                       }}

                                       style={{fontSize: 18}}>
                                        <FontAwesomeIcon style={{fontSize: 25, paddingRight: 10, color: '#0d6efd'}}
                                                         icon={faBriefcase}
                                                         className={'svg-inline--fa fa-calendar-day fa-w-16 '}/>
                                        {getString(strings.MenuTMC)}
                                    </a>
                                </li>
                                {/*TMC*/}
                                <li key={getString(strings.MenuSettings)}>{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <a href="#" className={"py-2"}
                                       onClick={showSet}

                                       style={{fontSize: 18}}>
                                        <FontAwesomeIcon style={{fontSize: 25, paddingRight: 10, color: '#0d6efd'}}
                                                         icon={faCog}
                                                         className={'svg-inline--fa fa-calendar-day fa-w-16 '}/>
                                        {getString(strings.MenuSettings)}
                                    </a>
                                </li>
                                {/*Settings*/}
                                <li key={getString(strings.MenuAbout)}>
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <a href="#" className={"py-2"}
                                       onClick={aboutPrilClick}

                                       style={{fontSize: 18}}>
                                        <FontAwesomeIcon style={{fontSize: 25, paddingRight: 10, color: '#0d6efd'}}
                                                         icon={faQuestionCircle}
                                                         className={'svg-inline--fa fa-calendar-day fa-w-16 '}/>
                                        {getString(strings.MenuAbout)}
                                    </a>
                                </li>
                                {/*About*/}
                                <li key={getString(strings.MenuLogOut)}>
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <a href="#" className={"py-2"}
                                        //  onClick={() => logOut()}
                                       onClick={() => Exit()} //пока так
                                        //localStorage.removeItem('Authorization', Authorization)

                                       style={{fontSize: 18}}>
                                        <FontAwesomeIcon style={{fontSize: 25, paddingRight: 10, color: '#0d6efd'}}
                                                         icon={faDoorOpen}
                                                         className={'svg-inline--fa fa-calendar-day fa-w-16 '}/>
                                        {getString(strings.MenuLogOut)}
                                    </a>
                                </li>
                                {/*LogOut*/}
                            </ul>
                        </div>
                    </nav>
                    <div className='content'>
                    </div>
                </div>
                <nav className="navbar fixed-bottom navbar-light bg-white shadow-lg">

                    <button onClick={async () => {
                        window.open("/newtask", '_self', false)
                    }}
                            type="button" className="btn btn-primary btn-circle">+
                    </button>

                    <div className="float-left">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a onClick={handleToggle} className="navbar-brand text-primary mx-2"
                           href="#"><FontAwesomeIcon
                            icon={faBars}/></a>
                    </div>
                    <div className="float-right text-right">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a onClick={() => {
                            ShowFilterDialog((orderBy) => {
                                let pickedDate = formateDate(new Date(Date.parse(User.currentDate)));
                                getFiltredTasks(pickedDate, pickedDate, orderBy).then(() => {
                                })
                                    .catch((error) => {
                                        console.log(error)
                                    })
                            });
                        }}
                           className="navbar-brand text-primary mr-2" href="#"><FontAwesomeIcon
                            icon={faFilter}/></a>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}


                        <a onClick={() => {
                            ShowCalendarDialog((day) => {
                                let pickedDate = formateDate(new Date(Date.parse(day)));
                                console.log(pickedDate)
                                User.currentDate = pickedDate;
                                document.getElementById('taskPickedDate').innerText = pickedDate;
                                getTasks(pickedDate, pickedDate).then(() => {
                                })
                                    .catch((error) => {
                                        console.log(error)
                                    });
                            })
                        }}

                           className="navbar-brand text-primary mx-2" href="#"><FontAwesomeIcon icon={faCalendar}/></a>
                    </div>
                </nav>

            </div>
        </>
    );
}

export {Menu}

function openOverdue() {
    GetOverdueTasksForm().then(() => {
    });
}

function logOut() {
    User.clear();

    // getLoginForm();
}

function openNearKnotCoupling() {
    getNearKnotCouplingForm().then(() => {
    });
}
