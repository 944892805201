import React from "react";
import { decodeHtmlCharCodes } from "../../../../Utils/String/StringUtils";

export default function Description(props) {

  return (
    <div className="col-sm-12 px-1">
      <div className="form-group">
        <label className="d-block text-primary mb-0">{props.taskOpis}</label>
        <div
          className={"card d-block border-1 px-2 py-2"}>{decodeHtmlCharCodes(props.description)}</div>
      </div>
    </div>
  )
}