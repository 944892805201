import React from "react";
import copyIcon from '../../../../Resources/Icons/copy.svg';
import taskAbonSetIcon from '../../../../Resources/Icons/AboutTask/activity.png';
import copyToClipBoard from "../../functions/copyToClipBoard";

export default function AbonWebActivity(props) {

  return (
    <div id={'networkActivityHolder'} className="col-sm-12">
      <label className="float-left text-primary mb-2">{props.taskNetwork}</label>
      <div className="input-group mb-2">
        <div className="input-group-prepend">
          <span className="input-group-text bg-primary"><img src={taskAbonSetIcon} alt={taskAbonSetIcon} width="20px" /></span>
        </div>
        <div id={"networkActivity"} className={"form-control h-auto smallText card d-block border-1  px-2 py-2"} />
        <div className="input-group-append" onClick={() => copyToClipBoard(document.getElementById('networkActivity').innerText)}>
          <span className="input-group-text"><img src={copyIcon} alt={copyIcon} width="20px" /></span>
        </div>
      </div>
    </div>
  )
}