import React from "react";
import taskAgreementUIcon from '../../../../Resources/Icons/AboutTask/contract.png';
import copyIcon from '../../../../Resources/Icons/copy.svg';
import copyToClipBoard from "../../functions/copyToClipBoard";

export default function AbonAgreement(props) {

  return (
    <div id={"agreementHolder"} className="col-sm-12">
      <label
        className="float-left text-primary mb-0">{props.taskAgreement}</label>
      <div className="input-group mb-2">
        <div className="input-group-prepend">
          <span className="input-group-text bg-primary"><img src={taskAgreementUIcon} alt={taskAgreementUIcon} width="20px" /></span>
        </div>
        <div id={"agreement"}
          className={"form-control h-auto smallText card d-block border-1  px-2 py-2"} />
        <div className="input-group-append"
          onClick={() => copyToClipBoard(document.getElementById('agreement').innerText)}>
          <span className="input-group-text"><img src={copyIcon} alt={copyIcon} width="20px" /></span>
        </div>
      </div>
    </div>
  )
}