import React from "react";
import Button from 'react-bootstrap/Button';
import { render } from "react-dom";
import { ShowLoading } from "../../../../Utils/Dialog/LoadingDialog";
import { ServerApi } from "../../../../Server/ServerApi";
import { CloseLoading } from "../../../../Utils/Dialog/LoadingDialog";
import Images from "../ImagesComp/Images";
import downChevron from '../../../../Resources/Icons/down-chevron.svg';
import downloadIcon from '../../../../Resources/Icons/download.svg';

let commentsAlreadyDownloaded = false;

export default function Comments(props) {
  const css_comment = {
    "whiteSpace": "break-spaces",
    "borderTop": "2px solid #007bff"
  }
  const css_comment1 = {
    "fontWeight": "600"
  }
  //комментарии отображаются некорректно, 
  //функция разделяет на строки
  function replBr(string) {
    return string.replace('<br/>', '\n').replace('<br />', '\n')
  }

  async function spawnComments(comments) {

    let commentsTemps = [];
    for (let i = comments.length - 1; i >= 0; i--) {
      let commId = comments[i]['Id'];

      let idis = [];
      for (let attach in comments[i]['Attaches']) {
        idis.push(comments[i]['Attaches'][attach]);
      }

      commentsTemps.push(
        <div className={"container smallText"}>
          <div className={"card text-left"}>
            <ul className={"list-group list-group-flush"}>
              <li key={`commentAttach${commId}`} className={"list-group-item"}>
                <h5 className={"card-title smallText"} style={css_comment1}>{comments[i]['Operator']}</h5>
                <h6 className={"card-subtitle mb-2 text-muted smallText"}>{comments[i]['DateAdd']}</h6>
                <p className={"card-text smallText"} style={css_comment}>{replBr(comments[i]['CommentText'])}</p>
                <span id={`commentAttach${commId}`} />
                {idis.length > 0 && <Button onClick={() => loadCommentPhoto(commId, comments)} className={"btn btn-primary"}
                  id={`showImageCommentBtn${commId}`}>{props.attachedPhoto} ({idis.length})</Button>}
                <div id={`photoidis${commId}`} hidden={true}>{idis.toString()}</div>
              </li>
            </ul>
          </div>
        </div>);
    }
    await render(commentsTemps, document.getElementById('comments'));
    commentsAlreadyDownloaded = true;
  }
  // async function loadTaskPhoto(photos) {
  //   ShowLoading();
  //
  //   let serverApi = new ServerApi();
  //
  //   document.getElementById('showTaskPhotosBtn').hidden = true;
  //
  //   let bases = [];
  //   let basesFull = [];
  //   for (let photo in photos) {
  //     let base = await serverApi.getImage(photo);
  //     bases.push(base['Low']);
  //     basesFull.push(base['Full']);
  //   }
  //
  //   CloseLoading();
  //
  //   render(<Images images={bases} fullResImages={basesFull} />, document.getElementById('taskAttach'));
  // }

  async function loadCommentPhoto(commentId, commentForHide) {
    ShowLoading();

    for (let i = commentForHide.length - 1; i > 0; i--) {
      let commId = commentForHide[i]["Id"];


      let showImageBtn = document.getElementById(`showImageCommentBtn${commId}`);
      if (showImageBtn !== null) showImageBtn.hidden = false;

      await render(<></>, document.getElementById(`commentAttach${commId}`));
    }

    let serverApi = new ServerApi();
    document.getElementById(`showImageCommentBtn${commentId}`).hidden = true;

    let photoIdis = document.getElementById(`photoidis${commentId}`).innerText;

    if (photoIdis.indexOf(',') !== -1) {
      let idis = photoIdis.split(',');
      let photosSrc = [];
      let photosFullSrc = [];

      for (let i = 0; i < idis.length; i++) {
        let base64 = await serverApi.getImage(idis[i]);

        base64 = JSON.parse(JSON.stringify(base64));

        photosSrc.push(base64['Low']);
        photosFullSrc.push(base64['Full']);
      }

      CloseLoading();

      await render(<Images images={photosSrc} fullResImages={photosFullSrc} />, document.getElementById(`commentAttach${commentId}`));

      return;
    }
    console.log(photoIdis)
    let base64 = await serverApi.getImage(photoIdis);
    base64 = JSON.parse(JSON.stringify(base64));

    CloseLoading();

    await render(<Images images={[base64['Low']]} fullResImages={[base64['Full']]} />, document.getElementById(`commentAttach${commentId}`));
  }

  return ((props.comments !== '' &&  //Комментарии
    <>
      <div className="col-sm-12 my-2 px-1">
        <div className="clearfix">
          <a data-toggle="collapse" href={"#commentCollapse"} role="button"
            className={"text-decoration-none"}
            aria-expanded="false" aria-controls="collapseExample">
            <div onClick={() => {
              if (commentsAlreadyDownloaded) {
                return;
              }

              props.userSideApi.getComments(props.comments).then(comments => {
                spawnComments(comments);
              });
            }} className="input-group mb-0">
              <input type="text" className="bg-primary text-light form-control smallText"
                defaultValue={props.taskComments} disabled />
              <div className="input-group-append">
                <span className="input-group-text"><img src={downChevron} alt={downloadIcon} width="20px" /></span>
              </div>
            </div>
          </a>
        </div>
        <div className="collapse smallText" id={"commentCollapse"}>
          <div id={'comments'} className="card card-body border-top-0 px-0" />
        </div>
      </div>
    </>
  )
  )
}