import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { render } from "react-dom";
import { getString, strings } from "../../Languages/Languages";
import { User } from "../../User/User";
import { ServerApi } from "../../Server/ServerApi";
import { CloseLoading, ShowLoading } from "./LoadingDialog";

import ListFieldType from './../../Templates/AboutPages/AboutComponents/CloseTask/ListFieldType';
import TextFieldType from './../../Templates/AboutPages/AboutComponents/CloseTask/TextFieldType';
import MultiplySelect from "../../Templates/AboutPages/AboutComponents/CloseTask/MultiplySelect";
import StringFieldType from "../../Templates/AboutPages/AboutComponents/CloseTask/StringFieldType";
import FlagFieldType from "../../Templates/AboutPages/AboutComponents/CloseTask/FlagFieldType";
import DateFieldType from "../../Templates/AboutPages/AboutComponents/CloseTask/DateFieldType";
import NumberFieldType from "../../Templates/AboutPages/AboutComponents/CloseTask/NumberFieldType";
import CounterFieldType from "../../Templates/AboutPages/AboutComponents/CloseTask/CounterFieldType";
import ListCustomFieldType from "../../Templates/AboutPages/AboutComponents/CloseTask/ListCustomFieldType";
import ImagesSwiperPreviewClose from "../../Templates/AboutPages/AboutComponents/ImagesPreview/ImagesSwiperPreviewClose";
import { ShowDialog } from './Dialog'






function CloseTaskDialog(props) {
    const [state, setState] = useState([]);
    const [reqState, setReqState] = useState([]);
    const [btnState, setBtnState] = useState({
        value: getString(strings.CloseTheTask),
        btn: false
    });
    let sendObj = {};
    let serverApi = new ServerApi();
    const typeId = JSON.parse(User.lastAboutTask).TypeId

    useEffect(() => {
        ShowLoading();
        serverApi.closeTaskAditional(typeId).then((response) => {

                console.log(JSON.parse(response))
                const res = JSON.parse(response);
                CloseLoading();
                setState(res);
                res.forEach((i) => {
                    if (i.is_required) {
                        setReqState((prev) => [...prev, i.id])
                    }
                });
            serverApi.GetShow(User.taskId).then((response) => {
                if (response.tasks[0].Status === getString(strings.CheckTheTask)) {
                    setBtnState({ value: getString(strings.OnCheckTask), btn: true })
                }

            })
                .catch((error) => {
                    console.log(error)
                });
        })
            .catch((error) => {
                console.log(error)
            });
    }, [])

    const onHandleChange = (event) => {
        let name = event.target.name;
        let value = event.target.textContent;
        const btn = document.getElementById(name);
        btn.innerHTML = value;
        sendObj[`${name}`] = value;

        if (value === 'Сделать фото') {
            let el = document.createElement('div')
            btn.after(el)
            render(<ImagesSwiperPreviewClose
                hidden={false}
                fieldId={name}
            />, el)
            document.getElementById('Hidden').hidden = false;
        }
    }
    const onFlagChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        if (value === true) {
            value = '1'
        }
        else {
            value = '0'
        }
        sendObj[`${name}`] = value;
    }

//TODO: Languages!
    const handleSubmit = (event) => {
        event.preventDefault();
        let keyObj = Object.keys(sendObj)
        reqState.forEach((req) => {
            if (!keyObj.includes(String(req))) {
                console.log(props)
                render (
                    <Modal
                        show={props.show}
                        onHide={props.onHide}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        style={{ maxWidth: '100%', minHeight: '50vh' }}
                    >

                            <Button onClick={() => props.onHide()} className={"btn btn-dangerCancelTask"}>{getString(strings.Cancel)}</Button>

                    </Modal>, document.getElementById('contained-modal-title-vcenter')
                    )

                // alert('Заполните обязательные поля!')
            }
            else {
                serverApi.changeAdditionalFieldValue(JSON.stringify(sendObj)).then((response) => {
                    console.log(response)
                    props.onHide()
                })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        })
        //Получение доп. полей
        // serverApi.sendCloseTaskImage(JSON.stringify(sendObj)).then((response) => {
        //     console.log(response)
        // })
        //Проверка значения доп. поля
        // serverApi.getAdditionalFieldValue('89').then((response) => {
        //     console.log(response)
        // })
    }
    let fieldType = (item) => {
        let valueArr
        if('available_value' in item) {
            valueArr = item['available_value'][0].replace("&#047;", "/").replace(/\t/g, "  ").split('\n');
        }

        switch (item.type) {
            case 'list':
                return (
                    <ListFieldType
                        id={item.id}
                        name={item['name']}
                        onHandleChange={onHandleChange}
                        valueArr={valueArr}
                        required={item.is_required}
                    />
                )
            case 'text':
                return (<TextFieldType
                    id={item.id}
                    name={item['name']}
                    onHandleChange={onHandleChange}
                    required={item.is_required} />
                )
            case 'flag':
                return (
                    <FlagFieldType
                        id={item.id}
                        name={item['name']}
                        onChange={onFlagChange}
                        required={item.is_required}
                    />
                )
            case 'counter':
                return (
                    <CounterFieldType
                        id={item.id}
                        name={item['name']}
                        onHandleChange={onHandleChange}
                        required={item.is_required} />
                )
            case 'number':
                return (
                    <NumberFieldType
                        id={item.id}
                        name={item['name']}
                        onHandleChange={onHandleChange}
                        required={item.is_required} />
                )
            case 'date':
                return (
                    <DateFieldType
                        id={item.id}
                        name={item['name']}
                        onHandleChange={onHandleChange}
                        required={item.is_required} />
                )
            case 'string':
                return (<StringFieldType
                    id={item.id}
                    name={item['name']}
                    onHandleChange={onHandleChange}
                    required={item.is_required} />
                )
            case 'list_custom':
                return (
                    <ListCustomFieldType
                        id={item.id}
                        name={item['name']}
                        onHandleChange={onHandleChange}
                        valueArr={valueArr}
                        required={item.is_required} />
                )
            case 'list_custom_multiply':
                return (
                    <MultiplySelect
                        id={item.id}
                        name={item['name']}
                        onHandleChange={onHandleChange}
                        valueArr={valueArr}
                        required={item.is_required} />
                )
            default:
                return undefined

        }
    }


    return (

        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ maxWidth: '100%', minHeight: '50vh' }}
        >

            <Modal.Header style={{ maxWidth: '100%', minHeight: '40vh' }}>
                <Modal.Title style={{ width: '100%' }} id="contained-modal-title-vcenter">


                    {state.map((item) => {
                        const str = "Землянка\t\t900 руб.\tдо 15 Мбит&#047;сек\t\nИзба\t\t\t1 000 руб.\tдо 45 Мбит&#047;сек\t\nКоттедж\t\t1 200 руб.\tдо 60 Мбит&#047;сек\nОсобняк\t\t1 500 руб.\tдо 80 Мбит&#047;сек\nВилла\t\t\t2 100 руб.\tдо 100 Мбит&#047;сек\t + внешний IP-адрес\nЗамок\t\t\t2 750 руб.\tдо 150 Мбит&#047;сек\t + внешний IP-адрес\nGPON 50\t\t950 руб.\tдо 50 Мбит&#047;сек\t\nGPON 75\t\t1 200 руб.\tдо 75 Мбит&#047;сек\t\nGPON 100\t\t1 500 руб.\tдо 100 Мбит&#047;сек\t\nGPON 200\t\t2 100 руб.\tдо 200 Мбит&#047;сек\t+ внешний IP-адрес\nGPON 300\t\t3 000 руб.\tдо 300 Мбит&#047;сек\t+ внешний IP-адрес\nинТВернет \t\t1050 руб.  до 50 Мбит&#047;сек  111 телеканала\nКИНтернет\t\t1150 руб.  до 55 Мбит&#047;сек  134 телеканала + MEGOGO\nКИНтернет X2\t1350 руб.  до 75 Мбит&#047;сек  134 телеканала + MEGOGO\nинТВернет MAX  1500 руб.  до 75 Мбит&#047;сек  184 телеканала + IVI\nСкидка, Тариф SOS до 55 Мбит&#047;сек  134 телеканала + MEGOGO\nКратово Start   850руб - 50Мб&#047;с\nКратово Opti   950руб - 100Мб&#047;с\nКратово ProFI   1500руб - 250Мб&#047;с\nКратово Guru   1850руб - 500Мб&#047;с\nКратово VIP   2500руб - 750Мб&#047;с"
                        const valueArr = str.replace("&#047;", "/").replace(/\t/g, "  ").split('\n');
                        // const valueArr = item['available_value'][0].replace("&#047;", "/").replace(/\t/g, "  ").split('\n');
                        return (
                            <div className={"TaskClose"} key={item.id} >
                                <div className="col-auto my-1">

                                    {fieldType(item)}
                                    {/* <ListCustomFieldType
                                        name={item['name']}
                                        id={item.id}
                                        onHandleChange={onHandleChange}
                                        valueArr={valueArr}
                                    /> */}

                                    {/* <FlagFieldType
                                        name={item['name']}
                                        onChange={onFlagChange} /> */}
                                    {/* <CounterFieldType
                                        id={item.id}
                                        onHandleChange={onHandleChange} />
                                    <NumberFieldType
                                        id={item.id}
                                        onHandleChange={onHandleChange} /> */}

                                </div>
                            </div>
                        )
                    })}
                </Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button onClick={() => props.onHide()} className={"btn btn-dangerCancelTask"}>{getString(strings.Cancel)}</Button>
                <Button onClick={handleSubmit} className="btn btn-dangerCloseTask" disabled={btnState.btn}>{btnState.value}</Button>
            </Modal.Footer>
        </Modal >

    );
}

function GetDialog() {
    const [modalShow, setModalShow] = useState(true);


    return (
        <>
            <CloseTaskDialog
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>);
}

function ShowCloseTheTask() {
    if (!navigator.onLine) {
        ShowDialog({
            'title': getString(strings.Attention),
            'body': getString(strings.Offline),

            'pButtonClick': function () { },

            'pbtn': 'true',
            'nebtn': 'false',

            'pbtntitle': getString(strings.Ok),
        });
        return
    }

    render(<GetDialog />, document.createDocumentFragment());
}
export { ShowCloseTheTask };
