import {Button} from "react-bootstrap";
import {getString} from "../Languages/Languages";
import {strings} from "../Languages/Languages";
import {Form} from 'react-bootstrap';
import ReactHtmlParser from 'html-react-parser';
import {useParams} from "react-router-dom";
import {User} from "../User/User";
import button from "bootstrap/js/src/button";

let commentsAlreadyDownloaded = false;

function GetCommutationVariants() {
    const { taskI } = useParams();

    let propsT, props;
    propsT = JSON.parse(User.shortTasks);

    for (let i = 0; i < propsT.length; i++) {
        if (propsT[i]["Id"] == taskI) {
            props = propsT[i];
        }
    }
            let test = "";
    if (props["Nodes"] != "") {
    for (let propsKey of props["ComNodes"]) {
        test += `<form action="/commutationPhoto/${propsKey["Id"]}">
        <button class="CommutationButton">${propsKey["Name"]}</button>
         </form>`;
        }
    }

    return(
        <div>
            <div className={"CommutationForm"}>
               <Form id={"CommutationVariants"}>
               <br/>
                   {ReactHtmlParser(test)}
                <br/><br/>
                <Button type={"button"} onClick={() => {window.history.back();commentsAlreadyDownloaded = false;}}
                        className={"CommutationClose"}>{getString(strings.Close)}</Button>
               </Form>
            </div>
        </div>
    );
}
export default GetCommutationVariants;