import {render} from "react-dom";
import {appElementTag} from "../index";

export function switchForm(formTemplate)
{
    render(formTemplate, document.getElementById(appElementTag));
}

export async function switchFormAsync(formTemplate)
{
    await render(formTemplate, document.getElementById(appElementTag));
}
