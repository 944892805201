import React from "react";
import {User} from "../../User/User";
import {ServerApi} from "../../Server/ServerApi";

export default function stopWorkButton(timeStart, timeFinish, workDate) {
    let serverApi = new ServerApi();
    console.log(timeStart, timeFinish, workDate)
    let a = timeStart.split(' ')[1];

    let h = Number(a.split(':')[0]);
    let m = Number(a.split(':')[1]);
    let d = new Date();

    d.setHours(h, m)

    let workT = ((timeFinish - d.getTime())/(60 * 60 * 1000)).toFixed(1)
    if(workT > 8) {
        workT = Math.abs(8 - workT);
        let overWorkMin = (workT.toString()).split('.')[1];
        if(overWorkMin >= 3) {
            overWorkMin = 1
        }
        else {
            overWorkMin = 0
        }
        let overWork = toString(Math.floor(workT) + overWorkMin)
        let shiftT = 8 + Number(overWork)

        serverApi.setTimesheetData(User.employeeId, workDate, '1', '8').then((response) => {
            if (response.status === 'OK') {
                console.log(response)

            } else {
                console.log('Error')
            }
        })
        serverApi.setTimesheetData(User.employeeId, workDate, '2', overWork).then((response) => {
            if (response.status === 'OK') {
                console.log(`Ваше рабочее время: ${shiftT}`)

            } else {
                console.log('Error')
            }
        })

    }
    else {
        let workMin = workT.toString().split('.')[1]
        if(workMin >= 3) {
            workMin = 1
        }
        else {
            workMin = 0
        }
        let shiftT = Math.floor(workT) + workMin
        serverApi.setTimesheetData(User.employeeId, workDate, '1', shiftT).then((response) => {
            if (response.status === 'OK') {
                console.log(`Ваше рабочее время: ${shiftT}`)

            } else {
                console.log('Error')
            }
        })

    }
}