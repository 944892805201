import {getString, strings} from "../Languages/Languages";
import {ServerApi} from "../Server/ServerApi";
import {useParams} from "react-router-dom";
import React from "react";
import {CloseLoading, ShowLoading} from "../Utils/Dialog/LoadingDialog";

let commentsAlreadyDownloaded = false;

function GetCommutationPhoto(){
    const { nodeId} = useParams();
    let serverApi = new ServerApi();

    const [ url, setUrl ] = React.useState(null);
    React.useEffect(() => {
        ShowLoading();
        serverApi.getNodeScheme(nodeId).then((response) => {
            setUrl(response);
            console.log(response)
            CloseLoading();
        })
            .catch((error) => {
                console.log(error)
            })
        }, [])


    return(
        <div>
            <div className={"CommutationForm"}>
                <br/>
            <img src={url} id="comm" className={"CommutationIMGPhoto"}/>
                    <br/><br/>
                    <button onClick={() => {window.history.back();commentsAlreadyDownloaded = false;}}
                            className={"CommutationButtonPhoto"}>{getString(strings.Back)}</button>
                    <br/>
            </div>
        </div>
    );
}
export default GetCommutationPhoto;