import {ServerRequests} from "../Server/ServerRequests";
import {reactLocalStorage} from "reactjs-localstorage";

class Config
{
    static UserSide =
    {
        get() { return reactLocalStorage.get('UserSide', ''); },
        set(value) { reactLocalStorage.set('UserSide', value); }
    }
    static ApiKey =
    {
        get() { return reactLocalStorage.get('ApiKey', ''); },
        set(value) { reactLocalStorage.set('ApiKey', value); }
    }
    static Utilities1Sa =
    {
        get() { return reactLocalStorage.get('Utilities1Sa', ''); },
        set(value) { reactLocalStorage.set('Utilities1Sa', value); }
    }
    static Utilities1Su =
    {
        get() { return reactLocalStorage.get('Utilities1Su', ''); },
        set(value) { reactLocalStorage.set('Utilities1Su', value); }
    }
    static StateId =
    {
        get() { return reactLocalStorage.get('StateId', ''); },
        set(value) { reactLocalStorage.set('StateId', value); }
    }
    static StateIdClose =
    {
        get() { return reactLocalStorage.get('StateIdClose', ''); },
        set(value) { reactLocalStorage.set('StateIdClose', value); }
    }
    static RelatedTaskId =
    {
        get() { return reactLocalStorage.get('RelatedTaskId', ''); },
        set(value) { reactLocalStorage.set('RelatedTaskId', value); }
    }
    static TypeId =
    {
        get() { return reactLocalStorage.get('TypeId', ''); },
        set(value) { reactLocalStorage.set('TypeId', value); }
    }
    static StateIdType =
    {
        get() { return reactLocalStorage.get('StateIdType', ''); },
        set(value) { reactLocalStorage.set('StateIdType', value); }
    }
    static TaskLimit =
    {
        get() { return reactLocalStorage.get('TaskLimit', ''); },
        set(value) { reactLocalStorage.set('TaskLimit', value); }
    }
    static ArrCustomer14 =
    {
        get() { return reactLocalStorage.get('ArrCustomer14', ''); },
        set(value) { reactLocalStorage.set('ArrCustomer14', value); }
    }
    static PaymentServiceId =
    {
        get() { return reactLocalStorage.get('PaymentServiceId', ''); },
        set(value) { reactLocalStorage.set('PaymentServiceId', value); }
    }
    static RoleId =
    {
        get() { return reactLocalStorage.get('RoleId', ''); },
        set(value) { reactLocalStorage.set('RoleId', value); }
    }
    static UserSideVersion =
    {
        get() { return reactLocalStorage.get('UserSideVersion', ''); },
        set(value) { reactLocalStorage.set('UserSideVersion', value); }
    }

    static UserSide315 = '11';
    static UserSide316 = '12';

    static async getConfig(deviceId, keyPrill)
    {
        let config = await new ServerRequests().getUserSideConfig(deviceId, keyPrill);

        try
        {
            let configJson = JSON.parse(JSON.stringify(config));

            Config.Utilities1Sa.set(configJson['Utilities1SA'] ?? '');
            Config.Utilities1Su.set(configJson['Utilities1SU'] ?? '');

            Config.ApiKey.set(Buffer.from(Buffer.from(configJson['1'], "base64").toString()
                .replace(Config.Utilities1Sa.get(), ""), "base64").toString());

            Config.UserSide.set(Buffer.from(Buffer.from(configJson['2'], "base64").toString()
                .replace(Config.Utilities1Su.get(), ""), "base64").toString());

            Config.StateId.set(configJson['3'] ?? '');
            Config.StateIdClose.set(configJson['8'] ?? '');
            Config.RelatedTaskId.set(configJson['12'] ?? '');
            Config.TypeId.set(configJson['9'] ?? '');
            Config.StateIdType.set(configJson['10'] ?? '');
            Config.TaskLimit.set(configJson['13'] ?? '');
            Config.ArrCustomer14.set(configJson['14'] ?? '');
            Config.PaymentServiceId.set(configJson['23'] ?? '');
            Config.RoleId.set(configJson['Role'] ?? '');
            Config.UserSideVersion.set(configJson['17'] ?? '');

            return true;
        }
        catch (e)
        {
            console.log(`CONFIG ERR ${e}`);
            return false;
        }
    }

    static printConfig()
    {
        console.log(`UserSide - ${Config.UserSide.get()}\n
        ApiKey - ${Config.ApiKey.get()}\n
        Utilities1Sa - ${Config.Utilities1Sa.get()}\n
        Utilities1Su - ${Config.Utilities1Su.get()}\n
        StateId - ${Config.StateId.get()}\n
        StateIdClose - ${Config.StateIdClose.get()}\n
        RelatedTaskId - ${Config.RelatedTaskId.get()}\n
        TypeId - ${Config.TypeId.get()}\n
        StateIdType - ${Config.StateIdType.get()}\n
        TaskLimit - ${Config.TaskLimit.get()}\n
        ArrCustomer14 - ${Config.ArrCustomer14.get()}\n
        PaymentServiceId - ${Config.PaymentServiceId.get()}\n
        RoleId - ${Config.RoleId.get()}\n
        RoleId - ${Config.UserSideVersion.get()}\n`);
    }
}
export {Config};
