import React from "react";
import {Modal} from "react-bootstrap";
import {Button} from "react-bootstrap";
import {useState} from "react";
import {render} from "react-dom";
import {getString, strings} from "../../Languages/Languages";

function InfrastructureMapDialog(props)
{
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <p/>
                <div className="balloonIn">{getString(strings.Names)}</div>
                <div className="balloonI">{props.Names}</div>
                <div className="balloonIn">{getString(strings.Address)}:</div>
                <div className="balloonI">{props.Address}</div>
                <div className="balloonIn">{getString(strings.Entrance)}</div>
                <div className="balloonI">{props.Entrance}</div>
                <div className="balloonIn">{getString(strings.JobObject)}</div>
                <div className="balloonI">{props.JobObject}</div>
                <div class="balloonIn">{getString(strings.TaskNotes)}</div>
                <div class="balloonI">{props.TaskNotes}</div>
                <div className="balloonIn">{getString(strings.CouplingType)}</div>
                <div className="balloonI">{props.CouplingType}</div>
                {props.Photo}
                <div className="balloonIn">{getString(strings.Photo)}</div>
                <div className="balloonInfa" id="balloonInfa">
                </div>
            </Modal.Body>
            <Modal.Footer>
                {props.Navigator}
                <Button className={"btn btn-dangerCancelTask"} onClick={ () => props.onHide() }>{getString(strings.Cancel)}</Button>
            </Modal.Footer>
        </Modal>
    );
}

function GetDialog(props) {
    const [modalShow, setModalShow] = useState(true);

    return(
        <>
            <InfrastructureMapDialog
                Names={props.Names}
                Address={props.Address}
                Entrance={props.Entrance}
                JobObject={props.JobObject}
                TaskNotes={props.TaskNotes}
                CouplingType={props.CouplingType}
                Photo={props.Photo}
                Navigator={props.Navigator}
                onApplyclick={props.onApplyclick}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>);
}

function ShowInfrastructureMapDialog(onApplyClick, Names, Address, Entrance, JobObject, TaskNotes, CouplingType, Photo, Navigator)
{
    render (<GetDialog onApplyclick={onApplyClick}
                       Names={Names}
                       Address={Address}
                       Entrance={Entrance}
                       JobObject={JobObject}
                       TaskNotes={TaskNotes}
                       CouplingType={CouplingType}
                       Photo={Photo}
                       Navigator={Navigator}
    />, document.createDocumentFragment());
}
export {ShowInfrastructureMapDialog};