import React, {Component} from 'react';
import {YMaps, Map, Circle, Placemark, TypeSelector, ZoomControl, FullscreenControl, GeolocationControl, RouteButton} from 'react-yandex-maps';
import axios from "axios";
import {getString, strings} from "../../Languages/Languages";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter} from "@fortawesome/free-solid-svg-icons";
import {ShowKnotClutchFilter} from "../../Utils/Dialog/KnotClutchFilterDialog";
import {ShowDialog} from "../../Utils/Dialog/Dialog";
import {ShowKnotDot} from "../../Utils/Dialog/KnotDotDialog";
import {ServerApi} from "../../Server/ServerApi";
import {User} from "../../User/User";

const url = 'https://user.intronex.ru/api.php';

let commentsAlreadyDownloaded = false;
let serverApi = new ServerApi();
let lat;
let lon;
let latPlacemark;
let lonPlacemark;
let visible = false;

function resultLocation(){
    navigator.geolocation.getCurrentPosition(function(position) {
        lat = position.coords.latitude
        lon = position.coords.longitude
    });
}

const onCl = (ev) => {
    let serverApi = new ServerApi();
    latPlacemark = ev.originalEvent.target.geometry['_coordinates'][0]
    lonPlacemark = ev.originalEvent.target.geometry['_coordinates'][1]

    let rangeC = Number(document.getElementById("customRange3").value);
    serverApi.get_id_by_coord(lat,lon,rangeC,'').then(result => {
        let dataID = result.data
        for (let aaaID in dataID){
            if (latPlacemark === dataID[aaaID].lat && lonPlacemark === dataID[aaaID].lon){
                let ID = dataID[aaaID].id
                let distance = dataID[aaaID].distance

                serverApi.getInfras(ID).then(result => {
                    for (let aaaBalloon in result.data){
                        let balloon = result.data[aaaBalloon]
                        let numberBalloon = balloon.number
                        let nameBalloon = balloon.name
                        let idBalloon = balloon.id

                        let type = balloon.type
                        serverApi.getTypeList().then(result => {
                            let typeBalloon = result.data[type].single_name
                            ShowKnotDot(() => {
                                document.location.href = "yandexmaps://maps.yandex.ru/?rtext=" + lat + "," + lon + "~" + latPlacemark +"," + lonPlacemark;
                            },typeBalloon, distance + 'm', nameBalloon,'id ' + idBalloon, '№ ' + numberBalloon)

                        })
                    }
                })
            }
        }
    })
};

const mapState = {
    center: [55.76, 37.64],
    zoom: 10,
    type: 'yandex#map',
    bounds: undefined,
    range: 500,
    coordinates: [],
};

let arrCoordinates = [];
let coordinates;

class KnotCoupling extends Component {
    state = {
        center: [55.76, 37.64],
        zoom: 10,
        type: 'yandex#map',
        bounds: undefined,
        range: 500,
        coordinates: [],
        height: '500px',
    }

    setCenter(prevState) {
        return Object.assign({}, prevState, { center: [55.76, 37.6] });
    }

    setBounds(prevState) {
        return Object.assign({}, prevState, { bounds: [[37, 38], [39, 40]] });
    }

    setType(prevState) {
        return Object.assign({}, prevState, { type: 'yandex#publicMapHybrid' });
    }

    setDefault(prevState) {
        return Object.assign({}, prevState, mapState);
    }

    setRange(prevState) {
            let rangeC = Number(document.getElementById("customRange3").value);

        if(User.type_obj == '') {
            if (lat === undefined || lat === '' ||
                lon === undefined || lon === '') {
                ShowDialog({
                    'title': getString(strings.Attention),
                    'body': getString(strings.FailedLocateLocation),

                    'pButtonClick': function () {
                    },

                    'pbtn': 'true',
                    'nebtn': 'false',

                    'pbtntitle': getString(strings.Ok),
                });
            }
            let Results;
            serverApi.get_id_by_coord(lat, lon, rangeC, '').then(result => {
                Results = result.result
                if (Results == 'OK') {
                    arrCoordinates = []
                    result.data.map((item) => {
                        let arrItem = [item.lat, item.lon];
                        console.log(arrItem)
                        arrCoordinates.push(arrItem);
                        console.log(arrCoordinates)

                    });
                    let range = rangeC;
                    let center = [lat, lon];
                    coordinates = arrCoordinates;
                  //  console.log(coordinates)
                   // console.log(coordinates)
                    visible = true;
                    this.setState({range, center, coordinates});
                }
            })
        }

        if(User.type_obj != '') {
            if (lat === undefined || lat === '' ||
                lon === undefined || lon === '') {
                ShowDialog({
                    'title': getString(strings.Attention),
                    'body': getString(strings.FailedLocateLocation),

                    'pButtonClick': function () {
                    },

                    'pbtn': 'true',
                    'nebtn': 'false',

                    'pbtntitle': getString(strings.Ok),
                });
            }
            let Result;
            serverApi.get_id_by_coord(lat,lon,rangeC,User.type_obj).then(result => {
                Result = result.result
                if (Result == 'OK') {
                    arrCoordinates = []
                    result.data.map((item) => {
                        let arrItem = [item.lat, item.lon];
                        arrCoordinates.push(arrItem);
                    });
                    let range = rangeC;
                    let center = [lat, lon];
                    coordinates = arrCoordinates;
                    visible = true;
                    this.setState({range, center, coordinates});
                }
            })
        }
    }

    onChange(prevState) {
        let rangeC = Number(document.getElementById("customRange3").value);
        return Object.assign({}, prevState, { range: rangeC,coordinates: [] });
    }

    updateState(fn) {
        return () => this.setState(fn);
    }

    componentDidMount() {
        let height = document.documentElement.clientHeight
        height = height - 168

        this.setState({height});
    }

    render() {
        return (
            <div className={"YamapForm"} >
                <br/>
            <YMaps
                query={{ apikey: '3261ce08-c60a-4114-96f8-ce820abf124a' }}>
                <div>
                      <Map state={this.state}
                           id="test"
                           width='100%'
                           height={this.state.height}
                      >
                        <TypeSelector options={{ float: 'right' }} />
                        <ZoomControl options={{ float: 'right' }} />
                        <FullscreenControl />
                          <GeolocationControl onClick={() => resultLocation()}
                              options={{float: 'left'}}/>
                        <Circle
                            geometry={[this.state.center, this.state.range]}
                            options={{
                                fillColor: '#DB709377',
                                strokeColor: '#990066',
                                strokeOpacity: 0.8,
                                strokeWidth: 5,
                                draggable: false,
                                visible: visible
                            }}/>
                            {this.state.coordinates.map((mark, index) => (
                                <Placemark  onClick={onCl}
                                           key={index}
                                           geometry={mark}
                                />
                            ))}
                          <RouteButton options={{
                              float: 'right'
                          }} />
                      </Map>
                    <div>{JSON.stringify(this.state.range)}</div>
                    <input type="range" className="YamapInput" min="1" max="501" step="10" id="customRange3"
                           defaultValue="200" onChange={this.updateState(this.onChange)} />
                    <button type='button' className={"YamapRange"} onClick={this.updateState(this.setRange)}>{getString(strings.Apply)}</button>
                    <div>
                        <Button onClick={() => {window.history.back();commentsAlreadyDownloaded = false;localStorage.removeItem('type_obj')}}
                                className={"YamapBack"}>{getString(strings.Back)}</Button>

                        <button type="button" className="btn btn-primary"
                                onClick={() => ShowKnotClutchFilter(() => {
                                    window.location.reload()
                                })}>
                            <FontAwesomeIcon icon={faFilter}/></button>
                    </div>
                </div>
            </YMaps>
            </div>
        );
    }
}

export default KnotCoupling;