import React from "react";
import {getString, strings} from "../../../../Languages/Languages";

export default function ListCustomFieldType(props) {
  let text = ''
  if (props.required) {
    text = getString(strings.RequiredToFillIn);
  }

  return (
    <>
      <label className="mr-sm-2">{props.name}</label>
      <button
        className="btn btn-secondarys dropdown-toggle" type="button"
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id={props.id} >
      </button>
      <div id={"dropdown-menuId2"} className="dropdown-menu longtaski" aria-labelledby="dropdownMenuButton" >
        {props.valueArr.map((opt) => {
          return (
            <button onClick={props.onHandleChange} className="dropdown-itemCloseTask" data-value="dropdown-item2" key={opt} name={props.id}>{opt}</button>
          )
        })}
      </div>
      <p id='forRequiredField' style={{ fontSize: '0.8em' }}>{text}</p>
    </>
  )
}