/* eslint-disable no-unused-vars */
import React from "react";
import ReactSwipe from 'react-swipe';
import { ShowBigImage } from "../../../../Utils/Dialog/BigImageDialog";

export default function Images(props) {
  let reactSwipeEl;
  console.log(props)
  let divs = [];
  let imagesCount = props['images'].length;
  let fulResImgs = [];
  let defImgs = [];
  let comm = [];
  for (let i = 1; i <= imagesCount + 1 + (imagesCount % 3); i++) {
    if (i % 3 === 0) {
      let images = [];
      for (let j = i - 3; j < i; j++) {
        if (j < imagesCount) {
          if (props['comment']) {
            comm.push(props['comment'][j]);
          } else {
            comm.push('');
          }

          images.push(props['images'][j]);
          defImgs.push(props['images'][j]);
          fulResImgs.push(props['fullResImages'][j]);
        }
      }
      divs.push(images);
    }
  }

  return (
    <ReactSwipe
      className="swipe-wrap carousel"
      swipeOptions={{ continuous: false }}
      ref={el => (reactSwipeEl = el)}>

      {divs.map(div =>
        <div className={"container"} key={div}>
          {div.map(src => <img onClick={(e) => {
            ShowBigImage(fulResImgs, defImgs, comm, e.target.getAttribute("src"));
          }} style={{ maxHeight: 100, width: '33%' }} src={`${src}`} alt={`${src}`} className={"col-sm-4 img-thumbnail"} key={src} />)}
        </div>)}
    </ReactSwipe>
  );
}