import Calendar from "react-calendar";
import React from "react";
import {getString, strings} from "../Languages/Languages";
import {Button} from "react-bootstrap";
import {formateDate} from "../Utils/String/StringUtils";
import {ServerApi} from "../Server/ServerApi";
import {User} from "../User/User";
import {CloseLoading, ShowLoading} from "../Utils/Dialog/LoadingDialog";

let commentsAlreadyDownloaded = false;
let pickedDate;

function Days (day){
    pickedDate = formateDate(new Date(Date.parse(day)));
    document.querySelector('#AssignmentsFor').innerHTML =
            ` <div  class='AssignmentsFor'>${getString(strings.AssignmentsFor)+ ' ' + pickedDate}</div>`
    AssignmentsFor()
}

function AssignmentsFor(){
let limit = '10';
    ShowLoading();
    let serverApi = new ServerApi();
    serverApi.getTaskList(User.divisionId, User.employeeId, User.keyPril, pickedDate, pickedDate, limit).then(result => {
        let phonesRemove = document.getElementById('typeTaskList')
        while (phonesRemove.firstChild){
            phonesRemove.removeChild(phonesRemove.lastChild)
        }
        let list = result.list.list
        let type = result.tasks
        for (let aaaKey in type) {
            let typeTask = type[aaaKey].Type
            document.querySelector('#typeTaskList').innerHTML +=
              `<button id="TaskCalendarButton" class="dropdown-item TaskCalendar" data-nam=${list} >${typeTask + ' ' + list}</button>`
        }
        const TaskCalendarButton = document.querySelectorAll("#TaskCalendarButton")
        TaskCalendarButton.forEach(TaskCalendarButton => {
            TaskCalendarButton.addEventListener('click', TaskCalendarButtons)
        })
        CloseLoading();
    })
        .catch((error) => {
            console.log(error)
        })
}

function TaskCalendarButtons (event) {
    let id = Number(event.target.dataset.nam)
    window.open("/about/" + id, '_self', false)
}

function TaskCalendar()
{
    return (
        <div>
       <div className={"TaskCalendarForm"}>
           <br/><br/>
           <Calendar onClickDay={(day) => Days(day)}/>
           <br/>
           <div id={'AssignmentsFor'}>
           </div>
           <br/>
           <div id={'typeTaskList'}>
           </div>
           <br/>
           <Button onClick={() => {window.history.back();commentsAlreadyDownloaded = false;}}
                   className={"YamapBackAbon"}>{getString(strings.Back)}</Button>
           <p/>
       </div>
        </div>
    );
}
export default TaskCalendar;

