import React from "react";
import AbonInfo from "./AbonInfo";
import AbonIp from "./AbonIp";
import AbonPhone from "./AbonPhone";
import Fio from "./Fio";
import AbonAgreement from './AbonAgreement';
import AbonLogin from "./AbonLogin";
import Network from "./Network";
import AbonWebActivity from "./AbonWebActivity";
import AbonIntActivity from "./AbonIntActivity";
import OnPoint from "./OnPoint";
import EndPoint from "./EndPoint";
import AbonGroup from "./AbonGroup";
import SignalPower from "./SignalPower";
import SignalPowerHistory from "./SignalPowerHistory";
import LastOnuInfoHolder from "./LastOnuInfoHolder";

export default function AbonentPanel(props) {
  console.log(props)
  return (
    <div className="tab-pane fade " id="home" role="tabpanel" aria-labelledby="home-tab">

      <Fio
        taskFio={props.taskFio} />

      <AbonInfo
        taskAddData={props.taskAddData}
      />

      <AbonIp
        taskIp={props.taskIp}
      />

      <AbonPhone
        taskPhone={props.taskPhone}
      />

      <AbonAgreement
        taskAgreement={props.taskAgreement}
      />

      <AbonLogin
        login={props.login}
      />

      <Network
        network={props.network}
      />


      <div className="collapse" id="collapseExample">

        <AbonWebActivity
          taskNetwork={props.taskNetwork}
        />

        <AbonIntActivity
          taskInternet={props.taskInternet}
        />

        <OnPoint
          taskOnPoint={props.taskOnPoint}
        />

        <EndPoint
          taskEndPoint={props.taskEndPoint}
        />

        <AbonGroup
          taskAbonGroup={props.taskAbonGroup}
        />

        <SignalPower
          taskSignalPower={props.taskSignalPower}
          clickUpdate={props.clickUpdate}
          notSignalPower={props.notSignalPower}
          customerID={props.customerID}
        />

        <SignalPowerHistory
          customerID={props.customerID}
          taskSignalPowerHistory={props.taskSignalPowerHistory}
        />

        <LastOnuInfoHolder
          taskLastOnuInfo={props.taskLastOnuInfo}
        />
      </div>
    </div>
  )
}