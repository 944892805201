import React, { Component } from 'react';
import { ServerApi } from "../Server/ServerApi";
import 'react-dynamic-swiper/lib/styles.css'
import { Button, Form } from "react-bootstrap";
import { getString, strings } from "../Languages/Languages";
import { ShowDialog } from "../Utils/Dialog/Dialog";
import { CloseLoading, ShowLoading } from "../Utils/Dialog/LoadingDialog";
import ImagesSwiperPreview from './AboutPages/AboutComponents/ImagesPreview/ImagesSwiperPreview';


let commentsAlreadyDownloaded = false;
let divisionNames = new Map;

function createTask() {
    let serverApi = new ServerApi();
    let serialize = require('form-serialize');
    let form = document.querySelector('#NewTaskForm');
    let object = serialize(form, { hash: true });

    let date = object['TaskDateAndTime'];
    let opis = object['TaskOpis'];

    if (date === '' || typeof date === 'undefined' ||
        opis === '' || typeof opis === 'undefined') {
        ShowDialog({
            'title': getString(strings.Attention),
            'body': getString(strings.EmptyFieldWarning),

            'pButtonClick': function () { },

            'pbtn': 'true',
            'nebtn': 'false',

            'pbtntitle': getString(strings.Ok),
        });
        return;
    }
    let selectedDivisionName = document.querySelector("#dropdownMenuButton").innerHTML

    if (selectedDivisionName !== "") {
        // console.log(1)
        serverApi.createTaskDivision(date, opis, "1", divisionNames.get(selectedDivisionName)).then(result => {
            if (result) {
                ShowDialog({
                    'title': getString(strings.Attention),
                    'body': getString(strings.theTaskCreated),

                    'pButtonClick': function () {
                        window.history.back()
                    },

                    'pbtn': 'true',
                    'nebtn': 'false',

                    'pbtntitle': getString(strings.Ok),
                });
                return;
            }
            ShowDialog({  //Задание не создано
                'title': getString(strings.Attention),
                'body': getString(strings.theTaskNoCreated),

                'pButtonClick': function () { },

                'pbtn': 'true',
                'nebtn': 'false',

                'pbtntitle': getString(strings.Ok),
            });
        })
            .catch((error) => {
                console.log(error)
            })
        return;
    }
    //console.log(2)
    serverApi.createTask(date, opis, "1").then(result => {
        if (result) {
            ShowDialog({
                'title': getString(strings.Attention),
                'body': getString(strings.theTaskCreated),

                'pButtonClick': function () { },

                'pbtn': 'true',
                'nebtn': 'false',

                'pbtntitle': getString(strings.Ok),
            });
            return;
        }
        ShowDialog({
            'title': getString(strings.Attention),
            'body': getString(strings.theTaskNoCreated),

            'pButtonClick': function () { },

            'pbtn': 'true',
            'nebtn': 'false',

            'pbtntitle': getString(strings.Ok),
        });
    })
        .catch((error) => {
            console.log(error)
        })
}



class GetDivisionList extends Component {

    // constructor(props) {
    //     super(props);

    //     this.increment = this.increment.bind(this)
    //     this.decrement = this.decrement.bind(this)

    //     this.state = {
    //         slideCount: 1,
    //         options: {
    //             navigation: true,
    //             pagination: true,
    //             paginationClickable: true,
    //             scrollBar: false,
    //             loop: false
    //         },
    //         count: 0,
    //         list: 0,
    //         setList: 0,
    //     };
    // }

    componentDidMount() {
        let serverApi = new ServerApi();
        serverApi.getDivisionList().then((response) => {
            let divisionList = response.data
            let result = document.querySelector('.dropdown-menu');
            for (let aaaKey in divisionList) {
                divisionNames.set(divisionList[aaaKey].name, aaaKey)
                result.innerHTML += `<a  class="dropdown-item" data-value="dropdown-item">${divisionList[aaaKey].name}</a>`
            }

            document.addEventListener("click", function (e) {
                if (e.target.dataset.value === "dropdown-item") {
                    document.querySelector("#dropdownMenuButton").innerHTML = e.target.textContent;

                }
            });
        })
            .catch((error) => {
                console.log(error)
            })
    }


    render() {
        return (
            <div>
                <div className={"NewTaskForm"}>
                </div>
                <div className={"NewTaskForm1"}>
                    <div>
                        <Form id={"NewTaskForm"}>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label className={"TaskDateAndTimeLables"}>{getString(strings.TaskDateAndTime)}</Form.Label>
                                <Form.Control name={"TaskDateAndTime"} className={"form-controlDate"} type="Datetime-local" />
                            </Form.Group>
                            <br />
                            <Form.Group controlId="formBasicPassword">
                                <Form.Control name={"TaskOpis"} className={"form-control"} type="text"
                                    placeholder={getString(strings.TaskEnterDescription)} />
                            </Form.Group>
                            <br />
                            <Form.Label className={"SelectDivisionLables"}>{getString(strings.SelectDivision)}</Form.Label>
                            <br />
                            <div className="dropdown">
                                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                </div>
                            </div>
                            <p />
                            <Form.Label className={"TaskDateAndTimeLables"}>{getString(strings.Photo)}</Form.Label>

                            <ImagesSwiperPreview
                                hidden={false}
                            />



                            <Button onClick={() => { createTask(); }} className={"TaskCreateButton"}>{getString(strings.TaskCreate)}</Button>
                            <Button onClick={() => { window.history.back(); commentsAlreadyDownloaded = false; }}
                                className={"CancelButton"}>{getString(strings.Cancel)}</Button>
                            <br /><br /><br /><br />
                        </Form>
                    </div>
                </div>
            </div>
        )
    }
}
export default GetDivisionList;



