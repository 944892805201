import React from "react";
// import { UserSideApi } from "../../../UserSide/UserSideApi";
import { ServerApi } from "../../../Server/ServerApi";
import { User } from "../../../User/User";

async function load_new(id, callback) {
  // let userSideApi = new UserSideApi();
  let serverApi = new ServerApi();
  //
  // let props_new = []
  let tasksAndListData = {};
  let tasks_d = JSON.parse(User.shortTasks);
  //tasksAndListData = serverApi.GetShow(User.taskId, User.employeeId, User.keyPril,User.currentDate,"")
  serverApi.GetShow(User.taskId, User.employeeId, User.keyPril, User.currentDate, "").then((tasksAndListData) => {
    let tasks_n = [];
    tasks_n = tasksAndListData["tasks"];
    if (tasksAndListData["res"] === false) console.log("error");
    //console.log(tasksAndListData["res"]);
    //
    if (tasks_n !== null && tasks_n !== undefined) {
      for (let j = 0; j < tasks_n.length; j++) {
        for (let i = 0; i < tasks_n.length; i++) {
          {
            tasks_d.push(tasks_n[i]);  // задания количество 1,2,3,4 ....
            User.shortTasks = JSON.stringify(tasks_d);
            //console.log("nodes:"+tasks_n[i]['Nodes'])
            let taskI = tasks_n[i]['Id']
            window.open("/about/" + taskI, '_self', false);
            //User.Nodes = tasks_n[i]['Nodes']
            //  console.log( tasks_da)
          }
        }
      }
      // tasks_n=new_task.task
      //console.log("test0:"+tasks_n)


      //console.log("test:"+tasks_n);
      //tasks_d.push(tasks_n[0])


    }
  })
      .catch((error) => {
        console.log(error)
      });
  //
  callback();
}

export default load_new;