import React from "react";

export default function setField(id, holderId, value, isHtml = false) {
  if ((value + '').replace('', '') === '' || value === undefined || value === null) {
    document.getElementById(holderId).hidden = true;

    return;
  }

  if (isHtml) {
    document.getElementById(id).innerHTML = value;
    return;
  }

  document.getElementById(id).innerText = value;
}