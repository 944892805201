import React from "react";
import backArrow from "../Resources/Icons/back-arrow.svg";
import {faArchive, faArrowAltCircleRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function Supports()
{
    return (
        <div>
            <p/>
            <div id={'MarketingMapId'} onClick={async () => {window.open("supports/information", '_self', false);}}>
                <div type="button" id="MarketingId" className="SupportDiv">
                    probnaya
                    <div className="float-right text-right ColorSupportsIcon">
                            <FontAwesomeIcon className={'bbb'} icon={faArrowAltCircleRight}/>
                    </div>
                </div>
            </div>
            <nav className="navbar fixed-bottom navbar-light bg-white shadow-lg">
                <button
                        onClick={async () => {window.open("/supports/add", '_self', false);}}
                        type="button" className="btn btn-primary btn-circle">+</button>
                <div className="float-left" >
                    <a id={"backBtn"} onClick={() =>  window.open("/main", '_self', false)}
                       className="navbar-brand text-primary mx-2"><img src={backArrow} alt={backArrow} width={"25px"}/></a>
                </div>
                <div className="float-right text-right"
                     onClick={async () => {window.open("/supports/:archive", '_self', false);}}>
                    <a type="button"
                       className="navbar-brand text-primary mr-2" href="#">
                        <FontAwesomeIcon icon={faArchive}/>
                    </a>
                </div>
            </nav>
        </div>
    );
}
export default Supports;