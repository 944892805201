/* eslint-disable eqeqeq */
import React from "react";
import { ShowLoading } from "../../../Utils/Dialog/LoadingDialog";
import { ServerApi } from "../../../Server/ServerApi";
import { User } from "../../../User/User";
import { decodeHtmlCharCodes } from "../../../Utils/String/StringUtils";
import { CloseLoading } from "../../../Utils/Dialog/LoadingDialog";

export default function AbonDataNetwork(props) {
  let serverApi = new ServerApi();
  let namId = User.namId
  const { taskData } = props;
  console.log(props)

  ShowLoading();
  serverApi.getTaskData(namId, props['AddressId'], props["Id"], props['RelatedObjects']).then((response) => {

    // SET ABON TAB DATA
    if (response.abonData === undefined) {
      document.getElementById('abonTabButton').hidden = true;
      document.getElementById('home').hidden = true;
    }
    else {
      for (let i = 0; i < response.abonData.length; i++) {
        document.getElementById('addInfo').innerHTML = decodeHtmlCharCodes(response.abonData[i].Comment)
        /*  document.getElementById('ip').innerHTML = decodeHtmlCharCodes(response.abonData[i].Ip)*/

        if (response.abonData[i].Ip !== undefined && response.abonData[i].Ip.length >= 1) {
          document.getElementById('ip').innerHTML = decodeHtmlCharCodes(response.abonData[i].Ip)
          document.getElementById('ipHolder').hidden = false
        } else {
          document.getElementById('ipHolder').hidden = true
          // document.getElementById('ip').
        }

        document.getElementById('agreement').innerHTML = decodeHtmlCharCodes(response.abonData[i].Agreement)
        document.getElementById('login').innerHTML = decodeHtmlCharCodes(response.abonData[i].Login)

        let phonesRemove = document.getElementById('phonesHolder')
        while (phonesRemove.firstChild) {
          phonesRemove.removeChild(phonesRemove.lastChild)
        }

        let phone = response.abonData[i].Phones
        if (phone.length <= 0) {
          document.getElementById('phonesHolderDiv').hidden = true;
        }
        else {
          for (let phoneKey in phone) {
            let phoneText = phone[phoneKey];
            document.getElementById('phonesHolder').innerHTML += `<a href="tel:${phoneText}" id="Phones"
                                                             class="list-group-item list-group-item-action">${phoneText}</a>`
          }
        }
      }
    }

    // SET NETWORK TAB DATA
    if (taskData.networkData === undefined) {
      /// document.getElementById('networkTabButton').hidden = true;
      //  document.getElementById('contact').hidden = true;

      document.getElementById('AbonNetwork').hidden = true;
    }
    else {
      for (let i = 0; i < response.abonData.length; i++) {

        if (response.abonData[i] !== undefined || response.abonData[i] !== '') {
          document.getElementById('networkActivity').innerHTML = decodeHtmlCharCodes(response.abonData[i].NetworkActivity)
          document.getElementById('internetActivity').innerHTML = decodeHtmlCharCodes(response.abonData[i].InternetActivity)
        }
      }

      for (let phoneKey in response.networkData) {
        let phoneText = response.networkData[phoneKey];

        if (phoneText.length == 0 || phoneText.length == '') {
          document.querySelector('#onPointHolder').hidden = true
          document.querySelector('#endPointHolder').hidden = true
          document.querySelector('#abonGroupHodler').hidden = true
        }
        else {
          document.querySelector('#onPointHolder').hidden = false
          document.getElementById('onPoint').innerHTML = decodeHtmlCharCodes(phoneText.OnPoint)
          document.querySelector('#endPointHolder').hidden = false
          document.getElementById('endPoint').innerHTML = decodeHtmlCharCodes(phoneText.EndPoint)
          document.querySelector('#abonGroupHodler').hidden = false
          // document.getElementById('abonGroup').innerHTML = decodeHtmlCharCodes(phoneText.GroupName)

          if (phoneText.GroupName != undefined && phoneText.GroupName.length >= 1) {
            document.getElementById('abonGroup').innerHTML = decodeHtmlCharCodes(phoneText.GroupName)
          } else {
            document.querySelector('#abonGroupHodler').hidden = true
          }
          console.log("phone:" + phoneText.GroupName);
        }
      }
    }
    CloseLoading();
  })
      .catch((error) => {
        console.log(error)
      });
}