import React from "react";
import taskTypeIcon from '../../../../Resources/Icons/AboutTask/type.png';

export default function TaskType(props) {

  return (
    <div className="col-sm-12 px-1">
      <label className="float-left text-primary mb-0">{props.taskType}</label>
      <div className="input-group mb-3">
        <div className="input-group-prepend">
          <span className="input-group-text bg-primary"><img src={taskTypeIcon} alt={taskTypeIcon} width="20px" /></span>
        </div>
        <input type="text" className="bg-light  form-control smallText" defaultValue={props.type} disabled />
      </div>
    </div>
  )
}