import React, { useState, useEffect } from "react";
import backArrow from "../Resources/Icons/back-arrow.svg";
import ColorPicker from "../Utils/ColorPicker/ColorPicker";
import Modal from 'react-bootstrap/Modal';
import { reactLocalStorage } from "reactjs-localstorage";


export default function ShowSettings(props) {
    const [show, setShow] = useState(false)
    const [idState, setIdState] = useState('')
    const [color, setColorState] = useState([])
    const handleShow = (event) => {
        setShow(true);
        setIdState(event.target.id);
    }
    const handleClose = () => setShow(false);

    let dateTo = new Date();
    dateTo.setDate(dateTo.getDate() - 1);

    function Exit() {
        window.open("/main", '_self', false);
    }

    const getColor = (item) => {
        return reactLocalStorage.get('color_' + item, '')
    }
    const setColor = (item) => {
        reactLocalStorage.set(item, 'rgb(0, 123, 255)')
    }

    useEffect(() => {
        let keys = Object.keys(localStorage)
        for (let key of keys) {
            if (key.includes('setColor_')) {
                let type = key.replace('setColor_', '')
                setColorState((prev => [...prev, type]))
            }
        }
    }, [])

    const resetColor = () => {
        let keys = Object.keys(localStorage)
        for (let key of keys) {
            if (key.includes('color_')) {
                setColor(key)
            }
        }
        let blockArr = [...document.querySelectorAll('.color-block')];
        blockArr.forEach((item) => {
            item.style.background = 'rgb(0, 123, 255)';
        })
    }
    return (
        <>
            <div className={"container-color"}>
                <div className={"color-settings"}>
                    <p className={"color-settings-header"}>Настройка цвета для типа заданий</p>
                    {color.map((item) => {
                        let itemColor
                        if (getColor(item)) {
                            itemColor = getColor(item)
                        } else {
                            itemColor = 'rgb(0, 123, 255)'
                        }
                        return (
                            <div className={"color-for-task"} key={item}>
                                <div className={"task-type-container"}>
                                    <div className={"task-type-color"}>{item}</div>
                                </div>
                                <div className={"choose-clr-container"}>
                                    <div className={"color-block"} style={{ background: itemColor }}></div>
                                    <button className={"btn btn-primary change-color-btn"} id={item}
                                        onClick={handleShow}>Изменить
                                    </button>
                                </div>

                            </div>
                        )
                    })}
                    <button className={'btn btn-primary reset-color'} onClick={resetColor}>Сбросить все цвета</button>
                </div>
            </div>


            <nav className="navbar fixed-bottom navbar-light bg-white shadow-lg">
                <div className="float-left" onClick={() => Exit()}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a id={"backBtn"}
                        className="navbar-brand text-primary mx-2"><img src={backArrow} alt={backArrow} width={"25px"} /></a>
                </div>
            </nav>
            <Modal show={show} onHide={handleClose} style={{ marginTop: '15vh' }}>
                <div style={{ display: 'flex', textAlign: 'center', flexDirection: 'column' }}>
                    <ColorPicker
                        handleShow={handleShow}
                        handleClose={handleClose}
                        id={idState}
                    />
                </div>

            </Modal>
        </>
    )
}
