/* eslint-disable eqeqeq */
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getString } from "../Languages/Languages";
import { strings } from "../Languages/Languages";
import { decodeHtmlCharCodes } from "../Utils/String/StringUtils";
import { UserSideApi } from "../UserSide/UserSideApi";
import { User } from "../User/User";
import { ShowLoading } from "../Utils/Dialog/LoadingDialog";
import { ShortTask } from "../Task/Task";

import QrCodeCreate from "./AboutPages/AboutComponents/MenuContainer/Scanner/QrCodeCreate";
import MenuContainer from "./AboutPages/AboutComponents/MenuContainer/MenuContainer";
import Comments from "./AboutPages/AboutComponents/Comments/Comments";
import ShowImages from "./AboutPages/AboutComponents/ShowImages/ShowImages";
import AboutFooterNav from "./AboutPages/AboutComponents/AboutFooterNav/AboutFooterNav";
import AddressPanel from "./AboutPages/AboutComponents/AddressPanel/AddressPanel";
import OtherPanel from "./AboutPages/AboutComponents/OtherPanel/OtherPanel";
import AbonentPanel from "./AboutPages/AboutComponents/AbonentPanel/AbonentPanel";
import NavList from "./AboutPages/AboutComponents/NavList/NavList";
import Description from "./AboutPages/AboutComponents/Description/Description";
import TaskType from "./AboutPages/AboutComponents/TaskType/TaskType";
import TaskDate from "./AboutPages/AboutComponents/TaskDate/TaskDate";
import TaskNumber from "./AboutPages/AboutComponents/TaskNumber/TaskNumber";
import { Config } from "../UserSide/Config";
import load_new from "./AboutPages/functions/load_new";
import LoadAboutTaskData from "./AboutPages/functions/LoadAboutTaskData";



// import {GetMainForm} from "./MainForm";
// import abonOnMapIcon from '../Resources/Icons/Cards/abon_on_map.png';
// import openRelatedTask from "./AboutPages/functions/openRelatedTask";


let commentsAlreadyDownloaded = false;



export function GetAboutTaskForm() {
    const { taskI } = useParams();
    User.taskId = taskI;
    let propsT, props;
    propsT = JSON.parse(User.shortTasks);
    let flag_load = 0;
    for (let i = 0; i < propsT.length; i++) {
        if (propsT[i]["Id"] == taskI) {
            props = propsT[i];
            flag_load = 1;
        }
    }
    let userSideApi = new UserSideApi();
    if (flag_load === 0) {
        ShowLoading();
        load_new(taskI)
    } else {
        User.lastAboutTask = JSON.stringify(props);
    }
    propsT = JSON.parse(User.shortTasks);
    for (let i = 0; i < propsT.length; i++) {
        if (propsT[i]["Id"] == taskI) {
            props = propsT[i];
            flag_load = 1;
        }
    }
    try {
        User.nodes = props['Nodes'];
        ShortTask.activeTask = props['Id'];
    }
    catch (e) {
        console.log(e)
    }


    useEffect(() => {
        let array = 'customer';
        localStorage.setItem(array, JSON.stringify(props['Customer']));
        LoadAboutTaskData(props).then()
        console.log(User)
    }, [props])

    localStorage.removeItem('amount')
    return (
        <div>
            <form className={"smallText"}>
                <fieldset>
                    <div className="shadow-sm mx-1 mb-8-5 bg-light rounded row clearfix">
                        {/* Номер задания */}
                        <TaskNumber
                            taskNumber={getString(strings.TaskNumber)}
                            id={props["Id"]}
                        />
                        {/* Назначенная дата */}
                        <TaskDate
                            taskDate={getString(strings.TaskDate)}
                            date={props["Date"]}
                        />
                        {/* Тип задания */}
                        <TaskType
                            taskType={getString(strings.TaskType)}
                            type={props["Type"]}
                        />
                        {/* Поле "Описание" */}
                        <Description
                            taskOpis={getString(strings.TaskOpis)}
                            description={props["Description"]}
                        />
                        <div className="container px-1">
                            {/* Кнопки навигации: Адрес, Другое(где кнопки Абонента???) */}
                            <NavList
                                address={getString(strings.Address)}
                                other={getString(strings.Other)}
                            />
                            <div className="tab-content border-bottom border-right border-left pt-2" id="myTabContent">

                                {/* Вкладка Адрес */}
                                <AddressPanel
                                    taskAddress={getString(strings.TaskAddress)}
                                    decodeHtmlCharCodes={decodeHtmlCharCodes(props["Address"])}
                                    taskNotes={getString(strings.TaskNotes)}
                                    taskExits={getString(strings.TaskExits)}
                                    taskKeys={getString(strings.TaskKeys)}
                                    taskWorkNotes={getString(strings.TaskWorkNotes)} />
                                {/* Вкладка Другое */}
                                <OtherPanel
                                    taskShowWatchers={getString(strings.TaskShowWatchers)}
                                    taskRelated={getString(strings.TaskRelated)}
                                    taskObjects={getString(strings.TaskObjects)}
                                    addObservers={getString(strings.AddObservers)}
                                />
                                {/* Панель Абонента */}
                                <AbonentPanel
                                    taskFio={getString(strings.TaskFio)}
                                    taskAddData={getString(strings.TaskAddData)}
                                    taskIp={getString(strings.TaskIp)}
                                    taskPhone={getString(strings.TaskPhone)}
                                    taskAgreement={getString(strings.TaskAgreement)}
                                    login={getString(strings.Login)}
                                    network={getString(strings.Network)}
                                    taskNetwork={getString(strings.TaskNetwork)}
                                    taskInternet={getString(strings.TaskInternet)}
                                    taskOnPoint={getString(strings.TaskOnPoint)}
                                    taskEndPoint={getString(strings.TaskEndPoint)}
                                    taskAbonGroup={getString(strings.TaskAbonGroup)}
                                    taskSignalPower={getString(strings.TaskSignalPower)}
                                    clickUpdate={getString(strings.ClickUpdate)}
                                    notSignalPower={getString(strings.NotSignalPower)}
                                    customerID={props['Customer']}
                                    taskSignalPowerHistory={getString(strings.TaskSignalPowerHistory)}
                                    taskLastOnuInfo={getString(strings.TaskLastOnuInfo)}
                                />
                            </div>
                        </div>
                        {/* Комментарии */}
                        <Comments
                            comments={props['Comments']}
                            commentsAlreadyDownloaded={commentsAlreadyDownloaded}
                            userSideApi={userSideApi}
                            attachedPhoto={getString(strings.AttachedPhoto)}
                            taskComments={getString(strings.TaskComments)}
                            showImages={getString(strings.ShowImages)}
                        />

                        {/* Показать изображения */}
                        <ShowImages
                            allProps={props}
                            photos={props['Photos']}
                            showImages={getString(strings.ShowImages)} />

                        {/*Генерация Qr-code  */}
                        <QrCodeCreate
                            getString={getString(strings.GenQrCode)}
                            taskId={taskI}
                            customer={props.Customer}
                        />

                        {/* Меню */}
                        <MenuContainer
                            addressId={props['AddressId']}
                            onMap={getString(strings.AbonOnMap)}
                            sendCoord={getString(strings.SendCoord)}
                            scaning={getString(strings.Scaning)}
                            qrBarCode={getString(strings.QrBarCode)}
                            infraObject={getString(strings.InfraObject)}
                            id={props["Id"]}
                            commutation={getString(strings.Commutation)}
                            knotCoupling={getString(strings.KnotCoupling)}
                            sendComment={getString(strings.SendComment)}
                            createChildTask={getString(strings.CreateChildTask)}
                        />
                    </div>
                </fieldset>
            </form>
            {/* Footer-навигация */}
            <AboutFooterNav
                taskTypeDescription={{
                    taskTypeDescription: getString(strings.TaskTypeDescription), propsTypeDescription: props['TypeDescription']
                }} />

        </div>
    );
}













