import React from "react";

export default function ListFieldType(props) {
  let text = ''
  if (props.required) {
    text = 'Обязательно к заполнению'
  }
  return (
    <>
      <label className="mr-sm-2">{props.name}</label>
      <button
        className="btn btn-secondarys dropdown-toggle" type="button"
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id={props.id} >
      </button>
      <div id={"dropdown-menuId2"} className="dropdown-menu taski" aria-labelledby="dropdownMenuButton">
        {props.valueArr.map((opt) => {
          return (
            <button onClick={props.onHandleChange} className="dropdown-itemCloseTask" data-value="dropdown-item2" key={opt} name={props.id}>{opt}</button>
          )
        })}
      </div>
      <p id='forRequiredField' style={{ fontSize: '0.8em' }}>{text}</p>
    </>


    // <div className="dropdown">
    //   <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
    //     data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    //   </button>
    //   <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
    //     {props.valueArr.map((opt) => {
    //       return (
    //         <a class="dropdown-item" data-value="dropdown-item">{opt}</a>
    //         // <option key={opt}>{opt}</option>
    //       )
    //     })}
    //   </div>
    // </div>

    // <select className="btn-secondarys custom-select my-1 mr-sm-2" id="inlineFormCustomSelectPref" onChange={props.onHandleChange} name={props.id}>
    //   <option value='' hidden></option>
    //   {props.valueArr.map((opt) => {
    //     return (
    //       <option key={opt}>{opt}</option>
    //     )
    //   })}
    // </select>

  )
}