// import {UserSideApi} from "../../UserSide/UserSideApi";
import {ServerApi} from "../../Server/ServerApi";
import {User} from "../../User/User";
import {render} from "react-dom";
import {GetShortTaskTemplate} from "../../Templates/Tasks/ShortTaskTemplate";
import {ShowSimpleDialog} from "../../Utils/Dialog/Dialog";
import {getString, strings} from "../../Languages/Languages";
import {formateDate} from "../../Utils/String/StringUtils";
import {CloseLoading, ShowLoading} from "../../Utils/Dialog/LoadingDialog";

export default async function getFiltredTasks(dateDoFrom, dateDoTo, order_by) {
    ShowLoading();
    let serverApi = new ServerApi();
    let tasks = [];
    let isOnline = navigator.onLine;
    if (isOnline) {
        let taskListData = {};
        let tasksAndListData = {};

        tasksAndListData = await serverApi.getFilterTaskList(User.divisionId, User.employeeId, User.keyPril, dateDoTo, dateDoFrom, order_by);




        if (tasksAndListData["res"] === false) return;
        taskListData = tasksAndListData["list"];
        tasks = tasksAndListData["tasks"];

        if (tasks !== null && tasks !== undefined)
        {
            let tasks_da = [];
            for (let j = 0; j < tasks.length; j++) {
                for (let i = 0; i < tasks.length; i++) {
                    if ((tasks[i]['Id'] + '') === (taskListData['list'].split(',')[j] + ''))


                    {
                        tasks_da.push(tasks[i]);
                    }
                }
            }
            tasks = tasks_da;


        }

        // if (formateDate(dateDoFrom) === formateDate(new Date()) && formateDate(dateDoTo) === formateDate(new Date()))
        // {
        User.todayTasksList = taskListData['List'];   //Список задач на сегодня
        User.lastSavedDate = formateDate(new Date());   //дата последнего сохранения
        User.shortTasks = JSON.stringify(tasks);        //короткая задача


        // }
    } else {
        // if (formateDate(dateDoFrom) === formateDate(new Date()) && formateDate(dateDoTo) === formateDate(new Date()))
        // {
        try {
            tasks = JSON.parse(User.shortTasks);

        } catch (e) {
            console.error('No tasks - ' + e);
        }
        // }
    }

    let tasksTags = [];


    for (let task in tasks) {
        tasksTags.push(GetShortTaskTemplate(tasks[task])); //   GetShortTaskTemplate- где сам дизайн заданий
        //  console.log( tasksTags.push(GetShortTaskTemplate(tasks[task])))  //выводит 2,4,6,8,10,12,14  все задания по 2 штуки дублируются
    }
    let amount = User.amount;
    if (amount !== '') {
        let newArray = tasksTags.slice(0, amount)
        console.log(newArray)
        tasksTags = newArray
    }

    render(tasksTags, document.getElementById('TasksHolder'));


    let taskCount = tasksTags.length.toString(); // количество заданий в данном случае в просроченных 7, на основной 0

    if (taskCount === '0')  //если количество заданий равняется 0

    {
        document.getElementById('TasksHolder').innerText = ''; //пустота

        ShowSimpleDialog(getString(strings.Attention), getString(strings.NoTasksToDay));   //выводится окно внимвние На сегодня нет заданий


    }
    try {
        document.getElementById('taskCountText').innerText = `${getString(strings.Tasks)}${taskCount}`; //Заданий: 7 Заданий: 0
    } catch (e) {
    }

    CloseLoading();
    return tasks
}



