import React from "react";
import {Modal} from "react-bootstrap";
import {Button} from "react-bootstrap";
import {useState} from "react";
import {render} from "react-dom";
import {getString, strings} from "../../Languages/Languages";

function KnotDot(props)
{
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className={'DotText'}>
                    {props.title}
                </div>
                <p/>
                <div id={'StartDotId'} className={'Dot'}>
                    <div className={'balloonKnot'}>
                        {props.body}
                    </div>
                    <div className={'balloonKnot'}>
                        {props.bodys}
                    </div>
                    <div className={'balloonKnot'}>
                        {props.bod}
                    </div>
                    <div className={'balloonKnot'}>
                        {props.bo}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className={"ObserversClose"} onClick={() => {props.onHide(); props.onApplyclick()}}>{getString(strings.Navigator)}</Button>
                <Button className={"btn btn-dangerCancelTask"} onClick={ () => props.onHide() }>{getString(strings.Cancel)}</Button>
            </Modal.Footer>
        </Modal>
    );
}

function GetDialog(props) {
    const [modalShow, setModalShow] = useState(true);

    return(
        <>
            <KnotDot
                title={props.title}
                body={props.body}
                bodys={props.bodys}
                bod={props.bod}
                bo={props.bo}
                onApplyclick={props.onApplyclick}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>);
}

function ShowKnotDot(onApplyClick, body, bodys, bod, bo, title)
{
    render (<GetDialog onApplyclick={onApplyClick}
                       body={body}
                       bodys={bodys}
                       bod={bod}
                       bo={bo}
                       title={title}
    />, document.createDocumentFragment());
}
export {ShowKnotDot};
