import logo from '../Resources/Logo/Logo_white_512x512.png'
import {Form} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWrench} from "@fortawesome/free-solid-svg-icons/faWrench";
import {ShowDialog} from "../Utils/Dialog/Dialog";
import {User} from "../User/User";
import {getString} from "../Languages/Languages";
import {strings} from "../Languages/Languages";
import {CloseLoading, ShowLoading} from "../Utils/Dialog/LoadingDialog";
import {Component} from "react";

function login()
{
    let serialize = require('form-serialize'); // серелизовать требовать
    let form = document.querySelector('#loginForm');
    let object = serialize(form, { hash: true });

    let  Authorization = 'true';
    localStorage.setItem("Authorization",Authorization)

    let login = object['loginIF'];
    User.login = login;
    let password = object['passwordIF'];
    let deviceId = User.uniqueId;
    let keyPrill = User.keyPril;

    if (keyPrill === '' || keyPrill === 'undefined') {
        ShowDialog({
            'title': getString(strings.Attention),
            'body': getString(strings.KeyPrillMissing),

            'pButtonClick': function () {},
            'nButtonClick': function () {
                showKeyPrillDialog();
            },

            'pbtn': 'true',
            'nbtn': 'true',
            'nebtn': 'false',

            'pbtntitle': getString(strings.Ok),
            'nbtntitle': getString(strings.EnterKeyPrill),
        });
        return
    }

    if (login === '' || typeof login === 'undefined' ||
        password === '' || typeof password === 'undefined' ||
        deviceId === '' || deviceId === 'undefined') {
        ShowDialog({
            'title': getString(strings.Attention),
            'body': getString(strings.LoginAndPassword),
            'pButtonClick': function () {},
            'nButtonClick': function () {
                showKeyPrillDialog();
            },

            'pbtn': 'true',
            'nbtn': 'true',
            'nebtn': 'false',

            'pbtntitle': getString(strings.Ok),
            'nbtntitle': getString(strings.EnterKeyPrill),
        });
        return;
    }

    ShowLoading();
    User.authorize(login, password, deviceId, keyPrill).then(result => {
        CloseLoading();
        console.log(result);
        if (result["res"] == false) {
            ShowDialog({
                'title': getString(strings.Attention),
                'body': result["mes"],

                'pButtonClick': function () {},
                'nButtonClick': function () {
                    showKeyPrillDialog();
                },
                'pbtn': 'true',
                'nbtn': 'false',
                'nebtn': 'false',

                'pbtntitle': getString(strings.Ok),
            });
        } else {
            User.isLogin = true;
            let Authorization = 'true';
                localStorage.setItem("Authorization",Authorization);
            if (Authorization === 'true'){
                window.open("/main", '_self', false);
            }
        }
    });
}

function showKeyPrillDialog()
{
    ShowDialog({
        'title': getString(strings.Attention),
        'body': getString(strings.EnterKeyPrillPlease),

        'pButtonClick': function ()
        {
            let serialize = require('form-serialize');
            let form = document.querySelector('#dialogForm');
            let object = serialize(form, { hash: true });

            User.keyPril = object['dialogIF'];
        },
        'nButtonClick': function () {},

        'pbtn': 'true',
        'nbtn': 'true',
        'nebtn': 'false',

        'ifvisible': 'true',
        'defifval': User.keyPril,

        'pbtntitle': getString(strings.Save),
        'nbtntitle': getString(strings.Cancel),
    });
}

class GetLoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            count: 0,
        };
    }

    componentDidMount() {
        let Authorization = localStorage.getItem('Authorization')
        if (Authorization === 'true'){
            window.open("/main", '_self', false);
        }
    }

    render() {
        return (
            <div>
                <div className={"LoginLogoBackGround"}>
                    <img className={"LoginLogo"} src={logo} alt="logo"/>
                </div>
                <div className={"LoginForm"}>
                    <div>
                        <Form id={"loginForm"}>
                            <div id={"LoginLogo"} className={"LoginLabel"}>{getString(strings.LoginLogo)}</div>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label id={"LoginIF"} className={"LoginIFLables"}>{getString(strings.LoginIF)}</Form.Label>
                                <Form.Control id={"Login"} name={"loginIF"} className={"form-controlDate"} type="email"
                                              placeholder={getString(strings.Login)}/>
                            </Form.Group>
                            <br/>
                            <Form.Group controlId="formBasicPassword">
                                <Form.Label id={"PasswordIF"} className={"LoginIFLables"}>{getString(strings.PasswordIF)}</Form.Label>
                                <Form.Control id={"Passwords"} name={"passwordIF"} className={"form-controlDate"} type="password"
                                              placeholder={getString(strings.Password)}/>
                            </Form.Group>
                            <br/>
                            <Button id={"Enter"} onClick={() => {
                                login();
                            }} className={"LoginButton"}>{getString(strings.Enter)}</Button>
                            <br/>
                            <br/>
                            <a href={"#"} onClick={() => {
                                showKeyPrillDialog();
                            }} className={"LoginSettingsBtnHolder"}>
                                <p>
                                    <FontAwesomeIcon icon={faWrench} className={"LoginSettingsBtn"}/>
                                </p>
                            </a>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }
}
export default GetLoginForm;
