import React, {Component} from 'react';
import Scanner from './Scanner';
import Result from './Result';
import Quagga from 'quagga';
import {ServerApi} from "../../../../../../../../Server/ServerApi";
import {Button, Form} from "react-bootstrap";
import {getString, strings} from "../../../../../../../../Languages/Languages";
import ImagesSwiperPreview from "../../../../../ImagesPreview/ImagesSwiperPreview";
import {forEach} from "react-bootstrap/ElementChildren";

class QuaggaScanner extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hidden: false,
            comment: '',
            scanning: true,
            results: [

            ]
        }
    }


    _renderScanButtonAndResults() {
        if (this.state.scanning) {
            return null;
        }
        return (
            <div style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                {this._renderResults()}
                {/* {this._renderScanButton()} */}
            </div>
        )
    }


    _renderResults() {
        const result = this.state.results[this.state.results.length - 1]
        if (!result) {
            return null;
        }
        const onHandleChange = (event) => {
            console.log(event.target.value)
            this.setState({
                comment: event.target.value
            })
        }

        const handleClick = () => {

            let serverApi = new ServerApi()
            serverApi.addComment('Scan: ' + result.codeResult.code + `\n` + this.state.comment).then((response) => {
                this.setState({
                hidden: true
            })
                console.log(response);
              })
            let s = [...document.querySelectorAll('.show')]
            s.forEach((item) => {
                item.hidden = true
            })
        }

        const handleClose = () => {
            let s = [...document.querySelectorAll('.show')]
            s.forEach((item) => {
                item.hidden = true
            })
        }

        return (
            <Form id={"NewTaskForm"} style={{marginTop: '2vh', marginBottom: '2vh'}} hidden={this.state.hidden}>
                <div style={{}}>
                    <h1
                        style={{
                            opacity: '0.5',
                            margin: '0px',
                            textAlign: 'center',
                            fontSize: '32px',
                            borderBottom: '2px solid #aaa',
                            paddingBottom: '8px'
                        }}
                    >SCAN
                    </h1>
                    <Result result={result}/>
                </div>

                <textarea onChange={onHandleChange} style={{marginBottom: '10px'}} className="form-control"
                          id="exampleFormControlTextarea1" rows="2" placeholder="Введите описание, если необходимо"
                          value={this.state.comment}></textarea>
                <Button className={"btn btn-danger"} onClick={handleClose} style={{marginRight: '15px'}}>{'Отмена'}</Button>
                <Button className={"btn btn-primary"} onClick={handleClick}>Отправить комментарий</Button>
            </Form>

        )
        // {/* <ul className="results">
        //   {this.state.results.map((result) => (<Result key={result.codeResult.code} result={result} />))}
        // </ul> */}
    }

    _renderVideoStream() {
        const text = this.state.scanning ? 'STOP' : 'SCAN';
        const styles = {
            width: '50%',
            height: '50px',
            marginBottom: '20px',
            border: 'solid rgb(206, 212, 218) 2px',
            borderRadius: '10px',
            backgroundColor: 'rgb(13, 110, 253)',
            fontSize: '30px',
            color: 'white',
            // position: 'fixed',
            bottom: '20px'
        }
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                <Scanner onDetected={this._onDetected.bind(this)}/>
                <button
                    onClick={this._scan}
                    style={styles}
                >{text}
                </button>
            </div>

        )
    }

    render() {
        return this._renderScanButtonAndResults() || this._renderVideoStream()
    }

    _scan = () => {
        this.setState({scanning: false});
        Quagga.stop()
        this.props.handleClose();
    }


    _onDetected(result) {
        this.setState({
            results: this.state.results.concat([result]),
            scanning: false
        });

        Quagga.stop();
    }
}

export default QuaggaScanner;

