import React, { useState, useRef } from 'react';
import Scanner from './Scanner';
// import Result from './Result';
import Button from 'react-bootstrap/Button';
import Quagga from '@ericblade/quagga2';

const Quagga2Scanner = (props) => {
  const [scanning, setScanning] = useState(false);
  const [results, setResults] = useState([]);
  const scannerRef = useRef(null);



  const scan = () => {
    setScanning(!scanning)

  }
  const stopScan = () => {
    setScanning(!scanning)
    Quagga.stop()
    props.handleClose();

  }

  return (

    <div>
      {/* <button onClick={() => setScanning(!scanning)}>{scanning ? 'Stop' : 'Start'}</button> */}

      <div ref={scannerRef} style={{ position: 'relative', width: '100%' }}>
        <video style={{ width: '100%', height: '100%' }} />
        <canvas className="drawingBuffer" style={{
          position: 'absolute',
          top: '0px',
          left: '0px',
          height: '100%',
          width: '100%',

        }} width="640" height="480" />
        {scanning ? <Scanner scannerRef={scannerRef} onDetected={(result) => setResults([...results, result])} /> : null}
      </div>
      {/* <ul className="results">
        {results.map((result) => (result.codeResult && <Result key={result.codeResult.code} result={result} />))}
      </ul> */}
      <p style={{ textAlign: 'center', fontSize: '24px' }}>{results[results.length - 1]}</p>
      <div
        style={{
          display: 'flex'
        }}>
        <Button variant="secondary" onClick={scan}>{'Start'}</Button>
        <Button variant="secondary" onClick={stopScan}>{'Stop'}</Button>
      </div>

    </div>
  );
};

export default Quagga2Scanner;
