import React from 'react';
import backArrow from "../Resources/Icons/back-arrow.svg";
import {CloseLoading, ShowLoading} from "../Utils/Dialog/LoadingDialog";
import {ServerApi} from "../Server/ServerApi";
import {User} from "../User/User";

let commentsAlreadyDownloaded = false;

function MarketingMapInformation()
{
    let serverApi = new ServerApi();
    ShowLoading();
    let markId = Number(localStorage.getItem('markId'));
    serverApi.nodegetmark(markId).then((response) => {
        let array = []
        for (let aaaCoordinates in response.data){
            array.push([response.data[aaaCoordinates].id, response.data[aaaCoordinates].coordinates])
            let comment = response.data[aaaCoordinates].comment

            let com = document.querySelector('#MarketingMapId')
            com.innerHTML += `<div data-comment=${response.data[aaaCoordinates].id} type="button" id="MarketingId" class="MarketingInfo">${comment}</div>`
        }

        let handleClick = (event) => {
            for (let aaaCoordinates in array) {
                if (event.target.dataset.comment == array[aaaCoordinates][0]) {
                    let corMarket = String([array[aaaCoordinates][1].lat,array[aaaCoordinates][1].lon])
                    User.corMarket = corMarket
                    window.open("/advertising",'_self',false)
                }
            }
        }

        let comClick = document.querySelectorAll('.MarketingInfo')
        comClick.forEach(comClick => {
            comClick.addEventListener('click', handleClick)
        })
        CloseLoading();
    })

    return (
        <div>
            <div id={'MarketingMapId'}>
            </div>
            <br/><br/><br/>
            <nav className="navbar fixed-bottom navbar-light bg-white shadow-lg">
                <div className="float-left" >
                    <a id={"backBtn"} onClick={() => {window.history.back();commentsAlreadyDownloaded = false}}
                       className="navbar-brand text-primary mx-2"><img src={backArrow} alt={backArrow} width={"25px"}/></a>
                </div>
                <div className="float-right text-right">
                    <a type="button"
                       className="navbar-brand text-primary mr-2" href="#">
                    </a>
                </div>
            </nav>
        </div>
    );
}
export default MarketingMapInformation;


