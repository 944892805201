import React from "react";

export default function MultiplySelect(props) {
  return (
    <>
      <label className="mr-sm-2">{props.name}</label>
      <select multiple className="form-control" id="exampleFormControlSelect2" onChange={props.onHandleChange} name={props.id}>
        <option value='' hidden></option>
        {props.valueArr.map((opt) => {
          return (
            <option className="dropdown-itemCloseTask" key={opt}>{opt}</option>
          )
        })}
      </select>
    </>

  )
}