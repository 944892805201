import React, {Component} from 'react';
import {YMaps, Map, Placemark, TypeSelector, ZoomControl, FullscreenControl, GeolocationControl, RouteButton} from 'react-yandex-maps';
import {Button} from "react-bootstrap";
import {getString, strings} from "../Languages/Languages";
import {ServerApi} from "../Server/ServerApi";
import {User} from "../User/User";

let commentsAlreadyDownloaded = false;

class AddressOnMap extends Component {
    state = {
        center: [55.595653, 38.472397],
        zoom: 10,
    }
    componentDidMount() {
        let height = document.documentElement.clientHeight
        height = height - 76
        let serverApi = new ServerApi();
        let cor;
        cor = [55.595653, 38.472397]
        serverApi.getAboonCoords(User.houseId).then((response) => {
            console.log(response)
            function Navigators(){
                let lat = response.lat;
                let lon = response.lon;
                navigator.geolocation.getCurrentPosition(function (position) {
                    let latGPS = position.coords.latitude
                    let lonGPS = position.coords.longitude
                    document.location.href = "yandexmaps://maps.yandex.ru/?rtext=" + latGPS + "," + lonGPS + "~" + lat + "," + lon;
                });
            }

            if (response.lat != '' || response.lat != undefined &&
                response.lon != '' || response.lon != undefined ){
                cor = [response.lat, response.lon]
                this.setState({lat: response.lat, lon: response.lon, Navigators, cor});
            }
            else
            {
                cor = [55.595653, 38.472397]
                this.setState({lat: response.lat, lon: response.lon, Navigators, cor});
            }

        });
        this.setState({height, cor})
    }
    render() {
        return (
            <div className={"SubscriberMapForm"} >
            <YMaps query={{ apikey: '3261ce08-c60a-4114-96f8-ce820abf124a' }}>
                <div>
                    <Map
                         // state={this.state}
                         state={{ center:this.state.cor, zoom: 10,
                                  type: 'yandex#map',
                                  bounds: undefined}}
                         height={this.state.height}
                         width='100%'
                    >
                        <ZoomControl options={{ float: 'right' }} />
                        <TypeSelector options={{ float: 'right' }} />
                        <Placemark geometry={[this.state.lat, this.state.lon]}
                                   onClick={this.state.Navigators} />
                        <FullscreenControl />
                        <GeolocationControl options={{float: 'left'}}/>
                        <RouteButton options={{
                            float: 'right'
                        }} />
                    </Map>
                    <br/>
                    <div>
                        <Button onClick={() => {window.history.back();commentsAlreadyDownloaded = false;}}
                                className={"YamapBackAbon"}>{getString(strings.Back)}</Button>
                    </div>
                </div>
            </YMaps>
            </div>
        );
    }
}

export default AddressOnMap;