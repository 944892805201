import React from "react";
import {Modal} from "react-bootstrap";
import {Button} from "react-bootstrap";
import {useState} from "react";
import {render} from "react-dom";
import {getString, strings} from "../../Languages/Languages";
import {ServerApi} from "../../Server/ServerApi";
import {User} from "../../User/User";
import {CloseLoading, ShowLoading} from "./LoadingDialog";

let flag_dialog_about_onu = 0;
function Observers(props)
{
    let serverApi = new ServerApi();
    if (flag_dialog_about_onu == 0) {
        serverApi.get_allow_staff(User.taskId).then((response) => {
            let str = null;

            for (let vals in response.data['staff']) {
                if (str !== null) {
                    str = str + "," + response.data['staff'][vals]
                } else {
                    str = response.data['staff'][vals]
                }
            }
            //GETWEList
            ShowLoading();
            serverApi.GETWEList(str).then(result1 => {
                for (let vals in result1) {
                    for (let vals1 in result1[vals].data) {
                        let name = result1[vals].data[vals1].name
                        let login = result1[vals].data[vals1].login

                        document.querySelector('#Observers').innerHTML +=
                            `<div data-name=${login} id="ObserversButtonId" type="button" class="ObserversButton">${name + '(' + login + ')'}</div>`
                    }
                }

                let handleClick = (event) => {
                    let observersText = event.target.textContent
                    User.observers = observersText

                    let login = event.target.dataset.name
                    for (let i = 0; i < result1.length; i++){
                        let data = result1[i].data

                        for (let loginId in data){
                            if (data[loginId].login === login){
                                let id = data[loginId].id
                                props.onApplyclick(id)
                            }
                        }
                    }
                    if (event.target.dataset.name !== ''){
                        props.onHide()
                    }
                }
                let observers = document.querySelectorAll('#ObserversButtonId')
                observers.forEach(observers => {
                    observers.addEventListener('click', handleClick)
                })

                CloseLoading();
            });
        })
            .catch((error) => {
                console.log(error)
            });
        flag_dialog_about_onu = 1;
    }
    else
    if (flag_dialog_about_onu == 1){
        flag_dialog_about_onu = 0;
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <p/>
                <div id={'Observers'}>
                </div>
            <p/>
            <Modal.Footer>
                <Button className={"btn btn-Filter"} onClick={ () => props.onHide() }>{getString(strings.Cancel)}</Button>
            </Modal.Footer>
        </Modal>
    );
}

function GetDialog(props) {
    const [modalShow, setModalShow] = useState(true);

    return(
        <>
            <Observers
                onApplyclick={props.onApplyclick}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>);
}

function ShowObservers(onApplyClick)
{
    render (<GetDialog onApplyclick={onApplyClick}/>, document.createDocumentFragment());
}
export {ShowObservers};
