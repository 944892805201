import React from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useState } from "react";
import { render } from "react-dom";
import { getString, strings } from "../../Languages/Languages";
import { MapInteractionCSS } from 'react-map-interaction';

let imageId = 0;

function MyVerticallyCenteredModal(props) {
    console.log(props)
    imageId = 0;

    let target = props.target;

    for (let i in props.defImgs) {
        if (props.defImgs[i] === target) {
            imageId = i;
        }
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <MapInteractionCSS maxScale={20} minScale={0}>
                    <div id={"srcHolder"}>
                        <img className={"bigImage"} src={props.src[imageId]} alt={props.src[imageId]} />
                        <p style={{ textAlign: 'center', fontSize: '20px' }}>{props.comm[imageId]}</p>
                    </div>

                </MapInteractionCSS>

                <Button className={"bigImageBtn1"} onClick={() => {
                    if (imageId > 0) {
                        imageId--;
                        render(<><img className={"bigImage"} src={props.src[imageId]} alt={props.src[imageId]} />
                            <p style={{ textAlign: 'center', fontSize: '20px' }}>{props.comm[imageId]}</p></>, document.getElementById('srcHolder'));
                    }
                }}>{getString(strings.Previous)}</Button>
                <Button className={"bigImageBtn"} onClick={() => {
                    if (imageId < props.src.length - 1) {
                        imageId++;
                        render(<><img className={"bigImage"} src={props.src[imageId]} alt={props.src[imageId]} />
                            <p style={{ textAlign: 'center', fontSize: '20px' }}>{props.comm[imageId]}</p></>, document.getElementById('srcHolder'));
                    }
                }}>{getString(strings.Then)}</Button>
            </Modal.Body>
            <Modal.Footer>
                <Button className={"bigImageBtn2"} onClick={() => { props.onHide(); imageId = 0; }}>{getString(strings.Ok)}</Button>
            </Modal.Footer>
        </Modal >
    );
}

function GetDialog(props) {
    const [modalShow, setModalShow] = useState(true);

    return (
        <>
            <MyVerticallyCenteredModal
                defImgs={props.defImgs}
                comm={props.comm}
                target={props.target}
                src={props.src}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>);
}

function ShowBigImage(src, defImgs, comm, target) {
    render(<GetDialog src={src} comm={comm} defImgs={defImgs} target={target} />, document.createDocumentFragment());
}
export { ShowBigImage };
