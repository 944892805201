import React from "react";
import {Modal} from "react-bootstrap";
import {Button} from "react-bootstrap";
import {useState} from "react";
import {render} from "react-dom";
import {getString, strings} from "../../Languages/Languages";
import {User} from "../../User/User";
import axios from "axios";
import {ServerApi} from "../../Server/ServerApi";
import {CloseLoading, ShowLoading} from "./LoadingDialog";

let serverApi = new ServerApi();
let flag_dialog_about_onu = 0;
let filterID;
function KnotClutchFilter(props)
{
    if (flag_dialog_about_onu == 0){

        serverApi.getTypeList().then(result => {
            for (let aaaType in result.data){
                let couplingType = result.data[aaaType]
                let name = couplingType.single_name
                let id = couplingType.id

                let c = document.querySelector('#inputTypeId')
                c.innerHTML += `<input id="dinamic" data-id=${id} type="radio" name="filter" /> ${name}<br/>`
            }
            let handleClick = (event) => {
                filterID = event.target.dataset.id
                if (filterID === 'dinamic'){
                    filterID = '';
                }
                User.type_obj = filterID
            }

            let click = document.querySelectorAll('#dinamic')
            click.forEach(click => {
                click.addEventListener('click', handleClick)
            })

        }).catch((error) => {
            console.log(error);
        })


        flag_dialog_about_onu=1;
    } else if (flag_dialog_about_onu == 1){
        flag_dialog_about_onu=0;
    }
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div id={'inputTypeId'}>
                </div>
                <input
                    // onClick={() => {saveFilter('AllInfrastructure');}}
                    id="dinamic" data-id={'dinamic'}
                    type="radio" value="state_id"  /> {getString(strings.AllInfrastructure)}<br/>
            </Modal.Body>
            <Modal.Footer>
                <Button className={"btn btn-ObserversClose"} onClick={() => {props.onHide()}}>{getString(strings.Cancel)}</Button>
                <Button className={"ObserversClose"} onClick={() => {props.onHide();props.onApplyclick()}}>{getString(strings.Apply)}</Button>
            </Modal.Footer>
        </Modal>
    );
}

function GetDialog(props) {
    const [modalShow, setModalShow] = useState(true);

    return(
        <>
            <KnotClutchFilter
                onApplyclick={props.onApplyclick}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>);
}

function ShowKnotClutchFilter(onApplyClick)
{
    render (<GetDialog onApplyclick={onApplyClick}/>, document.createDocumentFragment());
}
export {ShowKnotClutchFilter};