import React from "react";
import svyazOnMap from '../../../../../Resources/Icons/Cards/svyz_on_map.png';
import { ShowDialog } from "../../../../../Utils/Dialog/Dialog";
import { getString, strings } from "../../../../../Languages/Languages";
// import { UserSideApi } from "../../../../UserSide/UserSideApi";
// import { getNodesMap } from "../../../Maps/Map";


export default function InfraObject(props) {

  const onHandleClick = async () => {
    if (!navigator.onLine) {
      ShowDialog({
        'title': getString(strings.Attention),
        'body': getString(strings.Offline),

        'pButtonClick': function () { },

        'pbtn': 'true',
        'nebtn': 'false',

        'pbtntitle': getString(strings.Ok),
      });

    }
    else {
      window.open("/Infrastructure", '_self', false);
    }
  }

  return (
    <div
      onClick={onHandleClick}
      className="col-md-4 col-sm-6 col-6 px-0">
      <div className="card border mx-1 my-1">
        <div className="card-body text-height-0-5 p-1">
          <img width={"70px"} src={svyazOnMap} className="text-primary display-1" data-toggle="modal" data-target="#exampleModal" alt={svyazOnMap} />
          <p className={"m-0"}>{props.infraObject}</p>
        </div>
      </div>
    </div>
  )
}