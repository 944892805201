import React from "react";
import abonOnMapIcon from '../../../../../Resources/Icons/Cards/abon_on_map.png';
import { ShowDialog } from "../../../../../Utils/Dialog/Dialog";
import { getString, strings } from "../../../../../Languages/Languages";
// import { UserSideApi } from "../../../UserSide/UserSideApi";
// import { getAbonOnMap } from "../../Maps/Map";

export default function AbonOnMap(props) {

  // function openAbonOnMap(addressId) {
  //   let userSideApi = new UserSideApi();
  //   userSideApi.getAbonCoords(addressId).then(coords => {
  //     getAbonOnMap(coords);
  //   });
  // }

  const onHandleClick = async () => {
    if (!navigator.onLine) {
      ShowDialog({
        'title': getString(strings.Attention),
        'body': getString(strings.Offline),

        'pButtonClick': function () { },

        'pbtn': 'true',
        'nebtn': 'false',

        'pbtntitle': getString(strings.Ok),
      });

    }
    else {

      window.open("/abonchoice", '_self', false)
      // window.open("/abonmap", '_self', false);
    }
  }
  return (
    <div
      // onClick={openAbonOnMap(props.addressId)}
      onClick={onHandleClick}
      className="col-md-4 col-sm-6 col-6 px-0"
    >
      <div className="card border mx-1 my-1">
        <div className="card-body text-height-0-5 p-1">
          <img width={"70px"} src={abonOnMapIcon} className="text-primary display-1" data-toggle="modal" data-target="#exampleModal" alt={abonOnMapIcon} />
          <p className={"m-0"}>{props.onMap}</p>
          <p className={"m-0"}>{props.sendCoord}</p>
        </div>
      </div>
    </div>
  )
}