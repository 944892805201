/* eslint-disable no-useless-concat */
import React from "react";
import taskAbonentFioIcon from '../../../../Resources/Icons/AboutTask/people.png';


export default function Fio(props) {
  return (
    ('2222' + '').replace(' ', '') !== '' &&                   //Абонент выпадающая вкладка
    <div className="col-sm-12">
      <label
        className="float-left text-primary mb-0">{props.taskFio}</label>
      <div className="input-group mb-2">
        <div className="input-group-prepend">
          <span className="input-group-text bg-primary"><img src={taskAbonentFioIcon} alt={taskAbonentFioIcon} width="20px" /></span>
        </div>
        <div id={"FIO"} className={"form-control h-auto smallText card d-block border-1  px-2 py-2 "}>
        </div>
      </div>
    </div>
  )
}