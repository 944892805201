import React, {useEffect, useState} from "react";
import { HexColorPicker, RgbaColorPicker } from "react-colorful";
import button from "bootstrap/js/src/button";
import {reactLocalStorage} from "reactjs-localstorage";


export default function ColorPicker(props) {
    const [color, setColor] = useState("rgb(0, 123, 255)");

    const changeColor = () => {
        reactLocalStorage.set(`color_${props.id}`, `rgba(${color.r},${color.g},${color.b},${color.a})`);
        props.handleClose();
    }

    return (
        <div className={'colorContainer'}>
                <div className="custom-layout" >
                    <RgbaColorPicker
                    onChange={setColor}
                    />
                </div>
            <div className={"color-modal-btn"}>
                <button className={"btn btn-danger color-close"} onClick={props.handleClose} >Отмена</button>
                <button className={"btn btn-primary color-save"} onClick={changeColor}>Сохранить</button>
            </div>

        </div>

    );
}
