import React, {useState, useEffect} from "react";
import {Button} from "react-bootstrap";
import {QRCodeSVG} from 'qrcode.react';
import {User} from "../../../../../User/User";
import Modal from 'react-bootstrap/Modal';
import {ServerApi} from "../../../../../Server/ServerApi";
import {getString, strings} from "../../../../../Languages/Languages";

export default function QrCodeCreate(props) {
    let serverApi = new ServerApi();
    const [show, setShow] = useState(false);
    const [qrShow, setQrShow] = useState(false)
    const [urlState, setUrlState] = useState('');
    const [custShow, setCustShow] = useState(false)
    const [userState, setUserState] = useState(User.namId);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const handleQrShow = () => setQrShow(true);
    const handleQrClose = () => setQrShow(false);
    const customerShow = () => setCustShow(true);
    const customerClose = () => setCustShow(false);

    const [groupState, setGroupState] = useState('');
    const [idState, setIdState] = useState('')
    const [costState, setCostState] = useState('');
    const [custIdState, setCustIdState] = useState('');
    const [acquiringState, setAcquiring] = useState('')

    useEffect(() => {
        if (props.customer.length === 1) {
            console.log(props.customer)
            setUserState(props.customer[0]['id']);
        }
    }, [])

    const onHandleClick = () => {
        let url = '';
        if (acquiringState === '0') {
            console.log('CloudPay');
            url = "https://taskusers.com/pay/index.php?cost=" + costState + "&customer_id=" + custIdState + "&key_pril=" + User.keyPril + "&imei=" + User.uniqueId + "&task_id=" + props.taskId + "&currency=RUB&group_id=" + groupState + "&public_id=" + idState;
        } else {
            console.log('Uniteller');
            url = "https://taskusers.com/catalog/controller/pay/Uniteller.php?cost=" + costState + "&customer_id=" + custIdState + "&key_pril=" + User.keyPril + "&imei=" + User.uniqueId + "&task_id=" + props.taskId + "&currency=RUB&group_id=" + groupState;
        }

        setUrlState(url)

        handleClose();
        handleQrShow();
    }
    const onHandleChange = (e) => {
        setCostState(e.target.value)
    }
    const chooseUserClick = (event) => {
        const id = event.target.id
        setUserState(id);
        serverApi.getCustomerData(id).then((response) => {
            const result = JSON.parse(response).data
            console.log(result)
            setCustIdState(result.agreement[0].number)
            setGroupState(Object.keys(result.group)[0]);
            setIdState(result.public_id);
            setAcquiring(result.acquiring)
            customerClose();

            handleShow();
        })
            .catch((error) => {
                console.log(error)
            });

    }
    const onHandleClickCost = () => {
        console.log(userState)
        if (userState !== '') {
            serverApi.getCustomerData(userState).then((response) => {

                const result = JSON.parse(response).data
                console.log(result)
                setCustIdState(result.agreement[0].number)
                setGroupState(Object.keys(result.group)[0]);
                setIdState(result.public_id);
                setAcquiring(result.acquiring)
                handleShow();
            })
                .catch((error) => {
                    console.log(error)
                });
        } else {
            customerShow();
        }
    }

    const customerChoose = (props) => {
        if (props !== '') {
            try {
                return (
                    props.customer.map((item) => {
                        return (
                            <button style={{marginTop: '5%'}} type="number" className="form-control btn-primary"
                                    aria-describedby="emailHelp"
                                    placeholder="Enter cost" onClick={chooseUserClick} id={item.id}>{item.Fio}</button>
                        )
                    })
                )
            } catch (error) {
                console.log(error)
            }
        } else {
            return '';
        }
    }

    return (
        <>
            <Modal show={show} onHide={handleClose}
                   style={{
                       marginTop: '30vh',
                   }}>
                <div style={{display: 'flex', flexDirection: 'column', marginTop: "1em", marginBottom: "1em"}}>
                    <div className="form-group" style={{width: '95%', marginLeft: 'auto', marginRight: 'auto'}}>
                        <label htmlFor="exampleInputEmail1">Введите сумму</label>
                        <input type="number" className="form-control" id="exampleInputEmail1"
                               aria-describedby="emailHelp" placeholder="Enter cost" onChange={onHandleChange}/>
                    </div>
                    <button onClick={onHandleClick} className="btn btn-primary"
                            style={{width: '70%', marginLeft: 'auto', marginRight: 'auto'}}>{props.getString}</button>
                </div>
            </Modal>
            <Modal show={custShow} onHide={customerClose}
                   style={{
                       marginTop: '30vh',
                   }}>
                <div style={{display: 'flex', flexDirection: 'column', marginTop: "1em", marginBottom: "1em"}}>
                    <div className="form-group" style={{width: '95%', marginLeft: 'auto', marginRight: 'auto'}}>
                        <label>{getString(strings.ChooseAbonent)}</label>
                        {customerChoose(props)}
                    </div>
                </div>
            </Modal>


            <div className={"col-sm-12 px-1 smallText"}>
                <Button className={"btn btn-primary btn-block mb-2"}
                        onClick={onHandleClickCost}>{props.getString} </Button>
            </div>
            <Modal show={qrShow} onHide={handleQrClose}
                   style={{
                       marginTop: '10vh',
                   }}>
                <QRCodeSVG value={urlState} height={'100%'} width={'100%'}/>
            </Modal>
        </>

    )
}

