import React, {useState} from "react";
import {getString, strings} from "../../../../Languages/Languages";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileImage, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {Swiper, Slide} from 'react-dynamic-swiper';
import {Button, Modal} from "react-bootstrap";
import {ShowLoading, CloseLoading} from "../../../../Utils/Dialog/LoadingDialog";
import {ServerApi} from "../../../../Server/ServerApi";
import {Form} from "react-bootstrap";


export default function ImagesSwiperPreviewClose(props) {


    const [state, setState] = useState({
        slideCount: 1,
        options: {
            navigation: true,
            pagination: true,
            paginationClickable: true,
            scrollBar: false,
            loop: false
        },
        count: 0,
        list: 0,
        setList: 0,
    })

    function PreviewImage() {
        let file = document.getElementById("file").files;
        if (file.length > 0) {
            let fileReader = new FileReader();

            fileReader.onload = function (event) {
                document.getElementById("preview").setAttribute("src", event.target.result);
            }
            fileReader.readAsDataURL(file[0]);
        }
    }

    const increment = (e) => {
        e.preventDefault()
        setState({slideCount: state.slideCount + 1})
        let imageBase = document.getElementById('preview').src;
        let serverApi = new ServerApi();
        let countImg = state.slideCount;
        let textarea = document.getElementById('commentText').value
        ShowLoading();
        document.getElementById('Demo-swiper').hidden = false;


        serverApi.uploadClosedTaskImage(imageBase, props.fieldId, textarea).then((response) => {
                let div = document.createElement('div');
                div.id = 'DivNewTask' + countImg;
                div.className = 'DivNewTask';
                let img = document.createElement('img');
                img.id = 'classImg' + countImg;
                img.src = imageBase;
                img.className = "classImg";
                img.width = 280;
                img.height = 100;

                div.append(img);
                document.querySelector('#key_slide' + countImg).append(div);
                CloseLoading();

                document.querySelector(".swiper-button-next").click();


            }
        )
            .catch((error) => {
                console.log(error)
            })
    }
    const decrement = (e) => {
        e.preventDefault();
        setState({slideCount: state.slideCount - 1})
    }


    return (

        <div style={{width: "100%"}}>

            <div className="Demo-swiper" id="Demo-swiper" hidden>
                <span className="close delete_slide" aria-hidden="true" id={"buttonRemove"}
                      onClick={decrement}>&times;</span>
                <Swiper
                    swiperOptions={{scrollbarHide: false}}
                    {...state.options}
                >
                    {(new Array(state.slideCount).fill(null).map((_, i) => (
                        <Slide className="Demo-swiper__slide" id={"key_slide" + i} key={i}>
                            {/*Slide {i + 1}*/}
                        </Slide>
                    )))}
                </Swiper>
            </div>

            <br/>
            <div id={'Hidden'} hidden={props.hidden}>
                <input className="btn btn-primary mr-2" type="file" multiple name="file" id="file"
                       onChange={() => PreviewImage()}
                       data-toggle="modal"
                       data-target="#exampleModal"
                       accept="image/*,image/jpeg"
                       hidden/>
                <label htmlFor="file" className="NewTaskLabel">
                    <FontAwesomeIcon icon={faFileImage}/>
                </label>
                <div className="modal fade" id="exampleModal" tabIndex="-1"
                     aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <Modal.Title id="contained-modal-title-vcenter">
                                    <FontAwesomeIcon icon={faInfoCircle} className={"DialogIcon"}/>
                                    <a>{getString(strings.PhotoDescription)}</a>
                                </Modal.Title>
                            </div>
                            <div>
                                <div id={"ddd"} className="modal-body">
                                    <img className={"image"} id={"preview"}/>
                                </div>
                                <div className="modal-body text-left">
                                    <form role="form">
                                        <textarea id={"commentText"} className="form-controlComments"
                                                  placeholder={getString(strings.EnterPhotoDescription)}/>
                                    </form>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-dangerSendComments"
                                        data-dismiss="modal">{getString(strings.Cancel)}</button>
                                <Button data-dismiss="modal" className="btn btn-dangerSendCommentsOk"
                                        onClick={increment}>{getString(strings.Ok)}</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br/><br/>

        </div>

    )
}