import React from "react";
import {Modal} from "react-bootstrap";
import {Button} from "react-bootstrap";
import {useState} from "react";
import {render} from "react-dom";
import {getString, strings} from "../../Languages/Languages";

function StartDot(props)
{
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className={'DotText'}>
                    {props.title}
                </div>
                <p/>
                <div id={'StartDotId'} className={'Dot'}>
                    {props.body}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className={"ObserversClose"} onClick={() => {props.onHide(); props.onApplyclick()}}>{getString(strings.Navigator)}</Button>
                <Button className={"btn btn-dangerCancelTask"} onClick={ () => props.onHide() }>{getString(strings.Cancel)}</Button>
            </Modal.Footer>
        </Modal>
    );
}

function GetDialog(props) {
    const [modalShow, setModalShow] = useState(true);

    return(
        <>
            <StartDot
                title={props.title}
                body={props.body}
                onApplyclick={props.onApplyclick}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>);
}

function ShowDot(onApplyClick, body, title)
{
    render (<GetDialog onApplyclick={onApplyClick}
                       body={body}
                       title={title}
    />, document.createDocumentFragment());
}
export {ShowDot};
