import React from "react";
import {Modal} from "react-bootstrap";
import {useState} from "react";
import {render} from "react-dom";
import {Button} from "react-bootstrap";
import {User} from "../../User/User";
import {getString} from "../../Languages/Languages";
import {strings} from "../../Languages/Languages";

function saveFilter(name)
{
    User.KnotClutchFilter = name;

}

// date_add
//date_change
//date_do
//date_finish
//state_id
//type_id


function FilterDialog(props)
{
    return (
        <Modal show={props.show} onHide={props.onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body>
                    <input onClick={() => {saveFilter('date_add');}} type="radio" value="date_add" name="filter" /> {getString(strings.ByCreationDate)} <br/>
                    <input onClick={() => {saveFilter('date_change');}} type="radio" value="date_change" name="filter" /> {getString(strings.ByDateOfChange)} <br/>
                    <input onClick={() => {saveFilter('date_do');}} type="radio" value="date_do" name="filter" /> {getString(strings.ByTheAppointedDate)} <br/>
                    <input onClick={() => {saveFilter('date_finish');}} type="radio" value="date_finish" name="filter" /> {getString(strings.ByFinishDate)} <br/>
                    <input onClick={() => {saveFilter('state_id');}} type="radio" value="state_id" name="filter" /> {getString(strings.ByStatus)} <br/>
                    <input onClick={() => {saveFilter('type_id');}} type="radio" value="type_id" name="filter" /> {getString(strings.ByType)} <br/>
            </Modal.Body>
            <Modal.Footer>
                    <Button className={"btn btn-ObserversClose"} onClick={() => props.onHide() }>{getString(strings.Cancel)}</Button>
                    <Button className={"ObserversClose"} onClick={() => {props.onHide(); props.onApplyclick(User.KnotClutchFilter);}}>{getString(strings.Apply)}</Button>
            </Modal.Footer>
        </Modal>
    );
}

function GetDialog(props) {
    const [modalShow, setModalShow] = useState(true);

    return(
        <>
            <FilterDialog onApplyclick={props.onApplyclick}
                          show={modalShow}
                          onHide={() => setModalShow(false)}/>
        </>);
}

function ShowFilterDialog(onApplyClick)
{
    render (<GetDialog onApplyclick={onApplyClick}/>,
        document.createDocumentFragment());
}
export {ShowFilterDialog};

