import React from "react";
import {Modal} from "react-bootstrap";
import {Button} from "react-bootstrap";
import {useState} from "react";
import {render} from "react-dom";
import {getString, strings} from "../../Languages/Languages";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";

function SupportDelete(props)
{
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    <FontAwesomeIcon icon={faInfoCircle} className={"DialogIcon"}/>
                    {getString(strings.Attention)}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.body}
            </Modal.Body>
            <Modal.Footer>
                <Button className={"btn btn-dangerCancelTask"} onClick={ () => props.onHide() }>{getString(strings.Cancel)}</Button>
                <Button className={"ObserversClose"} onClick={() => {props.onHide(); props.onApplyclick()}}>{getString(strings.Ok)}</Button>
            </Modal.Footer>
        </Modal>
    );
}

function GetDialog(props) {
    const [modalShow, setModalShow] = useState(true);

    return(
        <>
            <SupportDelete
                body={props.body}
                onApplyclick={props.onApplyclick}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>);
}

function ShowSupportDelete(onApplyClick,body)
{
    render (<GetDialog onApplyclick={onApplyClick}
                       body={body}
    />, document.createDocumentFragment());
}
export {ShowSupportDelete};