/* eslint-disable no-unused-vars */
import React from "react";
import { ShowCloseTheTask } from "../../../../Utils/Dialog/CloseTaskDialog";
import backArrow from '../../../../Resources/Icons/back-arrow.svg';
import { ShowSimpleDialog } from "../../../../Utils/Dialog/Dialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle, faHome } from "@fortawesome/free-solid-svg-icons";

let commentsAlreadyDownloaded = false;

export default function AboutFooterNav(props) {

  return (
    <nav className="navbar fixed-bottom navbar-light bg-white shadow-lg">
      <button type="button" className="btn btn-danger btn-circle"
        onClick={() => ShowCloseTheTask()}>x</button>
      <div className="float-left">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a id={"backBtn"} onClick={() => {
          // User.lastAboutTask.remove();
          window.history.back();
          commentsAlreadyDownloaded = false;
          localStorage.removeItem('namId')
          // GetMainForm().then(() => {});
        }
        } className="navbar-brand text-primary mx-2"><img src={backArrow} alt={backArrow} width={"25px"} /></a>
          <a className="navbar-brand text-primary mx-2"
             onClick={async () => {window.open("/main", '_self', false);}}>
              <FontAwesomeIcon width={"26px"}
                               icon={faHome}/>
          </a>
      </div>
      <div className="float-right text-right">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a onClick={() => { ShowSimpleDialog(props.taskTypeDescription.taskTypeDescription, props.taskTypeDescription.typeDescription); }}
          className="navbar-brand text-primary mx-2"><FontAwesomeIcon icon={faQuestionCircle} /></a>
      </div>
    </nav>
  )
}