import React from "react";
import { Button } from "react-bootstrap";
import { ShowONUDialog } from "../../../../Utils/Dialog/ONUDialog";

export default function LastOnuInfoHolder(props) {

  return (
    <div id={"lastOnuInfoHolder"} className="col-sm-12">
      <div className="input-group mb-3">
        <Button
          onClick={() => ShowONUDialog()}
          className={"btn btn-primary btn-block"}>{props.taskLastOnuInfo}</Button>
      </div>
    </div>
  )
}