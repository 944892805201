import React from "react";
import {Modal} from "react-bootstrap";
import {Button} from "react-bootstrap";
import {useState} from "react";
import {render} from "react-dom";
import {getString, strings} from "../../Languages/Languages";

function TaskOnMapDialog(props)
{
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="taskOnMap">{props.Id}</div>
                <div className="taskOnMap">{props.types}</div>
                <div className="taskOnMap">{props.date}</div>
               <p/>
                {props.Navigator}
                {props.More}
            </Modal.Body>
            <Modal.Footer>
                <Button className={"btn btn-dangerCancelTask"} onClick={ () => props.onHide() }>{getString(strings.Cancel)}</Button>
            </Modal.Footer>
        </Modal>
    );
}

function GetDialog(props) {
    const [modalShow, setModalShow] = useState(true);

    return(
        <>
            <TaskOnMapDialog
                Id={props.Id}
                types={props.types}
                date={props.date}
                Navigator={props.Navigator}
                More={props.More}
                onApplyclick={props.onApplyclick}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>);
}

function ShowTaskOnMapDialog(onApplyClick, Id, types, date, Navigator, More)
{
    render (<GetDialog onApplyclick={onApplyClick}
                       Id={Id}
                       types={types}
                       date={date}
                       Navigator={Navigator}
                       More={More}
    />, document.createDocumentFragment());
}
export {ShowTaskOnMapDialog};