import React from "react";

export default function NumberFieldType(props) {
  return (
    <>
      <label className="mr-sm-2">{props.name}</label>
      <input type="number" className="form-control" onChange={props.onChange} name={props.id} />
    </>

  )
}