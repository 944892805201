import React from "react";
import copyIcon from '../../../../Resources/Icons/copy.svg';
import taskAbonInternrtIcon from '../../../../Resources/Icons/AboutTask/internet.png';
import copyToClipBoard from "../../functions/copyToClipBoard";

export default function AbonIntActivity(props) {

  return (
    <div id={"internetActivityHolder"} className="col-sm-12">
      <label className="float-left text-primary mb-2">{props.taskInternet}</label>
      <div className="input-group mb-2">
        <div className="input-group-prepend">
          <span className="input-group-text bg-primary"><img src={taskAbonInternrtIcon} alt={taskAbonInternrtIcon} width="20px" /></span>
        </div>
        <div id={"internetActivity"} className={"form-control h-auto smallText card d-block border-1  px-2 py-2"} />
        <div className="input-group-append" onClick={() => copyToClipBoard(document.getElementById('internetActivity').innerText)}>
          <span className="input-group-text"><img src={copyIcon} alt={copyIcon} width="20px" /></span>
        </div>
      </div>
    </div>
  )
}