class ShortTask
{
    static activeTask;

    constructor(id, type, typeId, typeDescription, address, addressId, time, date, description, staff, status, sla,
                slaTime, price, timeForDoing, priority, customerId, relatedObjects, comments, photos, historyCount, nodes,
                comNodes, customer) {
        this.Id = id;
        this.Type = type;
        this.TypeId = typeId;
        this.TypeDescription = typeDescription;
       // this.Fio = fio;
        this.Customer = customer;
        this.Address = address;
        this.AddressId = addressId;
        this.Time = time;
        this.Date = date;
        this.Description = description;
        this.Staff = staff;
        this.Status = status;
        this.Sla = sla;
        this.SlaTime = slaTime;
        this.Price = price;
        this.TimeForDoing = timeForDoing;
        this.Priority = priority;
        this.CustomerId = customerId;
        this.RelatedObjects = relatedObjects;
        this.Comments = comments;
        this.Photos = photos;
        this.HistoryCount = historyCount;
        this.Nodes = nodes;
        this.ComNodes = comNodes;
    }
}
export {ShortTask};
