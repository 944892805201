import React from "react";

export default function NavList(props) {

  return (
    <ul className="nav nav-tabs" id="myTab" role="tablist">

      {/*<li id={"abonTabButton"} className="nav-item">
                                    <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home"
                                       role="tab"
                                       aria-controls="home" aria-selected="true">{getString(strings.Abon)}</a>
                                </li>*/}

      <li id={"addressTabButton"} className="nav-item" key={props.address}>
        <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile"
          role="tab"
          aria-controls="profile" aria-selected="false">{props.address}</a>
      </li>

      {/* <li id={"networkTabButton"} className="nav-item">
                                    <a className="nav-link" id="contact-tab" data-toggle="tab" href="#contact"
                                       role="tab"
                                       aria-controls="contact" aria-selected="false">{getString(strings.Network)}</a>
                                </li>*/}

      <li className="nav-item" key={props.other}>
        <a className="nav-link" id="add-tab" data-toggle="tab" href="#add" role="tab"
          aria-controls="contact" aria-selected="false">{props.other}</a>
      </li>

    </ul>
  )
}