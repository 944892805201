import React from "react";
import { Button } from "react-bootstrap";
import { ShowLoading } from "../../../../Utils/Dialog/LoadingDialog";
import { CloseLoading } from "../../../../Utils/Dialog/LoadingDialog";
import { ServerApi } from "../../../../Server/ServerApi";
import Images from "../ImagesComp/Images";
import { render } from "react-dom";

export default function ShowImages(props) {
  async function loadTaskPhoto(photos) {
    ShowLoading();
    let serverApi = new ServerApi();
    document.getElementById('showTaskPhotosBtn').hidden = true;
    let comment = [];
    let bases = [];
    let basesFull = [];
    // let arr = Object.keys(photos)
    // // console.log(arr)
    // await serverApi.getImage(arr).then((response) => {
    //   console.log(response)
    // });

    for (let photo in photos) {
      try {
        let base = await serverApi.getImage(photo);
        console.log(base)
        comment.push(base['comment'])
        bases.push(base['Low']);
        basesFull.push(base['Full']);
        CloseLoading();

        render(<Images images={bases} fullResImages={basesFull} comment={comment} />, document.getElementById('taskAttach'));
      }
      catch (e) {
        console.log(e)
        CloseLoading();
        document.getElementById('showTaskPhotosBtn').hidden = false;
      }
    }
  }
  return (
    props.photos !== '' && <div className={"col-sm-12 px-1 smallText"}>
      <Button onClick={() => loadTaskPhoto(props.photos)} id={"showTaskPhotosBtn"}  //показать изображение
        className={"btn btn-primary btn-block mb-2"}>{props.showImages}</Button>
      <div id={`taskAttach`} />
    </div>

  )
}