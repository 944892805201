import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { render } from "react-dom";
import { faFileImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactSwipe from "react-swipe";
import { ShowBigImage } from "./BigImageDialog";
import { CloseLoading, ShowLoading } from "./LoadingDialog";
import { ServerApi } from "../../Server/ServerApi";
import { getString, strings } from "../../Languages/Languages";

let images = [];
let base64Images = [];

function MyVerticallyCenteredModal(props) {
    images = [];
    base64Images = [];
    const [state, setstate] = useState([])
    const serverApi = new ServerApi()

    const exifVal = async (files) => {
        if (files) {
            for (let i in files) {
                try {
                    let src = URL.createObjectURL(files[i])
                    images.push(src);

                    let reader = new FileReader();
                    reader.readAsDataURL(files[i]);
                    reader.onloadend = function () {
                        base64Images.push(reader.result.replace('data:image/jpeg;base64,', ''));

                        setstate(state => [...state, reader.result.replace('data:image/jpeg;base64,', '')])
                    }
                }
                catch (e) {
                    console.log(e)
                }

            }

            // console.log(images)
            await render(<></>, document.getElementById("commentPhotos"))
            render(<Previews images={images} />, document.getElementById("commentPhotos"))
        }
    }

    return (
        <Modal show={props.show} onHide={props.onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <div id="commentModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{getString(strings.PostingAComment)}</h5>
                            <button onClick={() => props.onHide()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body text-left">

                            <form role="form">
                                <div className="form-group">
                                    <p className="help-block">{getString(strings.CanAttachPhotosAndComments)}</p>
                                </div>
                                <div className="form-group">
                                    <textarea id={"commentText"} className="form-control" placeholder="Комментарий" />
                                </div>

                                <div className="row">
                                    <div className="col-sm-8">
                                        <div id={"commentPhotos"} />
                                    </div>
                                    <div className="col-sm-4 text-right pt-2">
                                        <input className="btn btn-primary mr-2" type="file" multiple name="uploaded_file" id="uploaded_file"
                                            onChange={(e) => { exifVal(e.target.files); }} accept="image/*,image/jpeg" hidden={true} />
                                        <label htmlFor="uploaded_file" className="btn btn-primary mr-2" data-dismiss="modal">
                                            <FontAwesomeIcon icon={faFileImage} />
                                        </label>
                                    </div>
                                </div>

                                <div className="form-group text-center mt-2">
                                    <button onClick={() => props.onHide()} className="btn btn-dangerSend" data-dismiss="modal">{getString(strings.Close)}</button>
                                    <Button onClick={async () => {
                                        ShowLoading();
                                        // !!!!
                                        let result = await serverApi.addComment(document.getElementById('commentText').value);
                                        if (result) {
                                            props.onHide();
                                        }
                                        let commentId = result.Id
                                        let image = state;

                                        let results = await serverApi.addImageToComment(image, commentId);
                                        if (results) {
                                            props.onHide();
                                        }
                                        CloseLoading();
                                    }} className="btn btn-primarySend">{getString(strings.Send)}</Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

function Previews(props) {
    let reactSwipeEl;
    console.log(props)

    let divs = [];
    let imagesCount = props['images'].length;
    for (let i = 1; i <= imagesCount + 1 + (imagesCount % 3); i++) {
        if (i % 3 === 0) {
            let images = [];
            for (let j = i - 3; j < i; j++) {
                if (j < imagesCount) {
                    images.push(props['images'][j]);
                }
            }
            divs.push(images);
        }
    }

    return (
        <div>
            <ReactSwipe
                className="swipe-wrap carousel"
                swipeOptions={{ continuous: false }}
                ref={el => (reactSwipeEl = el)}>
                {divs.map(div =>
                    <div className={"container"}>
                        {div.map(src => <img id={'imgPhoto'} onClick={(e) => {
                            ShowBigImage(images, images, e.target.getAttribute("src"));
                        }} style={{ maxHeight: 100, width: '33%' }} src={`${src}`} alt={`${src}`} className={"col-sm-4 img-thumbnail"} />)}
                    </div>)}
            </ReactSwipe>
        </div>
    );
}

function GetDialog(props) {
    const [modalShow, setModalShow] = useState(true);

    return (
        <>
            <MyVerticallyCenteredModal
                src={props.src}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>);
}

function ShowSendCommentDialog() {
    render(<GetDialog />, document.createDocumentFragment());
}
export { ShowSendCommentDialog };
