import React, {Component} from 'react';
import {YMaps, Map, Placemark, TypeSelector, ZoomControl, FullscreenControl, GeolocationControl, RouteButton, Polyline} from 'react-yandex-maps';
import {Button} from "react-bootstrap";
import {getString, strings} from "../Languages/Languages";
import {ServerApi} from "../Server/ServerApi";
import {User} from "../User/User";
import {ShowDialog} from "../Utils/Dialog/Dialog";
import {CloseLoading, ShowLoading} from "../Utils/Dialog/LoadingDialog";

let commentsAlreadyDownloaded = false;
let coordinates;
let polyline = [];

class AbonMap extends Component {
    state = {
        center: [55.595653, 38.472397],
        zoom: 19,
        coordinates: [],
        polyline: []
    }
    componentDidMount() {
        let serverApi = new ServerApi();
        let height = document.documentElement.clientHeight
        height = height - 76
        let CustomerId = User.Customer

        let cor;
        let zoom;
        cor = [55.595653, 38.472397]
        zoom = 10

        ShowLoading();
        serverApi.customerGetData(CustomerId).then((response) => {
            if (response.data.state_id != 0) {
                for (let h in response.data.address) {
                    let houseId = response.data.address[h].house_id


                    serverApi.addressGetHouse(houseId).then((response) => {
                        for (let v in response.data) {
                            coordinates = response.data[v].coordinates

                            for (let g in coordinates){
                                polyline.push(coordinates[g])
                            }

                        if (coordinates[0] != '' || coordinates[0] != undefined &&
                            coordinates[0] != '' || coordinates[0] != undefined) {

                            cor = [coordinates[0][0], coordinates[0][1]]
                            zoom = 19

                            function Navigators() {
                                let lat = coordinates[0][0];
                                let lon = coordinates[0][1];
                                navigator.geolocation.getCurrentPosition(function (position) {
                                    let latGPS = position.coords.latitude
                                    let lonGPS = position.coords.longitude
                                    document.location.href = "yandexmaps://maps.yandex.ru/?rtext=" + latGPS + "," + lonGPS + "~" + lat + "," + lon;
                                });
                            }
                            this.setState({cor, zoom, Navigators});
                        }
                        this.setState({coordinates,polyline});
                    }
                    })
                    this.setState({cor, zoom});
                }
            }
            else {
                ShowDialog({
                    'title': getString(strings.Attention),
                    'body': getString(strings.CallersLocationUnknown),

                    'pButtonClick': function () {},

                    'pbtn': 'true',
                    'nebtn': 'false',

                    'pbtntitle': getString(strings.Ok),
                });
            }
            CloseLoading();
        })
        this.setState({height, cor, zoom})
    }
    render() {
        return (
            <div className={"SubscriberMapForm"} >
            <YMaps query={{ apikey: '3261ce08-c60a-4114-96f8-ce820abf124a' }}>
                <div>
                    <Map
                        //  state={this.state}
                         state={{ center:this.state.cor, zoom: this.state.zoom,
                                  type: 'yandex#map',
                                  bounds: undefined,
                                  coordinates: []
                         }}
                         height={this.state.height}
                         width='100%'
                    >
                        <ZoomControl options={{ float: 'right' }} />
                        <TypeSelector options={{ float: 'right' }} />


                        <Polyline geometry={this.state.polyline}
                                  options={{
                            balloonCloseButton: false,
                            strokeColor: '#000',
                            strokeWidth: 4,
                            strokeOpacity: 0.5
                        }} />
                        {this.state.coordinates.map((mark, index) => (
                            <Placemark
                                onClick={this.state.Navigators}
                                       key={index}
                                       geometry={mark}
                            />
                        ))}
                        <FullscreenControl />
                        <GeolocationControl options={{float: 'left'}}/>
                        <RouteButton options={{
                            float: 'right'
                        }} />
                    </Map>
                    <br/>
                    <div>
                        <Button onClick={() => {window.history.back();commentsAlreadyDownloaded = false;}}
                                className={"YamapBackAbon"}>{getString(strings.Back)}</Button>
                    </div>
                </div>
            </YMaps>
            </div>
        );
    }
}
export default AbonMap;