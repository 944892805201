import React from "react";
import taskAbonAddDataIcon from '../../../../Resources/Icons/AboutTask/addition.png';
import copyIcon from '../../../../Resources/Icons/copy.svg';
import copyToClipBoard from "../../functions/copyToClipBoard";

import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

export default function AbonInfo(props) {

  return (
    <div id={"addInfoHolder"} className="col-sm-12">
      <label className="float-left text-primary mb-0">{props.taskAddData}</label>
      <div className="input-group mb-2">
        <div className="input-group-prepend">
          <span className="input-group-text bg-primary"><img src={taskAbonAddDataIcon} alt={taskAbonAddDataIcon} width="20px" /></span>
        </div>
        <div id={"addInfo"} className={"form-control h-auto smallText card d-block border-1  px-2 py-2"} />
        <div className="input-group-append"
          onClick={() => copyToClipBoard(document.getElementById('addInfo').innerText)}>
          <span className="input-group-text"><img src={copyIcon} alt={copyIcon} width="20px" /></span>
        </div>
        <NotificationContainer />
      </div>
    </div>
  )
}