import { useState } from "react";
import { Button } from "react-bootstrap";
import { User } from "../../../../User/User";
import { ServerApi } from "../../../../Server/ServerApi";
import { ShowObservers } from "../../../../Utils/Dialog/ObserversDialog";
import { ShowObserversClose } from "../../../../Utils/Dialog/ObserversCloseDialog";

export default function Observers(props) {
  const [state, setState] = useState(true)
  let serverApi = new ServerApi();

  function ShowAddObservers() {
    let observersId = document.getElementById('ObserversId')
    observersId.hidden = !state;
    setState(!state)
  }

  return (
    <div className="col-sm-12">
      <div className="input-group mb-2">
        <Button
          onClick={() => ShowAddObservers()}
          className={"btn btn-primary btn-block"}>{props.taskShowWatchers}
        </Button>
      </div>

      <div id={'ObserversId'} hidden>
        <div id={'ObserversDivId'} className={'ObserversDiv'}>
        </div>
        <Button id={'ObserversIdDialog'}
          onClick={() => ShowObservers((id) => {
            let observers = User.observers
            let ID = id;

            document.querySelector('.ObserversDiv').innerHTML +=
              `<div id='ObserversButtonId' data-name=${ID} type="button" class="ObserversButton">${observers}</div>`

            serverApi.add_employee(User.taskId, ID).then((response) => {
            });

            let handleClick = (event) => {
              let observersText = event.target
              let a = Number(event.target.dataset.name)

              ShowObserversClose(() => {
                serverApi.del_employee(User.taskId, a).then((response) => {
                });
                observersText.remove()
                localStorage.removeItem('observers')
              })
            }
            let observersDiv = document.querySelectorAll('#ObserversButtonId')
            observersDiv.forEach(observersDiv => {
              observersDiv.addEventListener('click', handleClick)
            })
          })}
          className={"btn btn-primary btn-block"}>{props.addObservers}
        </Button>
      </div>
    </div>
  )
}