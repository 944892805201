import React from "react";
import {Modal} from "react-bootstrap";
import {useState} from "react";
import {render} from "react-dom";
import refreshIcon from "../../Resources/Icons/refresh.svg";
import {getString, strings} from "../../Languages/Languages";

function MyVerticallyCenteredModal(props)
{
    return (
        <Modal style={{ zIndex: 10000 }}
            show={props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div style={{ fontSize: 20 }}>
                    <img className="objRotate mr-5" src={refreshIcon} alt={refreshIcon} width="50px"/>
                    {getString(strings.Loading)}...
                </div>
            </Modal.Body>
        </Modal>
    );
}

function GetDialog() {
    const [modalShow, setModalShow] = useState(true);

    return(
        <>
            <MyVerticallyCenteredModal
                show={modalShow}
            />
        </>);
}

function CloseLoading()
{
    render(<></>, document.getElementById('LoadingHolder'))
}
export {CloseLoading};

function ShowLoading()
{
    render (<GetDialog/>, document.getElementById('LoadingHolder'));
}
export {ShowLoading};
