import React from "react";
import downChevron from '../../../../Resources/Icons/down-chevron.svg';
import downloadIcon from '../../../../Resources/Icons/download.svg';

export default function Network(props) {

  return (
    <div>
      <div className="col-sm-12 my-2 px-1">
        <a data-toggle="collapse" href="#collapseExample"
          role="button" aria-expanded="false" aria-controls="collapseExample">
          <div className="input-group mb-0" id={'AbonNetwork'}>
            <input type="text" className="bg-primary text-light form-control smallText"
              defaultValue={props.network} disabled />
            <div className="input-group-append">
              <span className="input-group-text"><img src={downChevron} alt={downloadIcon} width="20px" /></span>
            </div>
          </div>
        </a>
      </div>
    </div>
  )
}