import React from "react";
import {Modal} from "react-bootstrap";
import {Button} from "react-bootstrap";
import {useState} from "react";
import {render} from "react-dom";
import {getString, strings} from "../../Languages/Languages";
import logo from '../../Resources/Logo/Logo_white_512x512.png'
import packageJson from '../../../package.json';

function MyVerticallyCenteredModal(props)
{
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title style={{ textAlign: 'center' }} id="contained-modal-title-vcenter">
                    <img style={{width: '100%', paddingLeft: 100, paddingRight: 100}} src={logo} alt={logo}/>
                    <h6 style={{ color: '#787e80d6' }} >v. {packageJson.version}</h6>
                    <h6>{getString(strings.Site)}: <a href={'https://taskusers.com'}>https://taskusers.com</a></h6>
                    <h6>{getString(strings.Email)}: web@intronex.ru</h6>
                    <h6>{getString(strings.Phone)}: <a href={'tel:+7(499)999-01-30'}>+7(499)999-01-30</a></h6>
                </Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button onClick={ () => props.onHide() }>{getString(strings.Ok)}</Button>
            </Modal.Footer>
        </Modal>
    );
}

function GetDialog() {
    const [modalShow, setModalShow] = useState(true);

    return(
        <>
            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>);
}

function ShowAbout()
{
    render (<GetDialog/>, document.createDocumentFragment());
}
export {ShowAbout};
