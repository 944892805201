import {User} from "../User/User";
import {ShortTask} from "../Task/Task";
import sendRequest from "./sendRequest";

class ServerRequests {
    static #url = `pwa/controller`;

    static async authorize(login, pass, deviceId, keyPrill) {
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'Authorization',
                        'login': login,
                        'password': pass,
                        'imei': deviceId,
                        'key_pril': keyPrill,
                        'locale': navigator.language,
                    },
            });

        return await sendRequest(this.#url, json);
    }

    static async getImage1(id) {
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'GetBase64Images',
                        'photoId': id,
                        'key_pril': User.keyPril,
                    }
            });
        return await sendRequest(this.#url, json);
    }

    async checkLicense(deviceId, login, keyPrill) {
        const url = `pwa/control`;

        const json = JSON.stringify(
            {
                'imei': deviceId,
                'login': login,
                'server': keyPrill,
            });
        return await sendRequest(url, json);
    }

    async checkAllow(deviceId, login, keyPrill) {
        const url = `pwa/allow`;

        const json = JSON.stringify(
            {
                'imei': deviceId,
                'tel': login,
                'lic': keyPrill,
            });
        return await sendRequest(url, json);
    }

    async uploadImage(imageBase, taskId, comment) {
        let url = 'pwa/uploadimg1';

        const json = JSON.stringify(
            {
                'upload_img': imageBase,
                'user': User.login,
                'task_id': User.taskId,
                'key_pril': User.keyPril,
                'comment': comment
            });
        return await sendRequest(url, json);
    }

    async getNodeScheme(id) {
        const url = `pwa/requestequipmentReact`;

        const json = JSON.stringify(
            {
                'equipment_id': id,
                'key_pril': User.keyPril,
            });
        return await sendRequest(url, json);
    }

    static async getMainTasks(division_id, employee_id, keyPrill, date_do_to, date_do_from) {

        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'GetList',
                        'order_by': 'date_do',
                        'division_id': division_id,
                        'employee_id': employee_id,
                        'key_pril': keyPrill,
                        'date_do_to': date_do_to,
                        'date_do_from': date_do_from,
                        'locale': navigator.language,
                    },
            });
        return await sendRequest(this.#url, json);
    }

    static async getFilterTaskList(division_id, employee_id, keyPrill, date_do_to, date_do_from, order_by) {

        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'GetFilteredList',
                        'order_by': order_by,
                        'division_id': division_id,
                        'employee_id': employee_id,
                        'key_pril': keyPrill,
                        'date_do_to': date_do_to,
                        'date_do_from': date_do_from,
                        'locale': navigator.language,
                    },
            });
        return await sendRequest(this.#url, json);
    }

    static async getMonthEmployeeList(employee_id, date_do_from, key_pril, state_id) {

        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'GetMonthEmployeeList',
                        'order_by': "date_to",
                        'employee_id': employee_id,
                        'date_do_from': date_do_from,
                        'state_id': state_id,
                        'key_pril': key_pril,
                        'locale': navigator.language,
                    },
            });
        return await sendRequest(this.#url, json);
    }

    static async GetEmployeeDataAll(key_pril) {

        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'GetEmployeeDataAll',
                        'key_pril': key_pril,
                    },
            });
        return await sendRequest(this.#url, json);
    }

    static async getMonthDivisionPerformed(division_id, date_do_from, key_pril, state_id) {

        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'GetMonthDivisionList',
                        'order_by': "date_to",
                        'division_id': division_id,
                        'date_do_from': date_do_from,
                        'key_pril': key_pril,
                        'state_id': state_id,
                        'locale': navigator.language,
                    },
            });
        return await sendRequest(this.#url, json);
    }


    static async getMainTaskState(division_id, employee_id, keyPrill, date_do_to, date_do_from, state_id) {
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'GetList',
                        'order_by': "date_to",
                        'division_id': division_id,
                        'employee_id': employee_id,
                        'key_pril': keyPrill,
                        'date_do_to': date_do_to,
                        'date_do_from': date_do_from,
                        'locale': navigator.language,
                        'state_id': state_id,
                    },
            });
        return await sendRequest(this.#url, json);
    }

    static async getTaskListFinishState(employeeId, keyPrill, dateFinishFrom, dateFinishTo, state_id) {
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'GetListFinishState',
                        'order_by': "date_to",
                        'employee_id': employeeId,
                        'key_pril': keyPrill,
                        'date_finish_to': dateFinishTo,
                        'date_finish_from': dateFinishFrom,
                        'state_id': state_id,
                    },
            });
        return await sendRequest(this.#url, json);
    }

    static async getTaskListType(employee_id) {
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'GetListType',
                        'employee_id': employee_id,
                        'key_pril': User.keyPril,
                    },
            });
        return await sendRequest(this.#url, json);
    }

    static async getCatalogType() {
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'GetCatalogType',
                        'key_pril': User.keyPril,
                    },
            });
        return await sendRequest(this.#url, json);
    }

    static async getTaskState(employee_id, dateAddFrom) {
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'GetListState',
                        'employee_id': employee_id,
                        'dateAddFrom': dateAddFrom,
                        'key_pril': User.keyPril,
                    },
            });
        return await sendRequest(this.#url, json);
    }


    static async getAboutData(customerId, addressId, keyPril, taskId, relatedObjects) {
        //Добавить фильтр order_by

        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'TaskData',
                        'customerId': customerId,
                        'addressId': addressId,
                        'taskId': taskId,
                        'keyPril': keyPril,
                        'relatedObjects': relatedObjects,
                        'locale': navigator.language,
                    },
            });
        return await sendRequest(this.#url, json);
    }


    static async getDivisionList() {
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'GetDivisionList',
                        'keyPril': User.keyPril,
                    },
            });
        return await sendRequest(this.#url, json);
    }

    static async getDivision(id) {
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'GetDivision',
                        'keyPril': User.keyPril,
                        'id': id,
                    },
            });
        return await sendRequest(this.#url, json);
    }


    //Уровень сигнала ONU
    static async getRx(customerId, limit) {
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'GetPonLevel',
                        'customerId': customerId,
                        'limit': limit,
                        'keyPril': User.keyPril,
                    },
            });
        return await sendRequest(this.#url, json);
    }

    //Уровень сигнала OLT
    static async getOltRx(customerId, limit) {
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'GetOltPonLevel',
                        'customerId': customerId,
                        'limit': limit,
                        'keyPril': User.keyPril,
                    },
            });
        return await sendRequest(this.#url, json);
    }

    async addComment(comm) {
        let url = `pwa/addcomment`;
        const json = JSON.stringify(
            {
                'comment': comm,
                'task_id': ShortTask.activeTask,
                'user': User.login,
                'key_pril': User.keyPril,
                'name_comm': User.name,
            });

        return await sendRequest(url, json);
    }

    async addImageToComment(image, commentId) {
        let url = `pwa/attachPhotoToComm`;

        const json = JSON.stringify(
            {
                'img_base64': image,
                'user': User.login,
                'task_id': ShortTask.activeTask,
                'key_pril': User.keyPril,
                'comment_id': commentId,
            });

        return await sendRequest(url, json);
    }

    async uploadClosedTaskImage(imageBase, fieldId, comment) {
        let url = 'pwa/uploadClosedTaskImg';

        const json = JSON.stringify(
            {
                'upload_img': imageBase,
                'user': User.login,
                'task_id': User.taskId,
                'fieldId': fieldId,
                'key_pril': User.keyPril,
                'comment': comment
            });
        return await sendRequest(url, json);
    }

    async getUserSideConfig(deviceId, keyPrill) {
        const url = `pwa/check1`;

        const json = JSON.stringify(
            {
                'imei': deviceId,
                'key_pril': keyPrill,
            });

        return await sendRequest(url, json);
    }

    async createTask(date, opis, taskId) {
        let url = `pwa/newtask`;

        const json = JSON.stringify(
            {
                'date': date,
                'user': User.login,
                'task_Id': taskId,
                'key_pril': User.keyPril,
                'opis': opis,
            });

        return await sendRequest(url, json);
    }

    async getAbonCoords(id) {
        let url = `pwa/controller`;

        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': "GetAbonCoords",
                        'keyPril': User.keyPril,
                        'houseId': id,
                    },
            });

        return await sendRequest(url, json);
    }


    async createTaskDivision(date, opis, taskId, divisionId) {
        let url = `pwa/newtask`;
        const json = JSON.stringify(
            {

                'action': "createNewTask",
                'date': date,
                'user': User.login,
                'task_id': taskId,
                'division_id': divisionId,
                'key_pril': User.keyPril,
                'opis': opis,

            });
        return await sendRequest(url, json);
    }

    static async getUzelCoords(id) {
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'GetUzelCoords',
                        'nodeId': id,
                        'keyPril': User.keyPril,
                    },
            });
        return await sendRequest(this.#url, json);
    }

    static async GetRelatedTaskId(taskId) {
        let url = `pwa/controller`;

        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': "GetRelatedTaskId",
                        'keyPril': User.keyPril,
                        'taskId': taskId,
                        'locale': navigator.language,
                    },
            });
        return await sendRequest(url, json);
    }

//Получение последней информации по ONT/ONU
    static async GetOntData(id) {
        let url = `pwa/controller`;

        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': "GetOntData",
                        'id': id,
                        'keyPril': User.keyPril,
                    },
            });
        return await sendRequest(url, json);
    }

    static async GetShowTask(id) {
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': "taskshow",
                        'taskId': id,
                        'keyPril': User.keyPril,
                    },
            });
        return await sendRequest(this.#url, json);
    }

    static async GETWatcherEmployeeList(id) {
        let url = `pwa/controller`;

        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': "watcher_employee_list",
                        'keyPril': User.keyPril,
                        'id': id,
                        'locale': navigator.language,
                    },
            });
        return await sendRequest(url, json);
    }

    //
    static async GetShowEmployee(id) {

        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': "show",
                        'taskId': id,
                        'keyPril': User.keyPril,
                    },
            });
        return await sendRequest(this.#url, json);
    }

    //список исполнителей
    //
    static async get_allow_staff(id) {

        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': "get_allow_staff",
                        'taskId': id,
                        'key_pril': User.keyPril,
                    },
            });
        console.log(json);

        return await sendRequest(this.#url, json);
    }

    //добавление наблюдателя
    static async employee_add(id, employee_id) {

        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': "employee_add",
                        'taskId': id,
                        'employee_id': employee_id,
                        'key_pril': User.keyPril,
                    },
            });
        return await sendRequest(this.#url, json);
    }

    //удаление
    static async employee_del(id, employee_id) {

        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': "employee_delete",
                        'taskId': id,
                        'employee_id': employee_id,
                        'key_pril': User.keyPril,
                    },
            });
        console.log(json);

        return await sendRequest(this.#url, json);
    }

    //загрузка обьектов
    static async list_line(id) {

        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': "fiber_get_list",
                        'object_id': id,
                        'houseId': null,
                        'key_pril': User.keyPril,
                    },
            });
        return await sendRequest(this.#url, json);
    }


    static async gettypelist() {
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'get_type_list',
                        'keyPril': User.keyPril,
                    },
            });
        return await sendRequest(this.#url, json);
    }

    //Закрытие задания (с изменением статуса задания - "На проверке")
    static async closeTask(id, state_id) {
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'close_task',
                        "taskId": id,
                        "stateId": state_id,
                        "keyPril": User.keyPril,
                    },
            });
        return await sendRequest(this.#url, json);
    }

    //Закрытие задания ???(Не используется)
    static async closeTaskAditional(type_id) {
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'close_task_additional',
                        "type_id": type_id,
                        "keyPril": User.keyPril,
                    },
            });
        return await sendRequest(this.#url, json);
    }

    static async getAdditionalFieldValue(field_id) {
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'get_additional_field_value',
                        "keyPril": User.keyPril,
                        "field_id": field_id,
                        "object_id": User.taskId,
                    },
            });
        return await sendRequest(this.#url, json);
    }

//Изменение дополнительных полей в "закрываемом задании"
    static async changeAdditionalFieldValue(sendObj) {
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'close_task_additional_task_over',
                        "keyPril": User.keyPril,
                        "task_id": User.taskId,
                        "data": sendObj,
                    },
            });
        return await sendRequest(this.#url, json);
    }


    static async getIdByCoord(lat, lon, range, type) {
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'get_id_by_coord',
                        'lat': lat,
                        'lon': lon,
                        'range': range,
                        'type': type,
                        'keyPril': User.keyPril,
                    },
            });
        return await sendRequest(this.#url, json);
    }

    static async getInfraS(id) {
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'getInfraS',
                        'keyPril': User.keyPril,
                        'id': id,
                    },
            });
        return await sendRequest(this.#url, json);
    }

    static async getInfraSAddress(id) {
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'getInfraS',
                        'keyPril': User.keyPril,
                        'id': id,
                    },
            });
        return await sendRequest(this.#url, json);
    }

//Получение информации о пользователе(используется для генерации QR-кода на оплату)
    static async getCustomerData(id) {
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'getCustomerData',
                        'keyPril': User.keyPril,
                        'id': id,
                        'imei': User.uniqueId
                    },
            });
        return await sendRequest(this.#url, json);
    }

    static async markShow() {
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'mark_show',
                        'keyPril': User.keyPril,
                    },
            });
        return await sendRequest(this.#url, json);
    }

    static async NodeGetMark(markId) {
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'NodeGetMark',
                        'keyPril': User.keyPril,
                        'mark_id': markId,
                    },
            });
        return await sendRequest(this.#url, json);
    }

    static async attachGet(id) {
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'attachGet',
                        'keyPril': User.keyPril,
                        'object_type': 'node',
                        'object_id': id,
                    },
            });
        return await sendRequest(this.#url, json);
    }

    //Добавление объекта
    static async nodeAdd(comment, coordinates) {

        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': "nodeAdd",
                        'type': '2',
                        'comment': comment,
                        'is_planned': '1',
                        'coordinates': coordinates,
                        'keyPril': User.keyPril,
                    },
            });
        return await sendRequest(this.#url, json);
    }

    //Добавление метки
    static async nodeAddMark(nodeId, markId) {

        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': "nodeAddMark",
                        'node_id': nodeId,
                        'mark_id': markId,
                        'keyPril': User.keyPril,
                    },
            });

        return await sendRequest(this.#url, json);
    }

    static async getFile(id) {
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'getFile',
                        'keyPril': User.keyPril,
                        'id': id,
                    },
            });
        return await sendRequest(this.#url, json);
    }

    static async getinfrasaddress(id) {
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'getInfraSAddress',
                        'keyPril': User.keyPril,
                        'id': id,
                    },
            });
        return await sendRequest(this.#url, json);
    }

    //Получение наименований каталога ТМЦ
    static async getInventorySectionCatalog() {
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'getInventorySectionCatalog',
                        'keyPril': User.keyPril,
                    },
            });
        return sendRequest(this.#url, json);
    }

//Address
    static async getProvince() {
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'getProvince',
                        'keyPril': User.keyPril,
                    },
            });
        return sendRequest(this.#url, json);
    }

    static async getDistrict() {
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'getDistrict',
                        'keyPril': User.keyPril,
                    },
            });
        return sendRequest(this.#url, json);
    }

    static async getCity(id) {
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'getCity',
                        'keyPril': User.keyPril,
                        'district_id': id,
                    },
            });
        return sendRequest(this.#url, json);
    }


    static async getInventoryCatalog() {
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'getInventoryCatalog',
                        'keyPril': User.keyPril,
                    },
            });
        return sendRequest(this.#url, json);
    }

    static async getInventoryAmount(objectId) {
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'getInventoryAmount',
                        'keyPril': User.keyPril,
                        'object_id': objectId
                    },
            });
        return sendRequest(this.#url, json);
    }

    static async getOficeCoord() {
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'getOficeCoord',
                        'keyPril': User.keyPril,
                    },
            });
        return sendRequest(this.#url, json);
    }

    static async setTimesheetData(employee_id, date, type_id, value) {
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'setTimesheetData',
                        'keyPril': User.keyPril,
                        "employee_id": employee_id,
                        "date": date,
                        "type_id": type_id,
                        "value": value,
                    },
            });
        return sendRequest(this.#url, json);
    }
    static async getWorkTimeData(date_from, date_to, employee_id) {
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'getWorkTimeData',
                        'keyPril': User.keyPril,
                        "employee_id": employee_id,
                        "date_from": date_from,
                        "date_to": date_to,
                    },
            });
        return sendRequest(this.#url, json);
    }
    static async workTimeStart(employee_id, date, comment) {
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'workTimeStart',
                        'keyPril': User.keyPril,
                        "employee_id": employee_id,
                        "date": date,
                        "comment": comment,
                    },
            });
        return sendRequest(this.#url, json);
    }
    static async workTimeFinish(employee_id, date, comment) {
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'workTimeFinish',
                        'keyPril': User.keyPril,
                        "employee_id": employee_id,
                        "date": date,
                        "comment": comment,
                    },
            });
        return sendRequest(this.#url, json);
    }

    static async CustomerGetData(id){
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'CustomerGetData',
                        'keyPril': User.keyPril,
                        'id': id
                    },
            });
        return await sendRequest(this.#url, json);
    }

    static async AddressGetHouse(id){
        const json = JSON.stringify(
            {
                'info':
                    {
                        'action': 'AddressGetHouse',
                        'keyPril': User.keyPril,
                        'id': id
                    },
            });
        return await sendRequest(this.#url, json);
    }


}

export {ServerRequests};
