import React from "react";
import setField from "./setField";
import { ServerApi } from "../../../Server/ServerApi";
import { User } from "../../../User/User";
import { Button } from "react-bootstrap";
import { render } from "react-dom";

export default function setData(id, abonData, addressData, networkData, otherData) //Функция с массивом и выводом данных
{


  // SET ADDRESS TAB DATA
  if (addressData === null) {
    document.getElementById('addressTabButton').hidden = true;
    document.getElementById('profile').hidden = true;
  }
  else {
    try {
      setField('notes', 'notesHolder', addressData['Note']);
      setField('exits', 'exitsHolder', addressData.additional_data['Выходы']);


      setField('workNote', 'workNoteHolder', addressData['WorkNote']);

      if (addressData.additional_data['KEYS'].length <= 0) {
        document.getElementById('keysHolder').hidden = true;
      } else {
        let keys = "";
        for (let key in addressData.additional_data['KEYS']) {
          keys += addressData.additional_data['KEYS'][key];
        }
        document.getElementById('keys').innerText = keys.substring(0, keys.length);
      }
    }
    catch (e) {
      console.log(e)
    }



  }



  // SET OTHER TAB DATA

  //Связанные задания
  let serverApi = new ServerApi();
  serverApi.GetRTID(User.taskId).then((response) => {
    if (response) {
      if (response.length > 0 || response.length === undefined || response.length === '') {
        let data = response['data'].split(',').sort(function (a, b) {
          return b - a;
        });
        if (response['data'].length <= 0) {
          document.getElementById('relatedTasksHolder').hidden = true;
        } else {
          let relatedTasks = [];
          for (let aaaId in data) {
            let id = data[aaaId]

            relatedTasks.push(<Button key={`relatedTaskBtn${id}`} id={`relatedTaskBtn${id}`} data-ids={`${id}`} onClick={async () => { window.open("/about/" + id, '_self', false); }}
              className={"btn-primary"}>{id}</Button>);
          }
          render(relatedTasks, document.getElementById('relatedTasks'));
        }


      }
      else {
        document.getElementById('relatedTasksHolder').hidden = true;
      }
    }


  })
      .catch((error) => {
        console.log(error)
      })
  document.getElementById('backBtn').hidden = false;
  User.setAboutTask(id, abonData, addressData, networkData, otherData);

}