import axios from "axios";
import {Config} from "./Config";
import {User} from "../User/User";
import {ShortTask} from "../Task/Task";

class UserSideRequests
{
    static #url = '';

    constructor()
    {
        UserSideRequests.#url = `${Config.UserSide.get()}/api.php`;
    }

    async taskShow(id)
    {
        console.log(id)
        let json = new FormData();
        json.append('key', Config.ApiKey.get());
        json.append('cat', 'task');
        json.append('action', 'show');
        json.append('id', id);

        return await UserSideRequests.#sendRequest(UserSideRequests.#url, json);
    }

    async addComment(text)
    {
        let json = new FormData();
        json.append('key', Config.ApiKey.get());
        json.append('cat', 'task');
        json.append('action', 'comment_add');
        json.append('id', ShortTask.activeTask);
        json.append('comment', text);
        if (Config.UserSideVersion.get() === Config.UserSide316)
            json.append('employee_id', User.employeeId);
        if (Config.UserSideVersion.get() === Config.UserSide315)
            json.append('operator_id', User.staffId);


        return await UserSideRequests.#sendRequest(UserSideRequests.#url, json);
    }

    async nodeGet(id)
    {
        let json = new FormData();
        json.append('key', Config.ApiKey.get());
        json.append('cat', 'node');
        json.append('action', 'get');
        json.append('id', id);

        return await UserSideRequests.#sendRequest(UserSideRequests.#url, json);
    }

    async nodeGetIdis(lat, lon, range)
    {
        let json = new FormData();
        json.append('key', Config.ApiKey.get());
        json.append('cat', 'node');
        json.append('action', 'get_id_by_coord');
        json.append('lat', lat);
        json.append('lon', lon);
        json.append('range', range);
        json.append('type', '4,1');

        return await UserSideRequests.#sendRequest(UserSideRequests.#url, json);
    }

    async taskGetCatalogType(typeId)
    {
        let json = new FormData();
        json.append('key', Config.ApiKey.get());
        json.append('cat', 'task');
        json.append('action', 'get_catalog_type');
        json.append('id', typeId);

        return await UserSideRequests.#sendRequest(UserSideRequests.#url, json);
    }

    async employeeGetDivisionsList()
    {
        let json = new FormData();
        json.append('key', Config.ApiKey.get());
        json.append('cat', 'employee');
        json.append('action', 'get_division_list');

        return await UserSideRequests.#sendRequest(UserSideRequests.#url, json);
    }

    async staffGetData(staffId)
    {
        let json = new FormData();
        json.append('key', Config.ApiKey.get());
        json.append('cat', 'staff');
        json.append('action', 'get_data');
        json.append('id', staffId);

        return await UserSideRequests.#sendRequest(UserSideRequests.#url, json);
    }

    async staffGetDivision(divisionId)
    {
        let json = new FormData();
        json.append('key', Config.ApiKey.get());
        json.append('cat', 'staff');
        json.append('action', 'get_division');
        json.append('id', divisionId);

        return await UserSideRequests.#sendRequest(UserSideRequests.#url, json);
    }

    async employeeGetDivision(divisionId)
    {
        let json = new FormData();
        json.append('key', Config.ApiKey.get());
        json.append('cat', 'employee');
        json.append('action', 'get_division');
        json.append('id', divisionId);

        return await UserSideRequests.#sendRequest(UserSideRequests.#url, json);
    }

    async taskGetListWithStaffId(staffId, dateDoFrom, dateDoTo, stateId)
    {
        let json = new FormData();
        json.append('key', Config.ApiKey.get());
        json.append('cat', 'task');
        json.append('action', 'get_list');
        json.append('staff_id', staffId);
        json.append('date_do_to', dateDoTo);
        json.append('order_by', User.filter.get());
        json.append('state_id', stateId);

        if (dateDoFrom !== '') json.append('date_do_from', dateDoFrom);

        return await UserSideRequests.#sendRequest(UserSideRequests.#url, json);
    }

    async taskGetListWithEmployeeId(employeeId, dateDoFrom, dateDoTo, stateId)
    {
        let json = new FormData();
        json.append('key', Config.ApiKey.get());
        json.append('cat', 'task');
        json.append('action', 'get_list');
        json.append('employee_id', employeeId);
        json.append('date_do_to', dateDoTo);
        json.append('order_by', User.filter.get());
        json.append('state_id', stateId);

        if (dateDoFrom !== '') json.append('date_do_from', dateDoFrom);

        return await UserSideRequests.#sendRequest(UserSideRequests.#url, json);
    }

    async taskGetListWithDivisionId(divisionId, dateDoFrom, dateDoTo, stateId)
    {
        let json = new FormData();
        json.append('key', Config.ApiKey.get());
        json.append('cat', 'task');
        json.append('action', 'get_list');
        json.append('division_id', divisionId);
        json.append('date_do_to', dateDoTo);
        json.append('order_by', User.filter.get());
        json.append('state_id', stateId);

        if (dateDoFrom !== '') json.append('date_do_from', dateDoFrom);

        return await UserSideRequests.#sendRequest(UserSideRequests.#url, json);
    }

    async operatorCheckPass(login, password)
    {
        let json = new FormData();
        json.append('key', Config.ApiKey.get());
        json.append('cat', 'operator');
        json.append('action', 'check_pass');
        json.append('login', login);
        json.append('pass', password);

        return await UserSideRequests.#sendRequest(UserSideRequests.#url, json);
    }

    async employeeCheckPass(login, password)
    {
        let json = new FormData();
        json.append('key', Config.ApiKey.get());
        json.append('cat', 'employee');
        json.append('action', 'check_pass');
        json.append('login', login);
        json.append('pass', password);

        return await UserSideRequests.#sendRequest(UserSideRequests.#url, json);
    }

    async operatorGetIdByLogin(login)
    {
        let json = new FormData();
        json.append('key', Config.ApiKey.get());
        json.append('cat', 'operator');
        json.append('action', 'get_id_by_login');
        json.append('login', login);

        return await UserSideRequests.#sendRequest(UserSideRequests.#url, json);
    }

    async employeeGetIdByLogin(login)
    {
        let json = new FormData();
        json.append('key', Config.ApiKey.get());
        json.append('cat', 'employee');
        json.append('action', 'get_employee_id');
        json.append('data_typer', 'login');
        json.append('data_value', login);

        return await UserSideRequests.#sendRequest(UserSideRequests.#url, json);
    }

    async operatorGetData(id)
    {
        let json = new FormData();
        json.append('key', Config.ApiKey.get());
        json.append('cat', 'operator');
        json.append('action', 'get');
        json.append('id', id);

        return await UserSideRequests.#sendRequest(UserSideRequests.#url, json);
    }

    async customerGetData(id)
    {
        let json = new FormData();
        json.append('key', Config.ApiKey.get());
        json.append('cat', 'customer');
        json.append('action', 'get_data');
        json.append('id', id);

        return await UserSideRequests.#sendRequest(UserSideRequests.#url, json);
    }

    async employeeGetData(id)
    {
        let json = new FormData();
        json.append('key', Config.ApiKey.get());
        json.append('cat', 'employee');
        json.append('action', 'get_data');
        json.append('id', id);

        return await UserSideRequests.#sendRequest(UserSideRequests.#url, json);
    }

    async addressGetHouse(id)
    {
        let json = new FormData();
        json.append('key', Config.ApiKey.get());
        json.append('cat', 'address');
        json.append('action', 'get_house');
        json.append('id', id);

        return await UserSideRequests.#sendRequest(UserSideRequests.#url, json);
    }

    async commutationGetDataCustomer(id)
    {
        let json = new FormData();
        json.append('key', Config.ApiKey.get());
        json.append('cat', 'commutation');
        json.append('action', 'get_data');
        json.append('object_type', 'customer');
        json.append('object_id', id);
        json.append('is_finish_data', '1');

        return await UserSideRequests.#sendRequest(UserSideRequests.#url, json);
    }

    async fiberGetList(id)
    {
        let json = new FormData();
        json.append('key', Config.ApiKey.get());
        json.append('cat', 'fiber');
        json.append('action', 'get_list');
        json.append('object_id', id);

        return await UserSideRequests.#sendRequest(UserSideRequests.#url, json);
    }

    async deviseGetDataSwitch(id)
    {
        let json = new FormData();
        json.append('key', Config.ApiKey.get());
        json.append('cat', 'device');
        json.append('action', 'get_data');
        json.append('object_type', 'switch');
        json.append('object_id', id);

        return await UserSideRequests.#sendRequest(UserSideRequests.#url, json);
    }

    async deviseGetDataOlt(id)
    {
        let json = new FormData();
        json.append('key', Config.ApiKey.get());
        json.append('cat', 'device');
        json.append('action', 'get_data');
        json.append('object_type', 'olt');
        json.append('object_id', id);

        return await UserSideRequests.#sendRequest(UserSideRequests.#url, json);
    }

    async getAbonGroupList()
    {
        let json = new FormData();
        json.append('key', Config.ApiKey.get());
        json.append('cat', 'module');
        json.append('request', 'get_user_group_list');

        return await UserSideRequests.#sendRequest(UserSideRequests.#url, json);
    }

    async getInventoryAmountCustomer(customerId)
    {
        let json = new FormData();
        json.append('key', Config.ApiKey.get());
        json.append('cat', 'inventory');
        json.append('action', 'get_inventory_amount');
        json.append('location', 'customer');
        json.append('object_id', customerId);

        return await UserSideRequests.#sendRequest(UserSideRequests.#url, json);
    }

    async inventoryGet(id)
    {
        let json = new FormData();
        json.append('key', Config.ApiKey.get());
        json.append('cat', 'inventory');
        json.append('action', 'get_inventory');
        json.append('id', id);

        return await UserSideRequests.#sendRequest(UserSideRequests.#url, json);
    }

    async taskGetRelatedTasks(taskId)
    {
        let json = new FormData();
        json.append('key', Config.ApiKey.get());
        json.append('cat', 'task');
        json.append('action', 'get_related_task_id');
        json.append('id', taskId);

        return await UserSideRequests.#sendRequest(UserSideRequests.#url, json);
    }

    async operatorGet(id)
    {
        let json = new FormData();
        json.append('key', Config.ApiKey.get());
        json.append('cat', 'operator');
        json.append('action', 'get');
        json.append('id', id);

        return await UserSideRequests.#sendRequest(UserSideRequests.#url, json);
    }

    async getPonPowerHistory(limit, name)
    {
        let json = new FormData();
        json.append('key', Config.ApiKey.get());
        json.append('cat', 'device');
        json.append('action', 'get_pon_level_history');
        json.append('limit', limit);
        json.append('order_by', 'date_from');
        json.append('onu_name', name);
        json.append('is_desc', '1');

        return await UserSideRequests.#sendRequest(UserSideRequests.#url, json);
    }

    static async #sendRequest(url, json)
    {
        try
        {
            const response = await axios.post(url, json, { headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' } });

            let data = {};

            json.forEach(function (key, value)
            {
                data[value] = key;
            });

            console.log(`url - ${url}\n\njson - ${JSON.stringify(data)}\nresponse - ${JSON.stringify(response.data)}`);

            return response.data;
        }
        catch (e)
        {
            return '';
        }
    }
}
export {UserSideRequests};
