import React from "react";
import {Form, Modal} from "react-bootstrap";
import {Button} from "react-bootstrap";
import {useState} from "react";
import {render} from "react-dom";
import {getString, strings} from "../../Languages/Languages";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";

function MyVerticallyCenteredModal(props)
{
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    <FontAwesomeIcon icon={faInfoCircle} className={"DialogIcon"}/>
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className={"DialogBody"}>
                    {props.body}
                </p>
                {props.ifvisible === 'true' &&
                    <Form id={"dialogForm"}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Control name={"dialogIF"} defaultValue={props.defifval} className={"LoginIFBox"} type="email" placeholder={getString(strings.KeyPrillMask)} />
                        </Form.Group>
                    </Form>}
            </Modal.Body>
            <Modal.Footer>
                {props.nebtn === 'true' && <Button onClick={ () => { props.nebtnclk(); props.onHide(); } }>{props.nebtntitle}</Button>}
                {props.nbtn === 'true' && <Button onClick={ () => { props.nbtnclk(); props.onHide(); } }>{props.nbtntitle}</Button>}
                {props.pbtn === 'true' && <Button onClick={ () => { props.pbtnclk(); props.onHide(); } }>{props.pbtntitle}</Button>}
            </Modal.Footer>
        </Modal>
    );
}

function GetDialog(props) {
    const [modalShow, setModalShow] = useState(true);

    return(
        <>
            <MyVerticallyCenteredModal
                title={props.title}
                body={props.body}
                pbtnclk={ (() => props.pbtnclk()) }
                nbtnclk={ (() => props.nbtnclk()) }
                nebtnclk={ (() => props.nebtnclk()) }
                pbtn={props.pbtn} nbtn={props.nbtn} nebtn={props.nebtn}
                pbtntitle={props.pbtntitle} nbtntitle={props.nbtntitle} nebtntitle={props.nebtntitle}
                ifvisible={props.ifvisible}
                defifval={props.defifval}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>);
}

function ShowSimpleDialog(title, body) //модальное окно по в маине
{
    render (<GetDialog title={title} body={body}
                       pbtn={'true'} nbtn={'false'} nebtn={'false'}
                       pbtnclk={() => {}} nbtnclk={() => {}} nebtnclk={() => {}}
                       pbtntitle={'Ok'} nbtntitle={''} nebtntitle={''}
                       ifvisible={'false'}/>,
        document.createDocumentFragment());
}
export {ShowSimpleDialog};

function ShowDialog(options) //окно в логине
{
    render (<GetDialog title={options['title']} body={options['body']}
                        pbtn={options['pbtn']} nbtn={options['nbtn']} nebtn={options['nebtn']}
                        pbtnclk={() => options['pButtonClick']()} nbtnclk={() => options['nButtonClick']()} nebtnclk={() => options['neButtonClick']()}
                        pbtntitle={options['pbtntitle']} nbtntitle={options['nbtntitle']} nebtntitle={options['nebtntitle']}
                        ifvisible={options['ifvisible']} defifval={options['defifval']}/>,
        document.createDocumentFragment());
}
export {ShowDialog};




