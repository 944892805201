import React from 'react';
import backArrow from "../Resources/Icons/back-arrow.svg";
import downChevron from "../Resources/Icons/down-chevron.svg";
import downloadIcon from "../Resources/Icons/download.svg";
import {User} from "../User/User";
import {ServerApi} from "../Server/ServerApi";

let commentsAlreadyDownloaded = false;

function SupportsAdd() {
    const serverApi = new ServerApi();
    serverApi.getProvince().then((response) => {
        console.log(response)
        for (let key in response) {
            if(response[key].parent_id == null) {
                let name = response[key].name
                let ID = response[key].id

                let div = document.getElementById('regionId');
                div.innerHTML += `<div class="mal">
                                   <div class="clearfix">
                                   <a data-toggle="collapse"  href="#collapseExample${ID}"  role="button" class="text-decoration-none" 
                                   aria-expanded="false" aria-controls="collapseExample${ID}">
                                   <div class="input-group mb-0">
                                   <input type="text" data-name=${ID} class="bg-primary text-light form-control smallText a" value=${name} disabled/>
                                   <div class="input-group-append">
                                   <span class="input-group-text">
                                   <img src=${downChevron} alt=${downloadIcon} width="20px" data-name=${ID} />
                                   </span></div></div></a></div>
                                   <div class="collapse" id="collapseExample${ID}">
                                   <div id='areaId${ID}' class="card card-body"></div></div></div>`
                //  }
                let handleClick = (event) => {
                    if (event.target.dataset.name != undefined) {
                        let len = event.target.dataset.name
                        Area(len)
                    }
                }
                let area = document.querySelectorAll('.mal')
                area.forEach(area => {
                    area.addEventListener('click', handleClick)
                })
            }
            }

    })
        .catch((error) => {
            console.log(error)
        })

    return (
        <div>
            <div>
                <div className="col-sm-12 my-2" id={'regionId'}>
                </div>
            </div>
            <nav className="navbar fixed-bottom navbar-light bg-white shadow-lg">
                <div className="float-left">
                    <a id={"backBtn"} onClick={() => {
                        window.history.back();
                        commentsAlreadyDownloaded = false;
                    }}
                       className="navbar-brand text-primary mx-2"><img src={backArrow} alt={backArrow} width={"25px"}/></a>
                </div>
                <div className="float-right text-right">
                    <a type="button"
                       className="navbar-brand text-primary mr-2" href="#">
                    </a>
                </div>
            </nav>
        </div>
    );
}

export default SupportsAdd;

function Area(len) {
    const serverApi = new ServerApi();
    let areaRemove = document.getElementById('areaId' + len)
    if (areaRemove != null) {
        while (areaRemove.firstChild) {
            areaRemove.removeChild(areaRemove.lastChild)
        }
    }

    serverApi.getDistrict().then((response) => {
        for (let key in response) {
            if (response[key].parent_id == len) {
                let val = response[key].name
                let Id = response[key].id

                let div = document.getElementById('areaId' + len);
                div.innerHTML += `<div class="mals">
                                   <div class="clearfix" >
                                   <a data-toggle="collapse"  href="#collapseExample${Id}"  role="button" class="text-decoration-none" 
                                   aria-expanded="false" aria-controls="collapseExample${Id}">
                                   <div class="input-group mb-0">
                                   <input type="text" data-nam=${Id} class="bg-primary text-light form-control smallText" value=${val} disabled/>
                                   <div class="input-group-append">
                                   <span class="input-group-text">
                                   <img src=${downChevron} alt=${downloadIcon} width="20px" data-nam=${Id} />
                                   </span></div></div></a></div>
                                   <div class="collapse" id="collapseExample${Id}">
                                   <div id="areaId${Id}" class="card card-body"></div></div></div>`

                let handleClicks = (event) => {
                    let lens = event.target.dataset.nam
                    Locality(lens)
                }

                let locality = document.querySelectorAll('.mals')
                locality.forEach(locality => {
                    locality.addEventListener('click', handleClicks)
                })

            }
        }
    })
}

function Locality(lens) {
    const serverApi = new ServerApi();
    let localityRemove = document.getElementById('areaId' + lens)
    if (localityRemove != null) {
        while (localityRemove.firstChild) {
            localityRemove.removeChild(localityRemove.lastChild)
        }
    }

    serverApi.getCity(lens).then((response) => {
        for (let key in response) {
            let name = response[key].name
            let id = response[key].id

            let div = document.getElementById('areaId' + lens);
            div.innerHTML += `<button data-ids=${id} class="KnotButton">${name}</button>`

            let handleClick = (event) => {
                let ids = parseInt(event.target.dataset.ids)
                for (let key in response) {
                    if (response[key].id === ids) {
                        if (response[key].coordinates !== undefined) {
                            let coordinates = response[key].coordinates.coordinates
                            console.log(coordinates)
                            User.coordinates = coordinates
                            window.open("/supports/add/map", '_self', false);
                        }
                        if (response[key].coordinates === undefined) {
                            //  window.open("/supports/add/map", '_self', false);
                        }
                    }
                }
            }

            let localityClick = document.querySelectorAll('.KnotButton')
            localityClick.forEach(localityClick => {
                localityClick.addEventListener('click', handleClick)
            })
        }
    })
        .catch((error) => {
            console.log(error)
        })
}

function LocalityClick() {
}




