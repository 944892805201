import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faCrown, faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import {decodeHtmlCharCodes} from "../../Utils/String/StringUtils";
import {getString, strings} from "../../Languages/Languages";
import {ShowSimpleDialog} from "../../Utils/Dialog/Dialog";
import {GetAboutTaskForm} from "../AboutTaskForm";
import React from "react";
import {reactLocalStorage} from "reactjs-localstorage";

//props['Customer']  {GetNameCustomer(props)}

function openAboutTask(props)
{
    GetAboutTaskForm(props).then(() => {});
}


function GetNameCustomer (props, size){

        let FIO = props['Customer'];
        let reduction = '...'

        if (FIO.length > 0) {
             for (let i = 0; i < FIO.length; i++){
                 if(FIO[i].Fio.length > size) {
                     let redactText = FIO[i].Fio;
                     redactText = redactText.substr(0, size);
                     FIO[i].Fio = redactText + reduction;
                 }
             }
            return FIO.map((FIO) => <li>{FIO.Fio}</li>);
        }
        else
        {
            return FIO.Fio;
        }
}

const getColor = (item) => {
    return reactLocalStorage.get('color_' + item, '')
}
    //TODO: сделать загрузку побыстрей
function GetShortTaskTemplate(props)
{
    let tagIdCollapse = 'collapse' + props['Id'];
    let tagIdCollapseSharp = '#collapse' + props['Id'];
    let color = '0d6efd'
    if(getColor(props['Type'])) {
        color = getColor(props['Type']);
    }
    return(
        <>
            <div id={props['Id']} className="shadow-sm mx-3 mb-3 bg-white rounded clearfix" key={props['Id']}>
                <div className="blockTitleTask p-2 clearfix">
                    <div className="float-left text-primary">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <p onClick={() =>
                        {
                            if (props['TypeDescription'] === '')
                            {
                                ShowSimpleDialog(getString(strings.TaskTypeDescription), getString(strings.TaskTypeDescriptionNone));
                            }
                            else
                            {
                                ShowSimpleDialog(getString(strings.TaskTypeDescription), props['TypeDescription']);
                            }
                        }} className={"LoginSettingsBtnHolder"}>
                            <FontAwesomeIcon className={"taskQM"} color={color} icon={faQuestionCircle}/>
                        </p>
                    </div>
                        <div className="float-left px-2 text-nowrap"><h5 className="mb-0">{decodeHtmlCharCodes(props['Type'])}</h5>
                    </div>
                    <div className="float-right"><h5 className="mb-0" style={{color: color}}>{props['Id']}</h5></div>
                </div>



                <div className="p-2">
                    {/*<div onClick={() => openAboutTask(props)} className="clearfix">*/}
                    <div
                        onClick={() => {window.open("/about/" + props["Id"], '_self', false)}} className="clearfix">
                        <div className="float-left float-leftTemplate"><strong>{[GetNameCustomer(props, 25)]}</strong></div>
                        <div className="float-right">{props['Time'] + '(' + props['TimeForDoing'] + getString(strings.Hour) + ')'}</div>
                    </div>
                    {/*<div onClick={() => openAboutTask(props)} className="clearfix">*/}
                    <div onClick={() => {window.open("/about/" + props["Id"], '_self', false)}} className="clearfix">
                        <div className="float-left"><strong>{decodeHtmlCharCodes(props['Address'])}</strong></div>
                        <div className="float-right">
                            {props['Sla'] === true && props['SlaTime'] === true && <span className="badge badge-secondary">SLA</span>}
                            {props['Sla'] === true && props['SlaTime'] === false && <span className="badge badge-danger">SLA</span>}
                        </div>
                    </div>
                    {/*<div onClick={() => openAboutTask(props)} className="my-2">*/}

                    <div onClick={() => {window.open("/about/" + props["Id"], '_self', false)}} className="my-2">
                        <div className="text-left textwrap">{decodeHtmlCharCodes(props['Description'])}</div>
                    </div>


                    <div className="clearfix">
                        <div className="float-left text-success"><strong>{decodeHtmlCharCodes(props['Price'])}</strong></div>
                        <a data-toggle="collapse" href={tagIdCollapseSharp} role="button"
                           aria-expanded="false" aria-controls="collapseExample">
                            <div className="text-primary float-right h6"><FontAwesomeIcon icon={faChevronDown}/></div>
                            <div className="text-primary float-right h6 mr-2">{getString(strings.Staffs)}</div>
                        </a>
                    </div>


                    <div className="collapse" id={tagIdCollapse}>
                        <div className="card card-body">{props['Staff']}</div>
                    </div>


                </div>
                {props['Priority'] === false &&
                    // <div onClick={() => openAboutTask(props)} className="blockBottomTask rounded-bottom p-2 ">

                    <div onClick={() => {window.open("/about/" + props["Id"], '_self', false)}} className="blockBottomTask rounded-bottom p-2 " style={{background: color}}>
                        <h6 className="mb-0">{props['Status']}</h6>
                    </div>

                }
                {props['Priority'] === true &&
                    // <div onClick={() => openAboutTask(props)} className="blockBottomTaskD rounded-bottom p-2 ">
                    <div onClick={() => {window.open("/about/" + props["Id"], '_self', false)}} className="blockBottomTaskD rounded-bottom p-2 " style={{background: color}}>
                        <h6 className="mb-0">{props['Status']} <FontAwesomeIcon className={"pt-1"} style={{ fontSize: 15, float: 'right' }} icon={faCrown}/></h6>
                    </div>
                }
            </div>
        </>
    );
}
export {GetShortTaskTemplate};
