import React from "react";
import taskIdIcon from '../../../../Resources/Icons/AboutTask/list.png';
import copyIcon from '../../../../Resources/Icons/copy.svg';
import copyToClipBoard from "../../functions/copyToClipBoard";

export default function TaskNumber(props) {

  return (
    <div className="col-sm-6 col-6 px-1">
      <label className="float-left text-primary mb-0">{props.taskNumber}</label>
      <div className="input-group mb-3">
        <div className="input-group-prepend">
          <span className="input-group-text bg-primary"><img src={taskIdIcon} alt={taskIdIcon} width="20px" /></span>
        </div>
        <input type="text" className="bg-light form-control smallText"
          defaultValue={props.id} disabled />
        <div className="input-group-append" onClick={() => copyToClipBoard(props.id)}>
          <span className="input-group-text"><img src={copyIcon} alt={copyIcon} width="20px" /></span>
        </div>
      </div>
    </div>
  )
}