import downChevron from '../../../../Resources/Icons/down-chevron.svg';
import downloadIcon from '../../../../Resources/Icons/download.svg';

import { ServerApi } from "../../../../Server/ServerApi";

export default function Objects(props) {
  let serverApi = new ServerApi();

  async function f() {
    // let base = await serverApi.getDivisionList();
    console.log('!!!!!!!!!!!!!!!!!!' + process.env.REACT_APP_API)
  }

  return (
    <div className="col-sm-12 my-2">
      <div className="clearfix">
        <a data-toggle="collapse"
          href="#collapseExample"
          role="button" className={"text-decoration-none"} aria-expanded="false" aria-controls="collapseExample">
          <div className="input-group mb-0">
            <input type="text" className="bg-primary text-light form-control smallText" defaultValue={props.taskObjects} disabled />
            <div className="input-group-append">
              <span className="input-group-text"><img src={downChevron} alt={downloadIcon} width="20px" /></span>
            </div>
          </div>
        </a>
      </div>
      <div className="collapse" id="collapseExample">
        <div id={'objectsID'} className="card card-body">
        </div>
      </div>
    </div>
  )
}