import React from "react";
import {Modal} from "react-bootstrap";
import {Button} from "react-bootstrap";
import {useState} from "react";
import {render} from "react-dom";
import {getString, strings} from "../../Languages/Languages";
import {ServerApi} from "../../Server/ServerApi";

function featureLayerLabels(){
    let serverApi = new ServerApi();
    serverApi.markshow().then((response) => {
        console.log(response)
        for (let aaaName in response.data){
            console.log(response.data[aaaName].name)
            let name = response.data[aaaName].name
            let id = response.data[aaaName].id

            let selectName = document.querySelector('.dropdown-menu')
            selectName.innerHTML += `<a class="dropdown-item SupportSize" data-value="dropdown-item">${name}</a>`
        }

        document.addEventListener("click", function (e) {
            if (e.target.dataset.value === "dropdown-item") {
                console.log('hello')
                document.querySelector("#dropdownMenuButton").innerHTML = e.target.textContent;

            }
        });
    })
        .catch((error) => {
            console.log(error)
        })
}


function SupportsMap(props)
{
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <textarea id={"commentText"} className="SupportsMapTextarea" placeholder={getString(strings.Сomment)}/>
                <p/>
                <div className="dropdown">
                    <button onClick={() => featureLayerLabels()}
                            className="btn btn-secondary dropdown-toggle SupportSize" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    </div>
                </div>
                <br/> <br/>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={ () => props.onHide() } type="button" className="btn btn-dangerCancelTask" >{getString(strings.Cancel)}</button>
                <Button onClick={ () => {props.onHide(); props.onApplyclick()}} className="btn btn-dangerCloseTask" >{getString(strings.Save)}</Button>
            </Modal.Footer>
        </Modal>
    );
}

function GetDialog(props) {
    const [modalShow, setModalShow] = useState(true);

    return(
        <>
            <SupportsMap
                onApplyclick={props.onApplyclick}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>);
}

function ShowSupportsMap(onApplyClick)
{
    render (<GetDialog onApplyclick={onApplyClick}/>, document.createDocumentFragment());
}
export {ShowSupportsMap};