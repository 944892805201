import {ServerRequests} from './ServerRequests';
import {User} from "../User/User";

class ServerApi {
    licenseType =
        [
            "Получите лицензию!",
            "Демо период закончился. Купите лицензию.",
            "Достигнуто максимальное количество установок приложения",
            "Разрешено."
        ];

    constructor() {
        this.requests = new ServerRequests();
    }

//Получение изображения
    async getImage(id) {

        try {
            let images1 = JSON.parse(JSON.stringify(await ServerRequests.getImage1(id)));
            return {
                "res": false,
                "Full": images1['Full'],
                "Low": images1['Low'],
                "comment": images1['comment'],
            };
        } catch (e) {
            return e
        }

    }

    async checkLicense(deviceId, login, keyPrill) {
        return await this.requests.checkLicense(deviceId, login, keyPrill);
    }

    async checkAllow(deviceId, login, keyPrill) {
        let response = await this.requests.checkAllow(deviceId, login, keyPrill);
        return response.toString() === '1';
    }

//Загрузка изображения в папку pril/image
    async uploadImage(imageBase, taskId, comment) {
        try {
            let responseData = JSON.parse(JSON.stringify(
                await this.requests.uploadImage(imageBase, taskId, comment)
            ));
            return responseData
        } catch (e) {
            return null;
        }
    }

//Загрузка изображения для закрытия задания(автоматически прикрепляется комментарий)
    async uploadClosedTaskImage(imageBase, fieldId, comment) {
        try {
            let responseData = JSON.parse(JSON.stringify(
                await this.requests.uploadClosedTaskImage(imageBase, fieldId, comment)
            ));
            return responseData
        } catch (e) {
            return null;
        }
    }

    async getNodeScheme(nodeId) {
        let response = await this.requests.getNodeScheme(nodeId);
        return response;
    }

//Доваление комментария к заданию
    async addComment(comm) {
        return await this.requests.addComment(comm);
    }

//Добавление фото к комментарию (addComment+addImageToComment)
    async addImageToComment(image, commentId) {
        try {
            let responseData = JSON.parse(JSON.stringify(
                await this.requests.addImageToComment(image, commentId)
            ));
            return responseData
        } catch (e) {
            return null;
        }
    }

    async getTaskList(divisionId, employeeId, keyPrill, dateDoTo, dateDoFrom) {
        try {
            let responseData = JSON.parse(JSON.stringify(
                await ServerRequests.getMainTasks(divisionId, employeeId, keyPrill, dateDoTo, dateDoFrom)
            ));

            if (responseData["success"] === false)
                return {
                    "res": false,
                    "list": "",
                    "tasks": "",
                };

            return {
                "res": true,
                "list": responseData["list"],
                "tasks": responseData["tasks"],
            }
        } catch (e) {
            return {
                "res": false,
                "list": "",
                "tasks": "",
            };
        }
    }

    async getFilterTaskList(divisionId, employeeId, keyPrill, dateDoTo, dateDoFrom, order_by) {
        try {
            let responseData = JSON.parse(JSON.stringify(
                await ServerRequests.getFilterTaskList(divisionId, employeeId, keyPrill, dateDoTo, dateDoFrom, order_by)
            ));

            if (responseData["success"] === false)
                return {
                    "res": false,
                    "list": "",
                    "tasks": "",
                };

            return {
                "res": true,
                "list": responseData["list"],
                "tasks": responseData["tasks"],
            }
        } catch (e) {
            return {
                "res": false,
                "list": "",
                "tasks": "",
            };
        }
    }



    async getMonthEmployeeList(employee_id, date_do_from, key_pril) {
        try {
            return JSON.parse(JSON.stringify(
                await ServerRequests.getMonthEmployeeList(employee_id, date_do_from, key_pril)
            ))

        } catch (e) {
            return false
        }
    }

    async GetEmployeeDataAll(key_pril) {
        try {
            return JSON.parse(JSON.stringify(
                await ServerRequests.GetEmployeeDataAll(key_pril)
            ))

        } catch (e) {
            return false
        }
    }

    async getMonthDivisionPerformed(division_id, date_do_from, key_pril, state_id) {
        try {
            return JSON.parse(JSON.stringify(
                await ServerRequests.getMonthDivisionPerformed(division_id, date_do_from, key_pril, state_id)
            ))

        } catch (e) {
            return false
        }
    }


    async getTaskListType(employeeId) {
        try {
            return JSON.parse(JSON.stringify(
                await ServerRequests.getTaskListType(employeeId)
            ))

        } catch (e) {
            return false
        }
    }

    async getTaskState(employeeId) {
        try {
            return JSON.parse(JSON.stringify(
                await ServerRequests.getTaskState(employeeId)
            ))

        } catch (e) {
            return false
        }
    }


    async getTaskListState(divisionId, employeeId, keyPrill, dateDoTo, dateDoFrom, stateId) {
        try {

            let responseData = JSON.parse(JSON.stringify(
                await ServerRequests.getMainTaskState(divisionId, employeeId, keyPrill, dateDoTo, dateDoFrom, stateId)
            ));


            if (responseData["success"] === false)
                return {
                    "res": false,
                    "list": "",
                    "tasks": "",
                };

            return {
                "res": true,
                "list": responseData["list"],
                "tasks": responseData["tasks"],
            }
        } catch (e) {
            return {
                "res": false,
                "list": "",
                "tasks": "",
            };
        }
    }

    async getTaskListFinishState(employeeId, keyPrill, dateFinishFrom, dateFinishTo, stateId) {
        try {

            return JSON.parse(JSON.stringify(
                await ServerRequests.getTaskListFinishState(employeeId, keyPrill, dateFinishFrom, dateFinishTo, stateId)
            ))
        } catch (e) {
            return false;
        }
    }

//Получение информации о задании??
    async getTaskData(customerId, addressId, taskId, relatedObjects) {
        try {
            let responseData = JSON.parse(JSON.stringify(
                await ServerRequests.getAboutData(customerId, addressId, User.keyPril, taskId, relatedObjects)
            ));
            return {
                "abonData": responseData["abonData"],
                "otherData": responseData["otherData"],
                "addressData": responseData["addressData"],
                "networkData": responseData["networkData"],
            }
        } catch (e) {
            return {
                "abonData": [],
                "otherData": [],
                "addressData": [],
                "networkData": [],
            };
        }
    }

    // Отделы
    async getDivisionList() {
        try {
            let responseData = JSON.parse(JSON.stringify(
                await ServerRequests.getDivisionList()
            ));
            return responseData
        } catch (e) {
            return null;
        }
    }

    async getDivision(id) {
        try {
            return JSON.parse(JSON.stringify(
                await ServerRequests.getDivision(id)
            ))
        } catch (e) {
            return null;
        }
    }


//Создание задания
    async createTask(date, opis, taskId) {
        try {
            let responseData = JSON.parse(JSON.stringify(
                await this.requests.createTask(date, opis, taskId)
            ));
            return responseData === "Задание успешно создано";
        } catch (e) {
            return false;
        }
    }

//Координаты абонента
    async getAboonCoords(id) {
        try {
            // отправка запроса и получение ответа от сервера
            let responseData = JSON.parse(JSON.stringify(
                await this.requests.getAbonCoords(id)
            ));
            //выполнение обработки ответа
            if (responseData == null) return false;
            return responseData;
        } catch (e) {
            return false;
        }
    }

    async getNodeId(nodeId) {
        try {
            let responseData = JSON.parse(JSON.stringify(
                await ServerRequests.getUzelCoords(nodeId)
            ));

            return responseData
        } catch (e) {
            return null;
        }
    }

//Создание задания
    async createTaskDivision(date, opis, taskId, divisionId) {
        try {
            let responseData = JSON.parse(JSON.stringify(
                await this.requests.createTaskDivision(date, opis, taskId, divisionId)
            ));
            return responseData === "Задание успешно создано";
        } catch (e) {
            return false;
        }
    }

    //Уровень сигнала Onu
    async getRxLevel(customerId, limit) {
        try {
            let responseData = JSON.parse(JSON.stringify(await ServerRequests.getRx(customerId, limit)));
            if (responseData[0] === []) return undefined;
            return responseData;
        } catch (e) {
            return undefined;
        }
    }

    //Уровень сигнала Olt
    async getRxOltLevel(customerId, limit) {
        try {
            let responseData = JSON.parse(JSON.stringify(await ServerRequests.getOltRx(customerId, limit)));
            if (responseData[0] === []) return undefined;
            return responseData;
        } catch (e) {
            return undefined;
        }
    }

//ID связанного задания
    async GetRTID(taskId) {
        try {
            let responseData = JSON.parse(JSON.stringify(await ServerRequests.GetRelatedTaskId(taskId)));
            if (responseData[0] === []) return undefined;
            return responseData;
        } catch (e) {
            return undefined;
        }
    }

    //!!!!!!!
    async GetOD(id) {
        try {
            let responseData = JSON.parse(JSON.stringify(await ServerRequests.GetOntData(id)));
            if (responseData[0] === []) return undefined;
            return responseData;
        } catch (e) {
            console.log(e);
            return undefined;
        }
    }

    async GetShow(id) {
        try {
            //let responseData = JSON.parse(JSON.stringify(await ServerRequests.GetShowTask(id)));
            let responseData = JSON.parse(JSON.stringify(
                await ServerRequests.GetShowTask(id)
            ));
            if (responseData['list']['success'] === false)
                return {
                    "res": false,
                    "list": "",
                    "tasks": "",
                };
            console.log(responseData['list']['success'])
            return {
                "res": true,
                "list": responseData["list"],
                "tasks": responseData["tasks"],
            }
        } catch (e) {
            return {
                "res": false,
                "list": "",
                "tasks": "",
            };
        }
    }

    async ShowEmployee(id) {
        try {
            let responseData = JSON.parse(JSON.stringify(await ServerRequests.GetShowEmployee(id)));
            if (responseData[0] === []) return undefined;
            return responseData;
        } catch (e) {
            console.log(e);
            return undefined;
        }
    }

    async GETWEList(id) {
        try {
            let responseData = JSON.parse(JSON.stringify(await ServerRequests.GETWatcherEmployeeList(id)));
            if (responseData[0] === []) return undefined;
            return responseData;
        } catch (e) {
            console.log(e);
            return undefined;
        }
    }


    //get_allow_staff - Список исполнителей
    async get_allow_staff(id) {
        try {
            let responseData = JSON.parse(JSON.stringify(await ServerRequests.get_allow_staff(id)));
            if (responseData[0] === []) return undefined;
            return responseData;
        } catch (e) {
            console.log(e);
            return undefined;
        }
    }

    //добавить наблюдателя
    async add_employee(id, employee_id) {
        try {
            let responseData = JSON.parse(JSON.stringify(await ServerRequests.employee_add(id, employee_id)));
            if (responseData[0] === []) return undefined;
            return responseData;
        } catch (e) {
            console.log(e);
            return undefined;
        }
    }

    //удалить наблюдателя
    async del_employee(id, employee_id) {
        try {
            let responseData = JSON.parse(JSON.stringify(await ServerRequests.employee_del(id, employee_id)));
            if (responseData[0] === []) return undefined;
            return responseData;
        } catch (e) {
            console.log(e);
            return undefined;
        }
    }

    async list_lines(id) {
        try {
            let responseData = JSON.parse(JSON.stringify(await ServerRequests.list_line(id)));
            if (responseData[0] === []) return undefined;
            return responseData;
        } catch (e) {
            console.log(e);
            return undefined;
        }
    }


    //Получение список типов сооружения связи (get_type_list)
    async getTypeList() {
        try {
            let responseData = JSON.parse(JSON.stringify(
                await ServerRequests.gettypelist()
            ));

            return responseData
        } catch (e) {
            return null;
        }
    }

    async getCatalogType() {
        try {
            let responseData = JSON.parse(JSON.stringify(
                await ServerRequests.getCatalogType()
            ));

            return responseData
        } catch (e) {
            return null;
        }
    }

    //Изменение статуса задания (с "В работе" на "На проверке")
    async closeTask(id, state_id) {
        try {
            let responseData = JSON.parse(JSON.stringify(
                await ServerRequests.closeTask(id, state_id)
            ));
            console.log(responseData)
            return responseData
        } catch (e) {
            return null;
        }
    }

    //!Временно Получение дополнительных полей задания
    async closeTaskAditional(type_id) {
        try {
            return JSON.parse(JSON.stringify(
                await ServerRequests.closeTaskAditional(type_id)
            ))
        } catch (e) {
            return null;
        }
    }


    //Получение значения дополнительного поля задания
    async getAdditionalFieldValue(field_id) {
        try {
            let responseData = JSON.parse(JSON.stringify(
                await ServerRequests.getAdditionalFieldValue(field_id)
            ));
            console.log(responseData)
            return responseData
        } catch (e) {
            return null;
        }
    }

    //!Временно Изменение значений дополнительных полей задания
    async changeAdditionalFieldValue(sendObj) {
        try {
            return JSON.parse(JSON.stringify(
                await ServerRequests.changeAdditionalFieldValue(sendObj)
            ))
        } catch (e) {
            return null;
        }
    }


    async get_id_by_coord(lat, lon, range, type) {
        try {
            return JSON.parse(JSON.stringify(
                await ServerRequests.getIdByCoord(lat, lon, range, type)
            ))
        } catch (e) {
            return null;
        }
    }

    async getInfras(id) {
        try {
            let responseData = JSON.parse(JSON.stringify(
                await ServerRequests.getInfraS(id)
            ));
            return responseData
        } catch {
            return null;
        }
    }

    async getInfrasAddress(id) {
        try {
            return JSON.parse(JSON.stringify(
                await ServerRequests.getInfraSAddress(id)
            ))
        } catch {
            return null;
        }
    }

    async getCustomerData(id) {
        try {
            return JSON.parse(JSON.stringify(
                await ServerRequests.getCustomerData(id)
            ))
        } catch {
            return null;
        }
    }

    async markshow() {
        try {
            return JSON.parse(JSON.stringify(
                await ServerRequests.markShow()
            ))
        } catch (e) {
            return null;
        }
    }

    async nodegetmark(markId) {
        try {
            return JSON.parse(JSON.stringify(
                await ServerRequests.NodeGetMark(markId)
            ))
        } catch (e) {
            return null;
        }
    }

    async attachget(id) {
        try {
            return JSON.parse(JSON.stringify(
                await ServerRequests.attachGet(id)
            ))
        } catch (e) {
            return null;
        }
    }

    //Добавление объекта
    async nodeadd(comment, coordinates) {
        try {
            let responseData = JSON.parse(JSON.stringify(await ServerRequests.nodeAdd(comment, coordinates)));
            if (responseData[0] === []) return undefined;
            return responseData;
        } catch (e) {
            console.log(e);
            return undefined;
        }
    }

    // Добавление метки
    async nodeAddmark(nodeId, markId) {
        try {
            let responseData = JSON.parse(JSON.stringify(await ServerRequests.nodeAddMark(nodeId, markId)));
            if (responseData[0] === []) return undefined;
            return responseData;
        } catch (e) {
            console.log(e);
            return undefined;
        }
    }

    async get_file(id) {
        try {
            return JSON.parse(JSON.stringify(
                await ServerRequests.getFile(id)
            ))
        } catch (e) {
            return null;
        }
    }

    async getinfrasAddress(id) {
        try {
            let responseData = JSON.parse(JSON.stringify(
                await ServerRequests.getinfrasaddress(id)
            ));
            return responseData
        } catch {
            return null;
        }
    }

    //Получение наименований каталога ТМЦ
    async getinventorysectioncatalog() {
        try {
            return JSON.parse(JSON.stringify(
                await ServerRequests.getInventorySectionCatalog()
            ))
        } catch (e) {
            return null;
        }
    }

//Address
    async getProvince() {
        try {
            return JSON.parse(JSON.stringify(
                await ServerRequests.getProvince()
            ));
        } catch (e) {
            console.log(e)
            return null;
        }
    }

    async getDistrict() {
        try {
            return JSON.parse(JSON.stringify(
                await ServerRequests.getDistrict()
            ));
        } catch (e) {
            console.log(e)
            return null;
        }
    }

    async getCity(id) {
        try {
            return JSON.parse(JSON.stringify(
                await ServerRequests.getCity(id)
            ));
        } catch (e) {
            console.log(e)
            return null;
        }
    }

    async getinventorycatalog() {
        try {
            return JSON.parse(JSON.stringify(
                await ServerRequests.getInventoryCatalog()
            ))
        } catch (e) {
            return null;
        }
    }

    async getInventoryamount(objectId) {
        try {
            return JSON.parse(JSON.stringify(
                await ServerRequests.getInventoryAmount(objectId)
            ))
        } catch (e) {
            return null;
        }
    }

    //Координаты Офиса
    async getOficeCoord() {
        try {
            return JSON.parse(JSON.stringify(
                await ServerRequests.getOficeCoord()
            ))
        } catch (e) {
            return null;
        }
    }

    //Добавление/изменение/удаление записи в табель работ
    async setTimesheetData(employee_id, date, type_id, value) {
        try {
            return JSON.parse(JSON.stringify(
                await ServerRequests.setTimesheetData(employee_id, date, type_id, value)
            ))
        } catch (e) {
            return null;
        }
    }

    //Получение информации о рабочем времени сотрудников
    async getWorkTimeData(date_from, date_to, employee_id) {
        try {
            return JSON.parse(JSON.stringify(
                await ServerRequests.getWorkTimeData(date_from, date_to, employee_id)
            ))
        } catch (e) {
            return null;
        }
    }

    //Фиксация момента начала рабочего времени сотрудника
    async workTimeStart(employee_id, date, comment) {
        try {
            return JSON.parse(JSON.stringify(
                await ServerRequests.workTimeStart(employee_id, date, comment)
            ))
        } catch (e) {
            return null;
        }
    }

//Фиксация момента окончания рабочего времени сотрудника
    async workTimeFinish(employee_id, date, comment) {
        try {
            return JSON.parse(JSON.stringify(
                await ServerRequests.workTimeFinish(employee_id, date, comment)
            ))
        } catch (e) {
            return null;
        }
    }

    async customerGetData(id){
        try {
            return JSON.parse(JSON.stringify(
                await ServerRequests.CustomerGetData(id)
            ))
        }
        catch (e) {
            return null;
        }
    }

    async addressGetHouse(id){
        try {
            return JSON.parse(JSON.stringify(
                await ServerRequests.AddressGetHouse(id)
            ))
        }
        catch (e) {
            return null;
        }
    }


}

export {ServerApi};
