const strings =
    {
        Offline: 'Offline',
        LoginLogo: 'LoginLogo',
        LoginIF: 'LoginIF',
        PasswordIF: 'PasswordIF',
        Enter: 'Enter',
        Login: 'Login',
        Password: 'Password',
        KeyPrillMissing: 'KeyPrillMissing',
        Ok: 'OK',
        EnterKeyPrill: 'EnterKeyPrill',
        Attention: 'Attention',
        EnterKeyPrillPlease: 'EnterKeyPrillPlease',
        Save: 'Save',
        Cancel: 'Cancel',
        KeyPrillMask: 'KeyPrillMask',
        EmptyFieldWarning: 'EmptyFieldWarning',
        AuthorizationError: 'AuthorizationError',
        GetLicense: 'GetLicense',
        DemoOver: 'DemoOver',
        MaxApps: 'MaxApps',
        Allow: 'Allow',
        WrongLoginOrPassword: 'WrongLoginOrPassword',
        ConfigParseError: 'ConfigParseError',
        NotForYou: 'NotForYou',
        Hour: 'Hour',
        Currency: 'Currency',
        TaskTypeDescription: 'TaskTypeDescription',
        TaskTypeDescriptionNone: 'TaskTypeDescriptionNone',
        Staffs: 'Staffs',
        Tasks: 'Tasks',
        NoTasksToDay: 'NoTasksToDay',
        TaskNumber: 'TaskNumber',
        TaskDate: 'TaskDate',
        TaskType: 'TaskType',
        TaskOpis: 'TaskOpis',
        Abon: 'Abon',
        Address: 'Address',
        Network: 'Network',
        Other: 'Other',
        TaskFio: 'TaskFio',
        TaskAddData: 'TaskAddData',
        TaskIp: 'TaskIp',
        TaskPhone: 'TaskPhone',
        TaskAgreement: 'TaskAgreement',
        TaskLogin: 'TaskLogin',
        TaskComments: 'TaskComments',
        ShowImages: 'ShowImages',
        GenQrCode: 'GenQrCode',
        TaskAddress: 'TaskAddress',
        TaskNotes: 'TaskNotes',
        TaskExits: 'TaskExits',
        TaskKeys: 'TaskKeys',
        TaskWorkNotes: 'TaskWorkNotes',
        TaskNetwork: 'TaskNetwork',
        TaskInternet: 'TaskInternet',
        TaskOnPoint: 'TaskOnPoint',
        TaskEndPoint: 'TaskEndPoint',
        TaskAbonGroup: 'TaskAbonGroup',
        TaskSignalPower: 'TaskSignalPower',
        TaskSignalPowerHistory: 'TaskSignalPowerHistory',
        TaskLastOnuInfo: 'TaskLastOnuInfo',
        TaskShowWatchers: 'TaskShowWatchers',
        TaskRelated: 'TaskRelated',
        TaskObjects: 'TaskObjects',
        Vols: 'Vols',
        Before: 'Before',
        EndPointNotFound: 'EndPointNotFound',
        ClickUpdate: 'ClickUpdate',
        NotSignalPower: 'NotSignalPower',
        Apply: 'Apply',
        From: 'From',
        To: 'To',
        Db: 'Db',
        Sluzhebniy: 'Sluzhebniy',
        AttachedPhoto: 'AttachedPhoto',
        Scaning: 'Scaning',
        QrBarCode: 'QrBarCode',
        AbonOnMap: 'AbonOnMap',
        SendCoord: 'SendCoord',
        InfraObject: 'InfraObject',
        Commutation: 'Commutation',
        KnotCoupling: 'KnotCoupling',
        SendComment: 'SendComment',
        CreateChildTask: 'CreateChildTask',
        MenuOpora: 'MenuOpora',
        MenuAd: 'MenuAd',
        MenuTaskMap: 'MenuTaskMap',
        MenuTaskCalendar: 'MenuTaskCalendar',
        MenuOverdue: 'MenuOverdue',
        MenuNearKnotCoupling: 'MenuNearKnotCoupling',
        MenuVedomost: 'MenuVedomost',
        MenuTMC: 'MenuTMC',
        MenuSettings: 'MenuSettings',
        MenuAbout: 'MenuAbout',
        MenuLogOut: 'MenuLogOut',
        Site: 'Site',
        Email: 'Email',
        Phone: 'Phone',
        Loading: 'Loading',
        SendedPhoto: 'SendedPhoto',
        TaskEnterDescription: 'TaskEnterDescription',
        SelectDivision: 'SelectDivision',
        TaskCreate: 'TaskCreate',
        TaskDateAndTime: 'TaskDateAndTime',
        Close: 'Close',
        Back: 'Back',
        Send: 'Send',
        theTaskCreated: 'theTaskCreated',
        theTaskNoCreated: 'theTaskNoCreated',
        PhotoDescription: 'PhotoDescription',
        Photo: 'Photo',
        SelectTheStatus: 'SelectTheStatus',
        SelectTheNumberOfTasks: 'SelectTheNumberOfTasks',
        Then: 'Then',
        Previous: 'Previous',
        PostingAComment: 'PostingAComment',

        CanAttachPhotosAndComments: 'CanAttachPhotosAndComments',

        ByCreationDate: 'ByCreationDate',
        ByDateOfChange: 'ByDateOfChange',
        ByTheAppointedDate: 'ByTheAppointedDate',
        ByFinishDate: 'ByFinishDate',
        ByStatus: 'ByStatus',
        ByType: 'ByType',

        LoginAndPassword: 'LoginAndPassword',
        CloseTheTask: 'CloseTheTask',
        DepartureToTheClient: 'DepartureToTheClient',
        ActTwoSideSignatureAbon: 'ActTwoSideSignatureAbon',
        All: 'All',
        AssignmentsFor: 'AssignmentsFor',
        Navigator: 'Navigator',
        More: 'More',
        YouSureWantRemoveWatcher: 'YouSureWantRemoveWatcher',
        AddObservers: 'AddObservers',
        Сomment: 'Сomment',
        EnterPhotoDescription: 'EnterPhotoDescription',
        FinishDot: 'FinishDot',
        StartDot: 'StartDot',
        FailedLocateLocation: 'FailedLocateLocation',

        Сlutch: 'Сlutch',
        Support: 'Support',
        Well: 'Well',
        Knot: 'Knot',
        AllInfrastructure: 'AllInfrastructure',
        ReserveLength: 'ReserveLength',
        Stock: 'Stock',
        Name: 'Name',
        Date: 'Date',
        SerialNumber: 'SerialNumber',
        IndicationsLast: 'IndicationsLast',
        IndicationsNew: 'IndicationsNew',
        RequiredToFillIn: 'RequiredToFillIn',
        YouDontEquipment: 'YouDontEquipment',
        YouNotTasksStatistic: 'YouNotTasksStatistic',
        Names: 'Names',
        Entrance: 'Entrance',
        JobObject: 'JobObject',
        CouplingType: 'CouplingType',
        HideImages: 'HideImages',
        ChooseAbonent: 'ChooseAbonent',
        CheckTheTask: 'CheckTheTask',
        OnCheckTask: 'OnCheckTask',
        SubscriberSelection: 'SubscriberSelection',
        CallersLocationUnknown: 'CallersLocationUnknown',


    };
export {strings};

const ruStrings = {};
ruStrings[strings.SendedPhoto] = "Отправил фото:";
ruStrings[strings.MenuOpora] = "Опоры";
ruStrings[strings.Loading] = "Загрузка";
ruStrings[strings.Email] = "E-mail";
ruStrings[strings.Phone] = "Телефон";
ruStrings[strings.Site] = "Сайт";
ruStrings[strings.MenuLogOut] = "Выйти";
ruStrings[strings.MenuAbout] = "О приложении";
ruStrings[strings.MenuSettings] = "Настройки";
ruStrings[strings.MenuTMC] = "ТМЦ на сотруднике";
ruStrings[strings.MenuVedomost] = "Ведомость работ";
ruStrings[strings.MenuNearKnotCoupling] = "Рядом узел/муфта";
ruStrings[strings.MenuOverdue] = "Просроченные задания";
ruStrings[strings.MenuTaskCalendar] = "Календарь заданий";
ruStrings[strings.MenuAd] = "Реклама";
ruStrings[strings.MenuTaskMap] = "Задания на карте";
ruStrings[strings.LoginLogo] = "ВХОД";
ruStrings[strings.Scaning] = "Сканирование";
ruStrings[strings.SendComment] = "Отправить комментарий";
ruStrings[strings.CreateChildTask] = "Создать дочерние задание";
ruStrings[strings.Commutation] = "Коммутация";
ruStrings[strings.KnotCoupling] = "узел, муфта";
ruStrings[strings.InfraObject] = "Объект инфраструктуры на карте";
ruStrings[strings.AbonOnMap] = "Абонент на карте";
ruStrings[strings.SendCoord] = "Отправка координат";
ruStrings[strings.QrBarCode] = "Qr-код/Штрих-код";
ruStrings[strings.LoginIF] = "Логин:";
ruStrings[strings.PasswordIF] = "Пароль:";
ruStrings[strings.Enter] = "ВОЙТИ";
ruStrings[strings.Login] = "Логин";
ruStrings[strings.Password] = "Пароль";
ruStrings[strings.KeyPrillMissing] = "Отсутствует сохраненное название организации.";
ruStrings[strings.Ok] = "ОК";
ruStrings[strings.Save] = "Сохранить";
ruStrings[strings.Cancel] = "Отмена";
ruStrings[strings.EnterKeyPrill] = "Ввести";
ruStrings[strings.Attention] = "Внимание !";
ruStrings[strings.KeyPrillMask] = "xxxxxx";
ruStrings[strings.EnterKeyPrillPlease] = "Введите название организации.";
ruStrings[strings.EmptyFieldWarning] = "Пожалуйста , заполните поля.";
ruStrings[strings.AuthorizationError] = "Ошибка авторизации.";
ruStrings[strings.GetLicense] = "Получите лицензию!";
ruStrings[strings.DemoOver] = "Демо период закончился. Купите лицензию.";
ruStrings[strings.MaxApps] = "Достигнуто максимальное количество установок приложения";
ruStrings[strings.Allow] = "Разрешено.";
ruStrings[strings.LoginAndPassword] = "Введите логин или пароль.";
ruStrings[strings.WrongLoginOrPassword] = "Неверный логин или пароль.";
ruStrings[strings.ConfigParseError] = "Ошибка загрузки конфига.";
ruStrings[strings.NotForYou] = "Вашему устройству не разрешено пользоваться приложением.";
ruStrings[strings.Hour] = "ч.";
ruStrings[strings.Currency] = "₽";
ruStrings[strings.TaskTypeDescription] = "Описание типа задания";
ruStrings[strings.TaskTypeDescriptionNone] = "Описание типа задания отсутствует.";
ruStrings[strings.Staffs] = "Исполнители";
ruStrings[strings.Tasks] = "Заданий: ";
ruStrings[strings.NoTasksToDay] = "На сегодня нет заданий.";
ruStrings[strings.TaskNumber] = "Номер задания:";
ruStrings[strings.TaskDate] = "Назначеная дата:";
ruStrings[strings.TaskType] = "Тип задания:";
ruStrings[strings.TaskOpis] = "Описание:";
ruStrings[strings.Abon] = "Абонент";
ruStrings[strings.Address] = "Адрес";
ruStrings[strings.Network] = "Сеть";
ruStrings[strings.Other] = "Другое";
ruStrings[strings.TaskFio] = "ФИО:";
ruStrings[strings.TaskAddData] = "Доп. информация:";
ruStrings[strings.TaskIp] = "Ip:";
ruStrings[strings.TaskPhone] = "Телефоны";
ruStrings[strings.TaskAgreement] = "Договор:";
ruStrings[strings.TaskLogin] = "Логин:";
ruStrings[strings.TaskComments] = "Комментарии";
ruStrings[strings.ShowImages] = "Показать изображения";
ruStrings[strings.GenQrCode] = "Сгенерировать QR Code";
ruStrings[strings.TaskAddress] = "Адрес:";
ruStrings[strings.TaskNotes] = "Заметки:";
ruStrings[strings.TaskExits] = "Выходы:";
ruStrings[strings.TaskKeys] = "Ключи:";
ruStrings[strings.TaskWorkNotes] = "Рабочие заметки:";
ruStrings[strings.TaskNetwork] = "Активность в сети:";
ruStrings[strings.TaskInternet] = "Активность в интернете:";
ruStrings[strings.TaskOnPoint] = "Точка включения:";
ruStrings[strings.TaskEndPoint] = "Конечная точка:";
ruStrings[strings.TaskAbonGroup] = "Группа абонентов:";
ruStrings[strings.TaskSignalPower] = "Уровень сигнала:";
ruStrings[strings.TaskSignalPowerHistory] = "История уровня сигнала";
ruStrings[strings.TaskLastOnuInfo] = "Последняя информация по ONU";
ruStrings[strings.TaskShowWatchers] = "Показать наблюдателей";
ruStrings[strings.TaskRelated] = "Связанные задания";
ruStrings[strings.TaskObjects] = "Объекты";
ruStrings[strings.Vols] = "ВОЛС";
ruStrings[strings.Before] = "до";
ruStrings[strings.EndPointNotFound] = "Конечная точка не найдена";
ruStrings[strings.ClickUpdate] = "Нажмите \"обновить\"";
ruStrings[strings.NotSignalPower] = "Не удалось получить уровень сигнала";
ruStrings[strings.Offline] = "Отсутствует подключение к интернету";
ruStrings[strings.Apply] = "Принять";
ruStrings[strings.From] = "C";
ruStrings[strings.To] = "По";
ruStrings[strings.Db] = "Db";
ruStrings[strings.Sluzhebniy] = "Служебный";
ruStrings[strings.AttachedPhoto] = "Прикрепленных фото";
ruStrings[strings.TaskEnterDescription] = "Введите описание для данного задания...";
ruStrings[strings.SelectDivision] = "Выберите подразделение(если требуется)";
ruStrings[strings.TaskCreate] = "Создать задание";
ruStrings[strings.TaskDateAndTime] = "Назначенная дата и время:";
ruStrings[strings.Close] = "Закрыть";
ruStrings[strings.Back] = "Назад";
ruStrings[strings.Send] = "Отправить";
ruStrings[strings.theTaskCreated] = "Задание создано";
ruStrings[strings.theTaskNoCreated] = "Задание не создано, попробуйте позже";
ruStrings[strings.PhotoDescription] = "Описание фото";
ruStrings[strings.Photo] = "Фотографии:";
ruStrings[strings.SelectTheStatus] = "Выберите статус:";
ruStrings[strings.SelectTheNumberOfTasks] = "Выберите количество заданниний:";
ruStrings[strings.CloseTheTask] = "Закрыть задание ";
ruStrings[strings.CheckTheTask] = "Отправить на проверку";
ruStrings[strings.OnCheckTask] = "На проверке";
ruStrings[strings.DepartureToTheClient] = "Выезд к клиенту (!)";
ruStrings[strings.Then] = "Следующий";
ruStrings[strings.Previous] = "Предыдущий";
ruStrings[strings.PostingAComment] = "Отправка комментария";

ruStrings[strings.CanAttachPhotosAndComments] = "К комментарию можно прикрепить несколько фото, либо отправить фото без комментария.";

ruStrings[strings.ByCreationDate] = "По дате создания";
ruStrings[strings.ByDateOfChange] = "По дате изменения";
ruStrings[strings.ByTheAppointedDate] = "По назначенной дате";
ruStrings[strings.ByFinishDate] = "По дате окончания";
ruStrings[strings.ByStatus] = "По статусу";
ruStrings[strings.ByType] = "По типу";

ruStrings[strings.ActTwoSideSignatureAbon] = "Акт с двух сторон с подписью абонента (!)";
ruStrings[strings.All] = "Все";
ruStrings[strings.AssignmentsFor] = "Задания на";
ruStrings[strings.Navigator] = "Навигатор";
ruStrings[strings.More] = "Подробнее";
ruStrings[strings.YouSureWantRemoveWatcher] = "Вы уверены, что хотите удалить наблюдателя?";
ruStrings[strings.AddObservers] = "Добавить наблюдателей";
ruStrings[strings.Сomment] = "Комментарий...";
ruStrings[strings.EnterPhotoDescription] = "Введите описание фото если требуется...";
ruStrings[strings.FinishDot] = "Конечная точка";
ruStrings[strings.StartDot] = "Стартовая точка";
ruStrings[strings.FailedLocateLocation] = "Не удалось обнаружить местоположение.";
ruStrings[strings.ReserveLength] = "Резервная длина";
ruStrings[strings.Stock] = "Запас: ";

ruStrings[strings.Сlutch] = "Муфта";
ruStrings[strings.Support] = "Опора";
ruStrings[strings.Well] = "Колодец";
ruStrings[strings.Knot] = "Узел";
ruStrings[strings.AllInfrastructure] = "Вся инфраструктура";

ruStrings[strings.Name] = "Наименование";
ruStrings[strings.Date] = "Дата";
ruStrings[strings.SerialNumber] = "Серийный номер";
ruStrings[strings.IndicationsLast] = 'Предыдущие показания';
ruStrings[strings.IndicationsNew] = 'Введите новые показания';
ruStrings[strings.RequiredToFillIn] = 'Обязательно к заполнению';
ruStrings[strings.YouDontEquipment] = "На вас нет оборудования.";
ruStrings[strings.YouNotTasksStatistic] = "Вы еще не выполнили достаточное число заданий для показа статистики.";
ruStrings[strings.Names] = "Название:";
ruStrings[strings.Entrance] = "Подъезд:";
ruStrings[strings.JobObject] = "Объект задания:";
ruStrings[strings.CouplingType] = "Тип Муфты:";
ruStrings[strings.HideImages] = "Спрятать изображения";
ruStrings[strings.ChooseAbonent] = "Выберете абонента";
ruStrings[strings.SubscriberSelection] = "Выбор абонента";
ruStrings[strings.CallersLocationUnknown] = "Неизвестно местоположение абонента";

const enStrings = {};
enStrings[strings.LoginLogo] = "LOGIN";

const ukStrings = {};
ukStrings[strings.LoginLogo] = "ВХIД";

const bgStrings = {};
bgStrings[strings.LoginLogo] = "ВХIД";

function getLocale() {
    switch (navigator.language) {
        case 'ru':
        case 'ru-RU':
            return ruStrings;
        case 'en':
        case 'en-US':
            return enStrings;
        case 'uk':
        case 'uk-UA':
            return ukStrings;
        case 'bg':
        case 'bg-BG':
            return bgStrings;
        default:
            return enStrings;
    }
}

function getString(string) {
    return getLocale()[string];
}

export {getString};
