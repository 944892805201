import React from "react";

export default function FlagFieldType(props) {
  return (
    <form>

      <div className="form-check" id="exampleCheck" onChange={props.onChange} name={props.id} style={{ display: 'flex' }}>
        <label className="form-check-label" htmlFor="exampleCheck">{props.name}</label>
        <input type="checkbox" className="form-check-input" id="exampleCheck1" value={props.name} />
      </div>
    </form>

  )
}