import React from "react";
import taskAbonGrouptIcon from '../../../../Resources/Icons/AboutTask/group.png';

export default function AbonGroup(props) {

  return (
    <div id={"abonGroupHodler"} className="col-sm-12">
      <label className="float-left text-primary mb-0">{props.taskAbonGroup}</label>
      <div className="input-group mb-2">
        <div className="input-group-prepend">
          <span className="input-group-text bg-primary"><img src={taskAbonGrouptIcon} alt={taskAbonGrouptIcon} width="20px" /></span>
        </div>
        <div id={"abonGroup"} className={"form-control h-auto smallText card d-block border-1  px-2 py-2"} />
      </div>
    </div>
  )
}