import React, {Component} from 'react';
import {
    YMaps,
    Map,
    Circle,
    Placemark,
    TypeSelector,
    ZoomControl,
    FullscreenControl,
    GeolocationControl,
    RouteButton,
    Polyline
} from 'react-yandex-maps';

import {getString, strings} from "../../Languages/Languages";
import {Button} from "react-bootstrap";
import {ServerApi} from "../../Server/ServerApi";
import backArrow from "../../Resources/Icons/back-arrow.svg";
import {reactLocalStorage} from "reactjs-localstorage";
import {User} from "../../User/User";
import stopWorkButton from "../../Utils/StartToWork/stopWorkButton";
import startToWork from "../../Utils/StartToWork/StartToWork";



// contains(position)

let commentsAlreadyDownloaded = false;
let oficeLat
let oficeLon
let userLat
let userLng

//Фиксация рабочего времени


class FixingWorkingTime extends Component {
    constructor(props) {
        super(props);
        this.serverApi = new ServerApi();
        this.resultLocation = this.resultLocation.bind(this);
        this.exit = this.exit.bind(this);
        this.markLocation = this.markLocation.bind(this)
        // this._navigator = this._navigator.bind(this)
        this.employeeId = User.employeeId;
        this.date = new Date();
        this.workDate = this.date.getFullYear() + '-' + String(this.date.getMonth() + 1).padStart(2, '0') + '-' + String(this.date.getDate()).padStart(2, '0');
        this.lastDayDate = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0);
        this.finalDate = this.date.getFullYear() + '-' + String(this.date.getMonth() + 1).padStart(2, '0') + '-' + this.lastDayDate.toLocaleString('RU', {day: 'numeric'});
        this.workTime = this.date.getHours() + ':' + String(this.date.getMinutes()).padStart(2, '0');
        this.state = {
            center: [reactLocalStorage.get('oficeLatitude', ''), reactLocalStorage.get('oficeLongitude', '')],
            zoom: 19,
            type: 'yandex#map',
            bounds: undefined,
            range: 17,
            coordinates: [],
            height: '500px',
            ymaps: null,
            defMap: {
                center: [reactLocalStorage.get('oficeLatitude', ''), reactLocalStorage.get('oficeLongitude', '')],
                zoom: 17
            },
            oficeLat: 0,
            oficeLon: 0,
            userLat: 0,
            userLon: 0,
            startShift: {
                disabled: true,
                color: 'rgb(175 175 175)'
            },
            stopShift: {
                disabled: true,
                color: 'rgb(175 175 175)'
            }
        }
    }


    componentDidMount = () => {
        let height = document.documentElement.clientHeight
        height = height - 160    //53 //130 без дива

        this.setState({height});

        if (!navigator.geolocation) {
            alert('Geolocation is not supported by your browser')
            this.setState({startShift: {disabled: true, color: 'rgb(175 175 175)'}})
        } else {
            // this._navigator()
        }


    }



    _navigator = () => {
        navigator.geolocation.getCurrentPosition(function (position) {
                if (position.coords.accuracy <= 20) {
                    console.log(`Good! ${position.coords.accuracy}`)
                } else {
                    console.log(`Bad! ${position.coords.accuracy}`)
                }
                // reactLocalStorage.set('userLatitude', position.coords.latitude);
                // reactLocalStorage.set('userLongitude', position.coords.longitude);

                oficeLat = reactLocalStorage.get('oficeLatitude', '')
                oficeLon = reactLocalStorage.get('oficeLongitude', '')
                userLat = position.coords.latitude
                userLng = position.coords.longitude


                console.log(oficeLat, oficeLon, userLat, userLng)
                alert(startToWork(oficeLat, oficeLon, userLat, userLng))
            }, function (error) {
                console.log(error)
            },
            {timeout: 10000, enableHighAccuracy: true, maximumAge: 0})
        if (startToWork(oficeLat, oficeLon, userLat, userLng) > this.state.range) {
            this.setState({startShift: {disabled: true, color: 'rgb(175 175 175)'}})
        } else {
            this.setState({startShift: {disabled: false, color: '#505050'}})
        }
        if (User.keyPril === '100-201') {
            this.serverApi.getWorkTimeData(this.workDate, this.finalDate, User.employeeId).then((response) => {
                console.log(response)
                let timeFinish = response.data[this.employeeId][response.data[this.employeeId].length - 1].time_finish;
                if (timeFinish.includes('1970-01-01')) {
                    this.setState({
                        startShift: {disabled: true, color: 'rgb(175 175 175)'},
                        stopShift: {disabled: false, color: '#505050'}
                    })
                }
            })
                .catch((error) => {
                    console.log(error)
                })
        }
        if (User.keyPril === '100-200') {
            if (reactLocalStorage.get('timeFinish', '') !== null) {
                let timeFinish = reactLocalStorage.get('timeFinish', '')
                if (timeFinish.includes('1970-01-01')) {
                    this.setState({
                        startShift: {disabled: true, color: 'rgb(175 175 175)'},
                        stopShift: {disabled: false, color: '#505050'}
                    })
                }
            }
        }
    }


    resultLocation(ev) {
        this.serverApi.setTimesheetData(this.employeeId, this.workDate, '1', '1').then((response) => {
            if (response.status === 'OK') {
                console.log(`Вы начали работать в ${this.workTime}`)
                this.setState({
                    startShift: {disabled: true, color: 'rgb(175 175 175)'},
                    stopShift: {disabled: false, color: '#505050'}
                })
                this.serverApi.workTimeStart(this.employeeId, `${this.workDate},${this.workTime}`, '').then((response) => {
                    reactLocalStorage.set('timeStart', `${this.workDate} ${this.workTime}`)
                    reactLocalStorage.set('timeFinish', `1970-01-01 03:00:00`)
                    console.log(response)
                })
            } else {
                console.log('Error')
            }
        })
        //     /*console.log(ev.originalEvent.target)*/
        // });

    }

//Кнопка Закончить работу
stop = () => {
    let timeFinish = this.date.getTime();
    if (User.keyPril === '100-201') {
        this.serverApi.workTimeFinish(this.employeeId, `${this.workDate},${this.workTime}`, '').then((response) => {
            console.log(response)
        })
        console.log(`Вы закончили работать в ${this.workTime}`)
        this.serverApi.getWorkTimeData(this.workDate, this.finalDate, User.employeeId).then((response) => {
            console.log(response)
            let timeStart = response.data[this.employeeId][response.data[this.employeeId].length - 1].time_start;

            //Записывает время конца рабочего дня и передает данные,
            //Устанавливает кол-во рабочих часов за сегодняшний день
            stopWorkButton(timeStart, timeFinish, this.workDate);
            this.setState({
                stopShift: {disabled: true, color: 'rgb(175 175 175)'}
            })
        })
    }
    if (User.keyPril === '100-200') {

        reactLocalStorage.set('timeFinish', `${this.workDate} ${this.workTime}`)
        let timeStart = reactLocalStorage.get('timeStart', '');

        stopWorkButton(timeStart, timeFinish, this.workDate);
        this.setState({
            stopShift: {disabled: true, color: 'rgb(175 175 175)'}
        })
    }
}

    exit() {
        window.open("/main", '_self', false);
    }

    markLocation(ev) {
        /* let lat = ev.originalEvent.target.geometry['_coordinates'][0]
         let lon = ev.originalEvent.target.geometry['_coordinates'][1]*/
        console.log(ev.originalEvent.target)

        /*ev.originalEvent.target.geometry['_bounds']*/
    }


    render() {

        return (
            <div className={"newCircle"}>
                <YMaps

                    query={{
                        apikey: '3261ce08-c60a-4114-96f8-ce820abf124a',
                        // lang: "ru_RU", load: "package.full"
                    }}>

                    <div>
                        <Map defaultState={this.state.defMap}
                             state={this.state}
                             id="test"
                             width='100%'
                             height={this.state.height}
                             modules={["Placemark", "geocode", "geoObject.addon.balloon"]}
                        >
                            <Polyline geometry={[
                                [reactLocalStorage.get('oficeLatitude', ''), reactLocalStorage.get('oficeLongitude', '')],
                                [55.59575408469716, 38.47251711208433]
                                ]}
                                      options={{
                                          balloonCloseButton: false,
                                          strokeColor: '#000',
                                          strokeWidth: 4,
                                          strokeOpacity: 0.5
                                      }}
                                      onClick={this.markLocation}
                            />
                            <GeolocationControl onClick={() => this._navigator()}
                                                options={{float: 'left'}}


                            />
                            <Circle
                                // onLoad={ymaps => this.setState({ymaps})}
                                geometry={[this.state.center, this.state.range]}

                                options={{
                                    fillColor: '#DB709377',
                                    strokeColor: '#990066',
                                    strokeOpacity: 0.8,
                                    strokeWidth: 5,
                                    draggable: false,
                                }}

                                // contains={{}}
                                // instanceRef={ref => {
                                //     if (ref && this.state.ymaps) {
                                //         let k = this.state.ymaps.geoQuery(ref).get(0)
                                //
                                //         console.log(
                                //             this.state.ymaps.geoQuery(ref).get(0),
                                //             /*this.state.ymaps.geoQuery(ref).searchInside(ref.geometry)*/
                                //         );
                                //         console.log()
                                //         const res = this.state.ymaps
                                //             .geoQuery(ref)
                                //             .searchInside(ref.geometry);
                                //
                                //         console.log(
                                //             "Количество объектов, лежащих внутри заданной области: " +
                                //             res.getLength()
                                //         );
                                //     }
                                // }}
                            />

                        </Map>
                        <div className={'lllllllll'}>
                        </div>

                        <Button onClick={() => this.resultLocation()}
                            //TODO: добавить в Language
                                className={"complete"}
                                disabled={this.state.startShift.disabled}
                            // style={{
                            //     color: `${this.state.startShift.color}`,
                            // }}
                        >{`Начать работать`}</Button>
                        <Button onClick={() => this.stop()}
                                className={"begin btn-danger"}
                                disabled={this.state.stopShift.disabled}
                        >{'Закончить работать'}</Button>
                    </div>
                </YMaps>
                <nav className="navbar fixed-bottom navbar-light bg-white shadow-lg">
                    <div className="float-left">
                        <a id={"backBtn"} onClick={() => this.exit()}
                           className="navbar-brand text-primary mx-2"><img src={backArrow} alt={backArrow}
                                                                           width={"25px"}/></a>
                    </div>
                </nav>
            </div>
        );
    }
}

export default FixingWorkingTime;

