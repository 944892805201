import React, {Component} from 'react';
import {FullscreenControl, GeolocationControl, Map, Placemark, RouteButton, TypeSelector, YMaps, ZoomControl} from "react-yandex-maps";
import {Button} from "react-bootstrap";
import {getString, strings} from "../Languages/Languages";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {ShowMarketing} from "../Utils/Dialog/MarketingDialog";
import {CloseLoading, ShowLoading} from "../Utils/Dialog/LoadingDialog";
import {ServerApi} from "../Server/ServerApi";
import {User} from "../User/User";

let arrCoordinates = [];
let coordinates;
let markId;
let mapState;
let zoom;

if (User.corMarket != ''){
    let data = User.corMarket.split(',')
    let latIn = Number(data[0])
    let lonIn = Number(data[1])
    mapState = [latIn,lonIn]
    zoom = 16

}else {
    mapState = [55.59587, 38.47261]
    zoom = 10
}

class MarketingMap extends Component {
    state = {
        center: mapState,
        zoom: zoom,
        type: 'yandex#map',
        bounds: undefined,
        range: 1000,
        coordinates: [],
        height: '500px',
        lon: 0.0,
        lat: 0.0,
        controls: [],
        nodeData: 0.0,
        coords: [],
    }

    componentDidMount() {
        let serverApi = new ServerApi();
        let height = document.documentElement.clientHeight;
        height = height - 86;
        serverApi.markshow().then((response) => {
            for (let aaaId in response.data){
                if (response.data[aaaId].name === 'Реклама' || response.data[aaaId].name === 'Ad'){
                    markId = response.data[aaaId].id;
                    break;
                }
            }
            localStorage.setItem('markId', markId);
            ShowLoading();
            serverApi.nodegetmark(markId).then((response) => {
                let arr = [response.data]
                arrCoordinates = []
                arr.map((item) => {
                    for (let aaaCoordinates in item){
                        let coordinate = item[aaaCoordinates].coordinates;
                        let arrItem = [coordinate.lat, coordinate.lon];
                        arrCoordinates.push(arrItem);
                    }
                })
                coordinates = arrCoordinates;
                CloseLoading();
                this.setState({coordinates});
            })
        })
        let textArea = `<textarea id="textareaId" class="form-controlComments"
                        placeholder=${getString(strings.Сomment)} data-text="text"></textarea>`



        this.setState({height, textArea });
    }

    onMapClick(event) {
        let serverApi = new ServerApi();
        let lat = event.get("coords")[0]
        let lon = event.get("coords")[1]
        let coordinate = String([lat,lon])

        ShowMarketing(() => {
            let comment = document.getElementById('commentText').value
            console.log(comment)
             serverApi.nodeadd(comment, coordinate).then((response) => {
                 let id = response.id
                 serverApi.nodeAddmark(id, markId).then(() => {
                 })
             })
            this.setState(state => {
                return {
                    coords: [...state.coords, event.get("coords")]
                };
            });
        })
    }

    render() {
        return (
            <div className={"InfrastructureMapForm"} >
                <YMaps  query={{ apikey: '3261ce08-c60a-4114-96f8-ce820abf124a' }}>
                        <Map state={this.state}
                             height={this.state.height}
                             width='100%'
                             id={"mapId"}
                             onClick={this.onMapClick.bind(this)}
                        >
                            <ZoomControl options={{ float: 'right' }} />
                            <TypeSelector options={{ float: 'right' }} />
                            {this.state.coordinates.map((mark, index) => (
                                <Placemark  onClick={markLocation}
                                            key={index}
                                            defaultGeometry = {mark}
                                />
                            ))}
                            {this.state.coords.map(coords => (
                                <Placemark
                                    key={coords.join(",")}
                                    geometry={coords}
                                    options={{
                                        iconColor:'#c92d2d'
                                    }}
                                />
                            ))}
                            <FullscreenControl />
                            <GeolocationControl
                                               // onClick={resultGeoLocation}
                                                options={{float: 'left',}}
                            />
                            <RouteButton options={{
                                float: 'right'}} />
                        </Map>
                        <br/>
                        <div>
                            <Button onClick={() => {window.open("/main",'_self',false)}}
                                    className={"YamapBack"}>{getString(strings.Back)}</Button>
                            <button
                                onClick={async () => {window.open("advertising/information", '_self', false);localStorage.removeItem('marka')}}
                                    type="button" className="btn btn-primary">
                                <FontAwesomeIcon icon={faBars}/>
                            </button>
                        </div>
                </YMaps>
            </div>
        );
    }
}

export default MarketingMap;

function markLocation(ev){
    let serverApi = new ServerApi();
    let lat = ev.originalEvent.target.geometry['_coordinates'][0]
    let lon = ev.originalEvent.target.geometry['_coordinates'][1]
    let coordinate = String([lat,lon])

    ShowMarketing(() => {
        let text = document.getElementById('commentText').value
        console.log(text)
    })
}

function resultGeoLocation(){
    navigator.geolocation.getCurrentPosition(function(position) {
        let lat = position.coords.latitude
        let lon = position.coords.longitude
        console.log(lat)
        console.log(lon)
        let a = String([lat,lon])
        console.log(a)
        /* ShowMarketing(() => {
             let text = document.getElementById('commentText').value
             console.log(text)
         })*/
    });
}


