import React, {Component} from 'react';
import {FullscreenControl, GeolocationControl, Map, Placemark, TypeSelector, YMaps, ZoomControl} from "react-yandex-maps";
import {Button} from "react-bootstrap";
import {getString, strings} from "../Languages/Languages";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons";
import {ShowSupportsMap} from '../Utils/Dialog/SupportsDialog'
import {User} from "../User/User";

function resultLocation(){
    navigator.geolocation.getCurrentPosition(function(position) {
        let lat = position.coords.latitude
        let lon = position.coords.longitude
    });
}

let centers = User.coordinates
let data = centers.split(',')
let lat = Number(data[0])
let lon = Number(data[1])
if (lat == NaN || lat == '' || lat == 0 &&
    lon == NaN || lon == '' || lon == 0){
  lat = 55.590767;
  lon = 38.543839;
}

let mapState = { center: [lat, lon], zoom: 14 };

class SupportsMap extends Component {
    centers = User.coordinates
    state = {
        center: [55.590767,38.543839],
        zoom: 12,
        type: 'yandex#map',
        bounds: undefined,
        range: 1000,
        coordinates: [],
        height: '500px',
        lon: 0.0,
        lat: 0.0,
        controls: [],
    }

    componentDidMount() {
        let height = document.documentElement.clientHeight
        height = height - 87

        this.setState({height});
    }
    render() {
        return (
            <div className={"SubscriberMapForm"} >
                <YMaps query={{ apikey: '3261ce08-c60a-4114-96f8-ce820abf124a' }}>
                    <div>
                        <Map
                             height={this.state.height}
                             width='100%'
                             state={mapState}
                        >
                            <ZoomControl options={{ float: 'right' }} />
                            <TypeSelector options={{ float: 'right' }} />

                            {/*<Placemark*/}
                            {/*    geometry={this.state.center}*/}
                            {/*    options={{*/}
                            {/*        openBalloonOnClick: true,*/}
                            {/*    }}*/}
                            {/*    properties={{*/}
                            {/*        balloonContent:*/}
                            {/*            `<button data-nam='Navigator' class="MarketingButton">${getString(strings.Navigator)}</button>*/}
                            {/*             <button data-nam="onMap" id="TaskOnMap" class="MarketingButton">${getString(strings.More)}</button>`*/}
                            {/*    }}*/}
                            {/*    modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}/>*/}



                            <FullscreenControl />
                            <GeolocationControl
                                onClick={() => resultLocation()}
                                options={{float: 'left'}}/>
                        </Map>
                        <br/>
                        <div>
                            <Button onClick={() => {window.open("/supports", '_self', false);localStorage.removeItem('coordinates')}}
                                    className={"YamapBack"}>{getString(strings.Back)}</Button>
                            <button type="button" className="btn btn-primary"
                                    onClick={() => ShowSupportsMap(() => {
console.log('Save')
                                    })}
                            >
                                <FontAwesomeIcon icon={faMapMarkerAlt}/>
                            </button>
                        </div>
                    </div>
                </YMaps>
            </div>
        );
    }
}

export default SupportsMap;