import downChevron from '../../../../Resources/Icons/down-chevron.svg';
import downloadIcon from '../../../../Resources/Icons/download.svg';

export default function RelatedTasks(props) {

  return (
    <div id={"relatedTasksHolder"} className="col-sm-12 my-2">
      <div className="clearfix">
        <a data-toggle="collapse" href={"#relatedCollapse"} role="button" className={"text-decoration-none"} aria-expanded="false" aria-controls="collapseExample">
          <div className="input-group mb-0">
            <input type="text" className="bg-primary text-light form-control smallText" defaultValue={props.taskRelated} disabled />
            <div className="input-group-append">
              <span className="input-group-text"><img src={downChevron} alt={downloadIcon} width="20px" /></span>
            </div>
          </div>
        </a>
      </div>
      <div className="collapse smallText" id={"relatedCollapse"}>
        <div id={"relatedTasks"} className="card card-body border-top-0 flex_svaz" />
      </div>
    </div>
  )
}