import React, { useState} from "react";
import taskSignalPowerIcon from '../../../../Resources/Icons/AboutTask/addition.png';
import refreshIcon from '../../../../Resources/Icons/refresh.svg';
import { ServerApi } from "../../../../Server/ServerApi";
import { User } from "../../../../User/User";


export default function SignalPower(props) {
  let serverApi = new ServerApi();
  const [state, setState] = useState(props.clickUpdate);
  const [stateOlt, setOltState] = useState(props.clickUpdate);

  const onClick = () => {
    let namId = User.namId
    document.getElementById('updateImageHolder').innerHTML = `<img class="objRotate" src=${refreshIcon} alt=${refreshIcon} width="20px"/>`;

    serverApi.getRxLevel(namId, 1).then(power => {
      document.getElementById('updateImageHolder').innerHTML = `<img src=${refreshIcon} alt=${refreshIcon} width="20px"/>`;
      if (power === undefined || power.length === 0) {
        setState(props.notSignalPower)
        return;
      }
      setState(power[0]['Power'])
    })
  }

  const onOltClick = () => {
    let namId = User.namId
    document.getElementById('updateOltImageHolder').innerHTML = `<img class="objRotate" src=${refreshIcon} alt=${refreshIcon} width="20px"/>`;

    serverApi.getRxOltLevel(namId, 1).then(power => {
      document.getElementById('updateOltImageHolder').innerHTML = `<img src=${refreshIcon} alt=${refreshIcon} width="20px"/>`;
      if (power === undefined || power.length === 0) {
        setOltState(props.notSignalPower)
        return;
      }
      setOltState(power[0]['Power'])
    })
  }

  return (

    <>
      <div id={"signalPowerHolder"} className="col-sm-12">
        <label className="float-left text-primary mb-0">{props.taskSignalPower}</label>
        <div className="input-group mb-2">
          <div className="input-group-prepend">
            <span className="input-group-text bg-primary"><img src={taskSignalPowerIcon} alt={taskSignalPowerIcon} width="20px" /></span>
          </div>
          <div id={"signalPower"} className={"form-control h-auto smallText card d-block border-1 px-2 py-2"}>{state}</div>
          <div className="input-group-append"
            onClick={onClick}>
            <span id={"updateImageHolder"} className="input-group-text"><img src={refreshIcon} alt={refreshIcon} width="20px" /></span>
          </div>
        </div>
      </div>
      <div id={"signalOltPowerHolder"} className="col-sm-12">
        <label className="float-left text-primary mb-0">Уровень сигнала OLT</label>
        <div className="input-group mb-2">
          <div className="input-group-prepend">
            <span className="input-group-text bg-primary"><img src={taskSignalPowerIcon} alt={taskSignalPowerIcon} width="20px" /></span>
          </div>
          <div id={"signalOltPower"} className={"form-control h-auto smallText card d-block border-1 px-2 py-2"}>{stateOlt}</div>
          <div className="input-group-append"
            onClick={onOltClick}>
            <span id={"updateOltImageHolder"} className="input-group-text"><img src={refreshIcon} alt={refreshIcon} width="20px" /></span>
          </div>
        </div>
      </div>
    </>

  )
}