import React from "react";
import AbonOnMap from "./AbonOnMap/AbonOnMap";
import QrBarCodeIconFunc from "./Scanner/QrBarCodeIconFunc";
import InfraObject from "./InfraObject/InfraObject";
import Commutation from "./Commutation/Commutation";
import SendComment from "./SendComments/SendComments";
import CreateChildTask from "./CreateChildTask/CreateChildTask";

export default function MenuContainer(props) {
  return (
    <div className="container">
      <div className="row text-center">
        {/*Абонент на карте, Отправка координат */}
        <AbonOnMap
          addressId={props['AddressId']}
          onMap={props.onMap}
          sendCoord={props.sendCoord}
        />

        {/*QR-code scaner */}
        <QrBarCodeIconFunc
          scaning={props.scaning}
          qrBarCode={props.qrBarCode}
        />
        {/* Объект инфраструкутры на карте */}
        <InfraObject
          infraObject={props.infraObject} />

        {/* Коммутация, узел, муфта */}
        <Commutation
          // comNodes={props['ComNodes']}
          id={props.id}
          commutation={props.commutation}
          knotCoupling={props.knotCoupling} />

        {/* Отправить комментарии */}
        <SendComment
          sendComment={props.sendComment} />

        {/* Создать дочернее задание */}
        <CreateChildTask
          createChildTask={props.createChildTask} />
      </div>
    </div>
  )
}