function decodeHtmlCharCodes(str) {
    str = str.toString();
    str = str.replace(/<[^>]+>/g, '');
    return str.replace(/(&#(\d+);)/g, function(match, capture, charCode) {
        return String.fromCharCode(charCode);
    });
}
export {decodeHtmlCharCodes};

function formateDate(date)
{
    let nowDate = new Date(Date.parse(date));
    let day = nowDate.getDate();

    day = day < 10 ? '0' + day : day;

    let month = nowDate.getMonth();
    month = month + 1;
    month = month < 10 ? '0' + month : month;


    return `${nowDate.getFullYear()}-${month}-${day}`

}
export {formateDate};


