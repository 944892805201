import { reactLocalStorage } from "reactjs-localstorage";
import uuid from 'react-uuid';
import { ServerRequests } from "../Server/ServerRequests";

let User = {
    get uniqueId() {
        let id = reactLocalStorage.get('id', '');
        if (id) return id;

        reactLocalStorage.set('id', uuid());
        return reactLocalStorage.get('id', '');
    },

    get keyPril() {
        return reactLocalStorage.get('keyPrill', '');
    },
    set keyPril(value) {
        if (typeof value === 'undefined') value = '';

        reactLocalStorage.set('keyPrill', value);
        return reactLocalStorage.get('keyPrill', '');
    },

    get operatorId() {
        return reactLocalStorage.get('operatorId', '');
    },
    set operatorId(value) {
        reactLocalStorage.set('operatorId', value);
    },

    get divisionId() {
        return reactLocalStorage.get('divisionId', '');
    },
    set divisionId(value) {
        reactLocalStorage.set('divisionId', value);
    },

    get divisionList() {
        return reactLocalStorage.get('divisionList', '');
    },
    set divisionList(value) {
        reactLocalStorage.set('divisionList', value);
    },

    get login() {
        return reactLocalStorage.get('login', '');
    },
    set login(value) {
        reactLocalStorage.set('login', value);
    },

    get staffId() {
        return reactLocalStorage.get('staffId', '');
    },
    set staffId(value) {
        reactLocalStorage.set('staffId', value);
    },

    get targets() {///////////////////////////////////////
        return reactLocalStorage.get('targets', '');
    },
    set targets(value) {
        reactLocalStorage.set('targets', value);
    },

    get Customer () { //
        return reactLocalStorage.get('Customer', '');
    },
    set Customer (value) {
        reactLocalStorage.set('Customer', value);
    },

    get employeeId() {
        return reactLocalStorage.get('employeeId', '');
    },
    set employeeId(value) {
        reactLocalStorage.set('employeeId', value);
    },

    get name() {
        return reactLocalStorage.get('name', '');
    },
    set name(value) {
        reactLocalStorage.set('name', value);
    },

    get employeeName() {
        return reactLocalStorage.get('employeeName', '');
    },
    set employeeName(value) {
        reactLocalStorage.set('employeeName', value);
    },

    get isLogin() {
        return reactLocalStorage.get('isLogin', false);
    },
    set isLogin(value) {
        reactLocalStorage.set('isLogin', value);
    },

    get config() {
        return reactLocalStorage.get('config', undefined);
    },
    set config(value) {
        reactLocalStorage.set('config', value);
    },

    get filter() {
        return reactLocalStorage.get('filter', 'date_do');
    },
    set filter(value) {
        reactLocalStorage.set('filter', value);
    },

    get currentDate() {
        return reactLocalStorage.get('currentDate', new Date());
    },
    set currentDate(value) {
        reactLocalStorage.set('currentDate', value);
    },

    get shortTasks() {
        return reactLocalStorage.get('shortTasks', undefined);
    },
    set shortTasks(value) {
        reactLocalStorage.set('shortTasks', value);
    },

    get lastAboutTask() {
        return reactLocalStorage.get('lastAboutTask', null);
    },
    set lastAboutTask(value) {
        reactLocalStorage.set('lastAboutTask', value);
    },
    get houseId() {
        return reactLocalStorage.get('houseId', null);
    },
    set houseId(value) {
        reactLocalStorage.set('houseId', value);
    },

    get nodes() {
        return reactLocalStorage.get('nodes', undefined);
    },
    set nodes(value) {
        reactLocalStorage.set('nodes', value);
    },

    removeLastAboutTask() {
        reactLocalStorage.remove('lastAboutTask');
    },

    get lastSavedDate() {
        return reactLocalStorage.get('lastSavedDate', '');
    },
    set lastSavedDate(value) {
        reactLocalStorage.set('lastSavedDate', value);
    },

    get taskId() { //для дочернего
        return reactLocalStorage.get('taskId', '');
    },
    set taskId(value) {
        reactLocalStorage.set('taskId', value);
    },

    get catalog() { ////////////////////////
        return reactLocalStorage.get('catalog', '');
    },
    set catalog(value) {
        reactLocalStorage.set('catalog', value);
    },

    get code() { ////////////////////////
        return reactLocalStorage.get('code', '');
    },
    set code(value) {
        reactLocalStorage.set('code', value);
    },

    get namId() { ////////////////////////
        return reactLocalStorage.get('namId', '');
    },
    set namId(value) {
        reactLocalStorage.set('namId', value);
    },

    get amount() { ////////////////////////
        return reactLocalStorage.get('amount', '');
    },
    set amount(value) {
        reactLocalStorage.set('amount', value);
    },

    get observers() { ////////////////////////
        return reactLocalStorage.get('observers', '');
    },
    set observers(value) {
        reactLocalStorage.set('observers', value);
    },

    get KnotClutchFilter() { ////////////////////////
        return reactLocalStorage.get('KnotClutchFilter', '');
    },
    set KnotClutchFilter(value) {
        reactLocalStorage.set('KnotClutchFilter', value);
    },

    get coordinates() { ////////////////////////
        return reactLocalStorage.get('coordinates', '');
    },
    set coordinates(value) {
        reactLocalStorage.set('coordinates', value);
    },

    get todayTasksList() {
        return reactLocalStorage.get('todayTasksList', '');
    },
    set todayTasksList(value) {
        reactLocalStorage.set('todayTasksList', value);
    },

    get type_obj() {
        return reactLocalStorage.get('type_obj', '');
    },
    set type_obj(value) {
        reactLocalStorage.set('type_obj', value);
    },

    get corMarket () { //Реклама координаты
        return reactLocalStorage.get('corMarket', '');
    },
    set corMarket (value) {
        reactLocalStorage.set('corMarket', value);
    },

    getAboutTask(id) {
        return reactLocalStorage.get(`${id}aboutTask`, undefined);
    },

    setAboutTask(id, abonData, addressData, networkData, otherData) {
        reactLocalStorage.set(`${id}aboutTask`, JSON.stringify({ 'abonData': abonData, 'addressData': addressData, 'networkData': networkData, 'otherData': otherData }));
    },

    removeAboutTask(id) {
        reactLocalStorage.remove(`${id}HistoryCount`);
        reactLocalStorage.remove(`${id}aboutTask`);
    },

    getAboutTaskHistoryCount(id) {
        return reactLocalStorage.get(`${id}HistoryCount`, 0);
    },
    setAboutTaskHistoryCount(id, count) {
        reactLocalStorage.set(`${id}HistoryCount`, count);
    },

    clear() {
        reactLocalStorage.clear();
    },

    async authorize(login, pass, deviceId, keyPrill) {
        try {
            let responseData = JSON.parse(JSON.stringify(await ServerRequests.authorize(login, pass, deviceId, keyPrill)));

            if (responseData.success == false) return { "res": false, "mes": responseData["message"] };
            console.log(responseData.success)
            User.employeeId = responseData["id"];

            User.divisionId = responseData["division_id"];
            User.name = responseData["name"];

            return {
                "res": true,
            }
        }
        catch (e) {
            return { "res": false, "mes": 'Ошибка обработки запроса.' };
        }
    }
}
export { User }
