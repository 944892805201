import {getTasks} from "../Task/Spawners/ShortTasksSpawner";
import {User} from "../User/User";
import {getString} from "../Languages/Languages";
import {strings} from "../Languages/Languages";
import {formateDate} from "../Utils/String/StringUtils";
import {Menu} from "./Menu";
import {useEffect, useState} from "react";
import {reactLocalStorage} from "reactjs-localstorage";
import {ServerApi} from "../Server/ServerApi";



function GetMainForm() {

    const serverApi = new ServerApi()
    let nowDate = formateDate(new Date());
    User.currentDate = nowDate;

    useEffect(() => {
        if (!navigator.geolocation) {
            alert('Geolocation is not supported by your browser')
        } else {

        }
        serverApi.getOficeCoord().then((response) => {
            reactLocalStorage.set('oficeLatitude', response.lat)
            reactLocalStorage.set('oficeLongitude', response.lon)
        })
    }, [])


    useEffect(() => {
        console.log("called getTasks");
        getTasks(User.currentDate, User.currentDate).then((response) => {
            if (response.length > 0) {
                response.forEach((item) => {
                    reactLocalStorage.set('setColor_' + item.Type, '')
                })
            }
        })

    }, [])

    const getStaffName = () => {
        let staffName = '';
        let staffNameArr = [];


        if ((User.name + '').indexOf(' ') === -1) {
            return User.name;
        }
        staffNameArr = User.name.toString().split(' ');


        for (let i = 0; i < staffNameArr.length; i++) {
            if (staffNameArr[i] === '') continue;

            if (i === 0) {
                staffName += staffNameArr[i];
                continue;
            }

            staffName += ` ${staffNameArr[i].substring(0, 1)}.`;
        }

        return staffName;
    }

    return (<>
        <Menu/>
        <nav className="navbar fixed-top navbar-light bg-white shadow-lg navbar-top">
            <div className="container d-block">
                <div className="row">
                    <div className="col-4 col-sm-4 text-left pl-3 navbar-top-div">
                        <small id={"taskCountText"}>{`${getString(strings.Tasks)}0`}</small>
                    </div>
                    <div className="col-4 col-sm-4 text-center navbar-top-div">
                        <small id={"taskPickedDate"}>{nowDate}</small>
                    </div>
                    <div className="col-4 col-sm-4 text-right pr-3 navbar-top-div">
                        <small>{getStaffName()}</small>
                    </div>
                </div>
            </div>
        </nav>

        <div id={"TasksHolder"} className={"mt-5 mb-8-5"}/>
    </>);
}

export default GetMainForm;
