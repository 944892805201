import React from "react";
import {Modal} from "react-bootstrap";
import {useState} from "react";
import {render} from "react-dom";
import {Button} from "react-bootstrap";
import {getString, strings} from "../../Languages/Languages";

function PowerHistoryDialog(props)
{
    return (
        <Modal show={props.show} onHide={props.onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body>
                <div className={"signalsDialog"}>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">{getString(strings.From)}</th>
                                <th scope="col">{getString(strings.To)}</th>
                                <th scope="col">{getString(strings.Db)}</th>
                            </tr>
                        </thead>
                        <tbody id={"SignalPowersT"}/>
                    </table>
                </div>
                <Button onClick={() => {props.onHide();}}>Ok</Button>
            </Modal.Body>
        </Modal>
    );
}

function GetDialog() {
    const [modalShow, setModalShow] = useState(true);

    return(
        <>
            <PowerHistoryDialog show={modalShow}
                                onHide={() => setModalShow(false)}/>
        </>);
}

async function ShowPowerHistoryDialog()
{
    await render (<GetDialog/>, document.createDocumentFragment());
}
export {ShowPowerHistoryDialog};
