import { NotificationManager } from 'react-notifications';
//Кнопка копирования на странице Абонента
export default function copyToClipBoard(text) {
  navigator.clipboard.writeText(text).then(() => {
    return NotificationManager.info('Текст скопирован', text, 2000);
  })
      .catch((error) => {
        console.log(error)
      });
}



