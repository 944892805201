import React from "react";
import {Modal} from "react-bootstrap";
import {useState} from "react";
import {render} from "react-dom";
import {Button} from "react-bootstrap";
import {getString} from "../../Languages/Languages";
import {strings} from "../../Languages/Languages";
import axios from "axios";
import {User} from "../../User/User";

function saveFilter(name)
{
    User.filter = name;
}
function getCatalogState()
{
    const url = 'https://user.intronex.ru/api.php';

    const json = new FormData();
    json.append('key', '159753511');
    json.append('cat', 'task');
    json.append('action', 'get_catalog_state');

     axios.post(url, json, {headers: {'Content-Type': 'application/json'}}).then(net => {
        let catalog = net.data.Data
        for (let aaaCatalog in  catalog) {
            let catalogState = catalog[aaaCatalog].name

            let catalogStateId = catalog[aaaCatalog].id

            let result = document.querySelector('#dropdown-menuId');
            result.innerHTML += `<a id=${catalogStateId} class="dropdown-item1" data-value="dropdown-items1">${catalogState}</a>`
        }

        document.addEventListener("click", function (e) {
            if (e.target.dataset.value === "dropdown-items1") {
                document.querySelector("#dropdownMenuButton1").innerHTML = e.target.textContent;

                let elements = document.getElementsByClassName("dropdown-item1"); //тут айди

                let s = document.querySelector("#dropdownMenuButton1").innerHTML //текст

                let select = 0;
                for (const elementKey in elements) {
                    if (elements[elementKey].id === undefined)
                    {
                    }
                    else
                    {
                        select++;
                    }
                }
                for (let i = 1; i < select; i++){
                    if (catalog[elements[i].id].name === s)
                    {
                        User.catalog = catalog[elements[i].id].id
                    }
                }

            }
        });
    })
         .catch((error) =>{
         console.log(error)
});
}

function SelectTheNumberOfTasks ()
{
    document.addEventListener("click", function (e) {
        if (e.target.dataset.value === "dropdown-item2") {
            document.querySelector("#dropdownMenuButton2").innerHTML = e.target.textContent;
            let namber = e.target.textContent
            if (namber === getString(strings.All)){
                namber = ''
            }
            User.amount = namber
        }
    });
}

function Filter(props)
{
    return (
        <Modal show={props.show} onHide={props.onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body>
                <div>
                    <label className={"SelectDivisionLables"}>{getString(strings.SelectTheStatus)}</label>
                                         <div className="dropdown">
                                             <button onClick={() => getCatalogState()} className="btn btn-secondarys dropdown-toggle" type="button" id="dropdownMenuButton1"
                                                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                             </button>
                                             <div  id={"dropdown-menuId"} className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                             </div>
                                         </div>
                                             <br/>
                                         <label className={"SelectDivisionLables"}>{getString(strings.SelectTheNumberOfTasks)}</label>
                                         <div className="dropdown">
                                             <button onClick={() => SelectTheNumberOfTasks()} className="btn btn-secondaris dropdown-toggle" type="button" id="dropdownMenuButton2"
                                                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                             </button>
                                             <div id={'NamberId'} className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                 <a name="filter"  className="dropdown-item2" data-value="dropdown-item2">{getString(strings.All)}</a>
                                                 <a name="filter"  className="dropdown-item2" data-value="dropdown-item2">10</a>
                                                 <a name="filter"  className="dropdown-item2" data-value="dropdown-item2">25</a>
                                                 <a name="filter"  className="dropdown-item2" data-value="dropdown-item2">50</a>
                                             </div>
                                         </div>
                    <br/><br/>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className={"ObserversClose"} onClick={() => {props.onHide();localStorage.removeItem('catalog'); localStorage.removeItem('amount')}}>{getString(strings.Cancel)}</Button>
                <Button className={"btn btn-dangerCancelTask"} onClick={() => {props.onHide();props.onApplyclick();localStorage.removeItem('catalog')}}>{getString(strings.Apply)}</Button>
            </Modal.Footer>
        </Modal>
    );
}

function GetDialog(props) {
    const [modalShow, setModalShow] = useState(true);

    return(
        <>
            <Filter onApplyclick={props.onApplyclick}
                          show={modalShow}
                          onHide={() => setModalShow(false)}
            />
        </>);
}

function ShowFilter(onApplyClick)
{
    render (<GetDialog onApplyclick={onApplyClick}/>,
        document.createDocumentFragment());
}
export {ShowFilter};