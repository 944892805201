import React from "react";
import taskDateIcon from '../../../../Resources/Icons/AboutTask/calendar2.png';

export default function TaskDate(props) {

  return (
    <div className="col-sm-6 col-6 px-1">
      <label className="float-left text-primary mb-0">{props.taskDate}</label>
      <div className="input-group mb-3">
        <div className="input-group-prepend">
          <span className="input-group-text bg-primary"><img src={taskDateIcon} alt={taskDateIcon} width="20px" /></span>
        </div>
        <input type="text" className="bg-light  form-control smallText" defaultValue={props["Date"]} disabled />
      </div>
    </div>
  )
}