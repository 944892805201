import React from "react";
import {getString, strings} from "../../../../Languages/Languages";

export default function CounterFieldType(props) {
  return (
    <>
      <label className="mr-sm-2">{props.name}</label>
      <input type="number" className="form-control" onChange={props.onChange} name={props.id} placeholder={strings.IndicationsLast} />
      <label className="mr-sm-2" style={{ fontSize: '0.8em' }}>{getString(strings.IndicationsNew)}</label>
    </>

  )
}