import React from "react";

export default function TextFieldType(props) {
  return (
    <>
      <label className="mr-sm-2">{props.name}</label>
      <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" onChange={props.onChange} name={props.id} />
    </>

  )
}