import React, {useState, useEffect} from "react";
import backArrow from "../Resources/Icons/back-arrow.svg";
import {ShowDialog} from "../Utils/Dialog/Dialog";
import {getString, strings} from "../Languages/Languages";
import {ServerApi} from "../Server/ServerApi";
import {ShowLoading, CloseLoading} from "../Utils/Dialog/LoadingDialog";
import {User} from "../User/User";

let date = new Date();
let startDate = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + '01';
let lastDayDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
let finalDate = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + lastDayDate.toLocaleString('RU', {day: 'numeric'});
const serverApi = new ServerApi();
const key_pril = User.keyPril;
const division_id = User.divisionId;
const employee_id = User.employeeId;

function Exit() {
    window.open("/main", '_self', false);
}

//TODO: убрать из подсчётов задания без оплаты

export default function Worksheet() {
    const [perfState, setPerfomedState] = useState('')
    const [noPerfState, setNoPerfomedState] = useState('')
    // ShowLoading();
    const f = (net) => {
        document.getElementById('jobTypesID').hidden = true
        document.getElementById('JobStatisticsID').hidden = true
        let tasksCount = net.count
        let tasksList = net.list;

        if (tasksCount.count < 2) {

            ShowDialog({
                'title': getString(strings.Attention),
                'body': getString(strings.YouNotTasksStatistic),

                'pButtonClick': function () {
                },

                'pbtn': 'true',
                'nebtn': 'false',

                'pbtntitle': getString(strings.Ok),
            });
            return;
        }
        document.getElementById('jobTypesID').hidden = false;
        document.getElementById('JobStatisticsID').hidden = false;

        serverApi.ShowEmployee(tasksList).then((tasksResponse) => {

            let tasks = tasksResponse;

            serverApi.getCatalogType().then((typeResponse) => {
                console.log(tasksResponse);
                let workersText = "employee";
                let allCatalogTypesResp = typeResponse.data;
                let stateNames = [];
                let array = [];
                let allPayment = [];
                let amountPayment = [];
                let names;

                for (let key in tasks) {
                    stateNames.push(tasks[key].data.state.name);
                    let taskTypeId = tasks[key].data.type.id;
                    let task = tasks[key].data;
                    let type = allCatalogTypesResp[taskTypeId];
                    let array_elements = type.name;

                    array.push(array_elements);
                    const makeUniq = (arr) => [...new Set(arr)];
                    names = makeUniq(array);

                    let division
                    if (task["volumeCustom"] !== '') {
                        let volumeCustom = Number(task["volumeCustom"])
                        if (volumeCustom === 0) {
                            volumeCustom = 1;
                        }
                        let amount = Number(type.amount);
                        let multiply = volumeCustom * amount;
                        let employee = task.staff[workersText];
                        let countEmployee = Object.entries(employee).length;
                        division = multiply / countEmployee;
                    } else {
                        let amount = Number(type.amount);
                        let employee = task.staff[workersText];
                        let countEmployee = Object.entries(employee).length;
                        division = amount / countEmployee;
                    }
                    allPayment.push(division);
                    amountPayment.push({
                        summ: division,
                        firstName: array_elements,
                        id: type.id
                    });
                }

                let count = [];
                let numberItems;
                for (let a in names) {
                    numberItems = names[a];
                    document.getElementById('nameId').innerHTML += `<div class="columns2">${numberItems}</div>`;

                    let array2 = [];
                    for (let i in array) {
                        if (array2[array[i]] != undefined) {
                            (array2[array[i]]++)
                        } else {
                            (array2[array[i]] = 1)
                        }
                    }
                    let amountPaymentWork = [];
                    for (let m in amountPayment) {
                        if (amountPayment[m].firstName == numberItems) {
                            amountPaymentWork.push(amountPayment[m].summ);
                        }
                    }

                    function arraySum(array) {
                        let sum = 0;
                        for (let i = 0; i < array.length; i++) {
                            sum += array[i];
                        }
                        document.getElementById('SummaID').innerHTML += `<div class="columns3">${sum}</div>`;
                    }

                    arraySum(amountPaymentWork);

                    count.push(array2[numberItems])
                    document.getElementById('QuantityID').innerHTML += `<div class="columns3">${array2[numberItems]}</div>`;
                }
                let sumOfNumbers = count.reduce((acc, number) => acc + number, 0);
                console.log(sumOfNumbers)
                setPerfomedState(sumOfNumbers);
                document.getElementById('countId').innerHTML += `<div class="columns3">${sumOfNumbers}</div>`;

                let afterDecimalPoint = allPayment.reduce((acc, number) => acc + number, 0);
                document.getElementById('totalSumId').innerHTML += `<div class="columns3">${String(afterDecimalPoint.toFixed(2))}</div>`;
            })
        })

    }
useEffect(() => {
    serverApi.getTaskListFinishState(employee_id, key_pril, startDate, finalDate, '2').then(net => {
        f(net)
    })
}, [])

    // serverApi.getTaskListFinishState(employee_id, key_pril, startDate, finalDate, '18').then(response => {
    //     console.log(response)
    // })

    const rating = (props) => {
        console.log(props)
        let employeesId = ''
        let employees;
        let perf;
        let noPerf

        serverApi.getMonthDivisionPerformed(division_id, startDate, key_pril, '2,8,12,19,23,27,28,29,30,31,32,35,36,37').then((response) => {
            console.log("Всего в division_28: " + response.count + " выполненных заданий с начала месяца")
            perf = response.count;
        })

        serverApi.getMonthDivisionPerformed(division_id, '2022-08-01', key_pril, '1,3,4,5,6,9,10,11,13,15,17,18,20,21,22,34').then((response) => {
            console.log("Всего в division_28: " + response.count + " не выполненных заданий с начала месяца")
            noPerf = response.count;
        })
        serverApi.GetEmployeeDataAll(key_pril).then((dataResponse) => {
            for (let key in dataResponse.data) {
                employeesId += dataResponse.data[key].id;
                employeesId += ','
            }
            employees = employeesId.substring(0, employeesId.length - 1);

            serverApi.getMonthEmployeeList(employee_id, startDate, key_pril).then((response) => {


                serverApi.ShowEmployee(response.list).then((tasks) => {
                    let performed = []
                    let noPerformed = []
                    for (let key in tasks) {
                        let role = tasks[key].data.state.systemRole;
                        if (role == 6 || role == 8) {
                            performed.push(role)
                        }
                        if (role == 4 || role == 1 ||
                            role == 2 || role == 7 ||
                            role == 5) {
                            noPerformed.push(role)
                        }
                    }
                    if (performed.length != 0) {
                        document.getElementById('countStatusID').innerHTML += `<div class="columnsState">${props}</div>`
                        document.getElementById('StatusID').innerHTML += `<div class="colState">Выполнено</div>`
                        document.getElementById('ratingPerformed').innerHTML += `<div class="columnsState">${props + ' из ' + perf}</div>`
                    }

                    if (noPerformed.length != 0) {
                        document.getElementById('countNoStatusID').innerHTML += `<div class="columnsState">${noPerformed.length}</div>`
                        document.getElementById('StatusNoID').innerHTML += `<div class="colState3">Невыполнено</div>`
                        document.getElementById('ratingNoPerformed').innerHTML += `<div class="columnsState">${noPerformed.length + ' из ' + noPerf}</div>`
                    }
                })
            })
        })
    }
if(perfState !== '') {
    rating(perfState)
}



    return (
        <div>
            <div id={'jobTypesID'}>
                <div className="sectionCatalog">Типы заданий</div>
                <div className="container">
                    <div id={'LoadingHolder'}></div>
                    <div className="row align-items-start nnnn">
                        <div className="col">
                            <div className={'divCol2'}>Наименование</div>
                            <div id={'nameId'}>
                            </div>
                            <br/>
                            <div className={'columns4'}>
                                Всего
                            </div>
                        </div>
                        <div className="col">
                            <div className={'divCol'}>Кол-во заданий</div>
                            <div id={'QuantityID'}>
                            </div>
                            <br/>
                            <div id={'countId'}>
                            </div>
                        </div>
                        <div className="col">
                            <div className={'divCol'}>Сумма</div>
                            <div id={'SummaID'}>
                            </div>
                            <br/>
                            <div id={'totalSumId'}>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id={'JobStatisticsID'}>
                <div className="sectionCatalog">Статистика заданий</div>
                <div className="container">
                    <div className="row align-items-start nnnn">
                        <div className="col divCol">
                            <div className={'divCol2'}>Статус</div>
                            <div id={'StatusID'}></div>
                            <div id={'StatusNoID'}></div>
                        </div>
                        <div className="col divCol">
                            <div className={'divCol'}>Кол-во заданий</div>
                            <div id={'countStatusID'}></div>
                            <div id={'countNoStatusID'}></div>

                        </div>
                        <div className="col divCol">
                            <div className={'divCol'}>Рейтинг</div>
                            <div id={'ratingPerformed'}></div>
                            <div id={'ratingNoPerformed'}></div>

                        </div>

                    </div>
                </div>
            </div>

            <br/><br/><br/>
            <nav className="navbar fixed-bottom navbar-light bg-white shadow-lg">
                <div className="float-left">
                    <a id={"backBtn"} onClick={() => Exit()}
                       className="navbar-brand text-primary mx-2"><img src={backArrow} alt={backArrow} width={"25px"}/></a>
                </div>
            </nav>
        </div>
    );
}





