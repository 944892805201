import React, { useState } from "react";

import qrBarCodeIcon from '../../../../../Resources/Icons/Cards/qr_bar_code.png';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import QuaggaScanner from './QuaggaScanner/src/components/QuaggaScanner';
import Quagga2Scanner from "./Quagga_2_Scanner/Quagga_2_Scanner";
import Html5QrcodePlugin from "./Html5QrcodePlugin";
// import ReactQrBarcodeScanner from "./ReactQrBarcodeScanner";
import { faBarcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export default function QrBarCodeIconFunc(props) {

  const [res, setRes] = useState('')

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleClose2 = () => setShow2(false);
  const handleClose3 = () => setShow3(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const handleShow2 = () => setShow2(true);
  const handleShow3 = () => setShow3(true);




  const onNewScanResult = (decodedText, decodedResult) => {
    setRes(decodedText)
      window.open(decodedText, '_self', false)
  }

  return (
    <>
      {/* Кнопка вызова сканера */}
      <div
        onClick={handleShow}
        className="col-md-4 col-sm-6 col-6 px-0">
        <div className="card border mx-1 my-1">
          <div className="card-body text-height-0-5 p-1" >
            <img width={"70px"} src={qrBarCodeIcon} className="text-primary display-1" data-toggle="modal" data-target="#exampleModal" alt={qrBarCodeIcon} />
            <p className={"m-0"}>{props.scaning}</p>
            <p className={"m-0"}>{props.qrBarCode}</p>
          </div>
        </div>
      </div>
      {/* Модальное окно с меню сканера */}
      <Modal show={show} onHide={handleClose}
        style={{
          marginTop: '10vh',
          // paddingLeft: 0
          // height: '100vh'
        }}>
        <div className="choose_scaner_container"
          style={{
            display: 'flex'
          }}>
          {/*Qr-код_кнопка*/}
          <div className="card border mx-1 my-1"
            onClick={handleShow1}
            style={{
              width: '50%',
              cursor: 'pointer',
              alignItems: 'center'
            }}>
            <div className="card-body text-height-0-5 p-1" >
              <img width={"70px"} src={qrBarCodeIcon} className="text-primary display-1" data-toggle="modal" data-target="#exampleModal" alt={qrBarCodeIcon} />
              <p className={"m-0"}>{props.scaning}</p>
              <p className={"m-0"}>Qr-код</p>
            </div>
          </div>
          {/* штрих-код_кнопка1*/}
          <div className="card border mx-1 my-1"
            onClick={handleShow2}
            style={{
              width: '50%',
              cursor: 'pointer',
              alignItems: 'center'
            }}>
            <div className="card-body text-height-0-5 p-1" >
              <FontAwesomeIcon icon={faBarcode}
                style={{
                  width: "70px",
                  height: "70px",
                  color: '#007bff'
                }} />
              <p className={"m-0"}>{props.scaning}</p>
              <p className={"m-0"}>Bar-код</p>
            </div>
          </div>
          {/* штрих-код_кнопка2*/}
          {/* <div className="card border mx-1 my-1"
            onClick={handleShow3}
            style={{
              width: '50%',
              cursor: 'pointer',
              alignItems: 'center'
            }}>
            <div className="card-body text-height-0-5 p-1" >
              <FontAwesomeIcon icon={faBarcode}
                style={{
                  width: "70px",
                  height: "70px",
                  color: '#007bff',

                }} />
              <p className={"m-0"}>{props.scaning}</p>
              <p className={"m-0"}>Bar-код2</p>
            </div>
          </div> */}
          {/* Qr-код_компонент*/}
          <Modal show={show1} onHide={handleClose1}
            style={{
              // marginTop: '10vh',
            }}>
            <Html5QrcodePlugin
              fps={10}
              qrbox={250}
              disableFlip={false}
              qrCodeSuccessCallback={onNewScanResult} />
            <p className={"m-0"}>{res}</p>
            <Button variant="secondary" onClick={handleClose1}>Close</Button>
          </Modal>
          {/*Bar-код_компонент*/}
          <Modal show={show2} onHide={handleClose2}
            style={{
              marginTop: '10vh',
            }}>
            <QuaggaScanner
              handleClose={handleClose2}
            />
          </Modal>
          {/* Третий сканер Bar-code про-запас (работает на моём телефоне)*/}
          <Modal show={show3} onHide={handleClose3}
            style={{
              marginTop: '10vh',
            }}>
            <Quagga2Scanner
              handleClose={handleClose3} />
          </Modal>
        </div>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal>

    </>

  )
}