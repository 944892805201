import React from "react";
import taskOnPointIcon from '../../../../Resources/Icons/AboutTask/hub.png';

export default function OnPoint(props) {

  return (
    <div id={"onPointHolder"} className="col-sm-12">
      <label className="float-left text-primary mb-0">{props.taskOnPoint}</label>
      <div className="input-group mb-2">
        <div className="input-group-prepend">
          <span className="input-group-text bg-primary"><img src={taskOnPointIcon} alt={taskOnPointIcon} width="20px" /></span>
        </div>
        <div id={"onPoint"} className={"form-control h-auto smallText card d-block border-1  px-2 py-2"} />
      </div>
    </div>
  )
}