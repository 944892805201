import {getTasks, getTasksState} from "../Task/Spawners/ShortTasksSpawner";
import {switchFormAsync} from "../Utils/Utils";
import {formateDate} from "../Utils/String/StringUtils";
import {getString, strings} from "../Languages/Languages";
import backArrow from "../Resources/Icons/back-arrow.svg";
import {ShowFilter} from "../Utils/Dialog/FilterDialogShortTasks";
import {User} from "../User/User";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter} from "@fortawesome/free-solid-svg-icons";
import React from "react";



async function GetOverdueTasksForm(props)
{
    let dateTo = new Date();
    dateTo.setDate(dateTo.getDate() - 1);
    dateTo = formateDate(dateTo);

    switchFormAsync(
        <>
            <nav className="navbar fixed-top navbar-light bg-white shadow-lg navbar-top">
                <div className="container d-block">
                    <div className="row">
                        <div className="col-4 col-sm-4 text-left pl-3 navbar-top-div">
                            <small id={"taskCountText"}>{`${getString(strings.Tasks)}0`}</small>
                        </div>
                    </div>
                </div>
            </nav>
            <nav className="navbar fixed-bottom navbar-light bg-white shadow-lg">
                <div className="float-left"  onClick={() => window.open("/main", '_self', false)}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a id={"backBtn"}
                       className="navbar-brand text-primary mx-2"><img src={backArrow} alt={backArrow} width={"25px"}/></a>
                </div>
                <div className="float-right text-right">
                    <a type="button" onClick={() =>
                    {
                        ShowFilter(() =>
                        {
                            let catalogId = User.catalog
                            if(catalogId != '') {
                                getTasksState('', dateTo, catalogId).then(() => {});
                            }
                            if(catalogId == '') {
                                getTasks('', dateTo).then(() => {})
                            }
                        });
                    }
                    }
                       data-toggle="modal" data-target="#exampleModal"
                       className="navbar-brand text-primary mr-2" href="#"><FontAwesomeIcon icon={faFilter} /></a>
                </div>
            </nav>
            <div id={"TasksHolder"} className={"mt-5 mb-8-5"}/>
        </>
    ).then(() =>
    {
       getTasks('', dateTo).then(() => {}); //dateTo выводит задания под вчерашним днем
    });
}
export {GetOverdueTasksForm};
