import React from "react";
import { ServerApi } from "../../../../Server/ServerApi";
import downloadIcon from '../../../../Resources/Icons/download.svg';
import refreshIcon from '../../../../Resources/Icons/refresh.svg';
import { ShowPowerHistoryDialog } from "../../../../Utils/Dialog/SignalPowerHistoryDialog";
import { User } from "../../../../User/User";


export default function SignalPowerHistory(props) {
  let serverApi = new ServerApi();

  const onClick = () => {
    let namId = User.namId
    document.getElementById('downloadImage').innerHTML = `<img class="objRotate" src=${refreshIcon} alt=${refreshIcon} width="20px"/>`;

    serverApi.getRxLevel(namId, 30).then(powers => {
      document.getElementById('downloadImage').innerHTML = `<img src=${downloadIcon} alt=${downloadIcon} width="20px"/>`;
      if (powers === undefined || powers.length === 0) return;

      ShowPowerHistoryDialog().then(() => {
        for (let power in powers) {
          document.getElementById('SignalPowersT').innerHTML +=
            `<tr><td>${powers[power]['From']}</td><td>${powers[power]['To']}</td><th>${powers[power]['Power']}</th></tr>`;
        }
      });
    })
  }

  return (
    <>
      <div id={"signalPowerHistoryHolder"} className="col-sm-12">
        <div onClick={onClick} className="input-group mb-2">
          <input type="text" className="bg-primary text-light form-control smallText" disabled defaultValue={props.taskSignalPowerHistory} />
          <div className="input-group-append">
            <span id={"downloadImage"} className="input-group-text"><img src={downloadIcon} alt={downloadIcon} width="20px" /></span>
          </div>
        </div>
      </div>
    </>

  )
}