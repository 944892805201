import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useState } from "react";
import { render } from "react-dom";
import { getString, strings } from "../../Languages/Languages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { MapInteractionCSS } from "react-map-interaction";
import { ServerApi } from "../../Server/ServerApi";
import { User } from "../../User/User";

let flag_dialog_about_onu = 0;
function ONUDialog(props) {

    let serverApi = new ServerApi();
    let namId = User.namId
    if (flag_dialog_about_onu == 0) {
        serverApi.GetOD(namId).then(result => {
            if (result.data != undefined && result.data.date_add != undefined && result.data.date_add != "") {
                let data = result.data
                document.querySelector('#DateAddId').innerHTML = data.date_add
                document.querySelector('#SerialNumberId').innerHTML = data.sn
                document.querySelector('#IFaceNumberId').innerHTML = data.iface_number
                document.querySelector('#DescriptionId').innerHTML = data.description
                document.querySelector('#ReasonOfflineId').innerHTML = data.reason_offline
            }
            // }
        })
            .catch((error) => {
                console.log(error)
            });
        flag_dialog_about_onu = 1;
    } else if (flag_dialog_about_onu == 1) {
        flag_dialog_about_onu = 0;
    }
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            // onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            id={"test1"}>
            <div className="modal-content">
                <Modal.Body>
                    <div>
                        <label className={'ONUDialogLabel'}>DateAdd:</label>
                        <div id="DateAddId" className="dropdown-item ONUDialogA">
                        </div>
                    </div>
                    <div>
                        <label className={'ONUDialogLabel'}>SerialNumber:</label>
                        <div id="SerialNumberId" className="dropdown-item ONUDialogA">
                        </div>
                    </div>
                    <div>
                        <label className={'ONUDialogLabel'}>IFaceNumber:</label>
                        <div id="IFaceNumberId" className="dropdown-item ONUDialogA">
                        </div>
                    </div>
                    <div>
                        <label className={'ONUDialogLabel'}>Description:</label>
                        <a id="DescriptionId" className="dropdown-item ONUDialogA">
                        </a>
                    </div>
                    <div>
                        <label className={'ONUDialogLabel'}>ReasonOffline:</label>
                        <div id="ReasonOfflineId" className="dropdown-item ONUDialogA">
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className="btn btn-dangerSendComments" data-dismiss="modal" aria-label="Close"
                        onClick={() => props.onHide()}
                    >{getString(strings.Close)}</Button>
                </Modal.Footer>
            </div>
        </Modal>
    );
}

function GetDialog() {
    const [modalShow, setModalShow] = useState(true);

    return (
        <>
            <ONUDialog
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>);
}

function ShowONUDialog() {
    render(<GetDialog />, document.createDocumentFragment());
}
export { ShowONUDialog };
