import React from "react";
import taskEndPointIcon from '../../../../Resources/Icons/AboutTask/router.png';

export default function EndPoint(props) {

  return (
    <div id={"endPointHolder"} className="col-sm-12">
      <label className="float-left text-primary mb-0">{props.taskEndPoint}</label>
      <div className="input-group mb-2">
        <div className="input-group-prepend">
          <span className="input-group-text bg-primary"><img src={taskEndPointIcon} alt={taskEndPointIcon} width="20px" /></span>
        </div>
        <div id={"endPoint"} className={"form-control h-auto smallText card d-block border-1  px-2 py-2"} />
      </div>
    </div>
  )
}