import React from "react";

export default function AbonPhone(props) {

  return (
    <div id={"phonesHolderDiv"} className="col-sm-12 text-left smallText">
      <div className="list-group">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className="list-group-item list-group-item-action disabled bg-primary text-light"
          tabIndex="-1" aria-disabled="true">
          {props.taskPhone}
        </a>
        <div id={"phonesHolder"} />
      </div>
    </div>
  )
}