import React from "react";
import {Modal} from "react-bootstrap";
import {useState} from "react";
import {render} from "react-dom";
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';

function CalendarDialog(props)
{
    return (
        <Modal show={props.show} onHide={props.onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body>
                <Calendar onClickDay={(day) => {props.ondayclick(day); props.onHide();}}/>
            </Modal.Body>
        </Modal>
    );
}

function GetDialog(props) {
    const [modalShow, setModalShow] = useState(true);

    return(
        <>
            <CalendarDialog ondayclick={props.ondayclick}
                            show={modalShow}
                            onHide={() => setModalShow(false)}/>
        </>);
}

function ShowCalendarDialog(onDayClick)
{
    render (<GetDialog ondayclick={onDayClick}/>, document.createDocumentFragment());
}
export {ShowCalendarDialog};
