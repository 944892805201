import React, {Component} from 'react';
import backArrow from "../Resources/Icons/back-arrow.svg";

let commentsAlreadyDownloaded = false;

function SupportArchive()
{
    return (
        <div>
            <nav className="navbar fixed-bottom navbar-light bg-white shadow-lg">
                <div className="float-left" >
                    <a id={"backBtn"} onClick={() => {window.history.back();commentsAlreadyDownloaded = false;}}
                       className="navbar-brand text-primary mx-2"><img src={backArrow} alt={backArrow} width={"25px"}/></a>
                </div>
                <div className="float-right text-right">
                    <a type="button"
                       className="navbar-brand text-primary mr-2" href="#">
                    </a>
                </div>
            </nav>
        </div>
    );
}
export default SupportArchive;