import React, {Component} from 'react';
import {YMaps, Map, Placemark, TypeSelector, ZoomControl, FullscreenControl, GeolocationControl, RouteButton} from 'react-yandex-maps';
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar} from "@fortawesome/free-solid-svg-icons";
import {ShowCalendarDialog} from "../Utils/Dialog/CalendarDialog";
import {formateDate} from "../Utils/String/StringUtils";
import {getString, strings} from "../Languages/Languages";
import {ServerApi} from "../Server/ServerApi";
import {User} from "../User/User";
import {CloseLoading, ShowLoading} from "../Utils/Dialog/LoadingDialog";
import {ShowTaskOnMapDialog} from "../Utils/Dialog/TaskOnMapDialog";

let commentsAlreadyDownloaded = false;

class TaskOnMap extends Component {
    state = {
        center: [55.595653, 38.472397],
        zoom: 10,
        type: 'yandex#map',
        bounds: undefined,
        range: 1000,
        coordinates: [],
        height: '500px',
        lon: 0.0,
        lat: 0.0,
        controls: [],
        id: '',
        types: '',
        date: '',
    }
    componentDidMount() {
        let serverApi = new ServerApi();
        let height = document.documentElement.clientHeight
        height = height - 85

        let cor;
        cor = [55.595653, 38.472397];
        let zoom;
        zoom = 10;

        let date = new Date();
        let dataA = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0')
        ShowLoading();
        serverApi.getTaskList(User.divisionId, User.employeeId, User.keyPril, dataA, dataA).then(result => {
                for (let aaaAddressId in result.tasks) {
                    let addressId = result.tasks[aaaAddressId].AddressId
                    serverApi.getAboonCoords(addressId).then((response) => {
                        CloseLoading();
                        cor = [response.lat,response.lon];
                        zoom = 15;
                        this.setState({lat: response.lat, lon: response.lon, cor, zoom});

                        for (let i = 0; i < result.tasks.length; i++) {
                            if (result.tasks[i].AddressId === addressId) {
                                let id = result.tasks[i].Id
                                let types = result.tasks[i].Type
                                let date = result.tasks[i].Date

                                document.addEventListener("click", function (event) {
                                    if (event.target.dataset.nam === 'onMap') {
                                        window.open("/about/" + id, '_self', false)
                                    }
                                    if (event.target.dataset.nam === 'Navigator') {
                                        navigator.geolocation.getCurrentPosition(function (position) {
                                            let latGPS = position.coords.latitude
                                            let lonGPS = position.coords.longitude
                                            document.location.href = "yandexmaps://maps.yandex.ru/?rtext=" + latGPS + "," + lonGPS + "~" + response.lat + "," + response.lon;
                                        });
                                    }
                                })
                                this.setState({id, types, date});
                            }
                        }
                    })
                }
            CloseLoading();
        })
            .catch((error) => {
                console.log(error)
            })
        this.setState({height, cor, zoom});
    }

    render() {
        return (
            <div className={"SubscriberMapForm"} >
                <YMaps query={{ apikey: '3261ce08-c60a-4114-96f8-ce820abf124a' }}>
                    <div>
                        <Map
                            //state={this.state}
                             state={{ center:this.state.cor, zoom: this.state.zoom,
                                       type: 'yandex#map',
                                       bounds: undefined}}
                             height={this.state.height}
                             width='100%'>
                            <ZoomControl options={{ float: 'right' }} />
                            <TypeSelector options={{ float: 'right' }} />
                            <Placemark
                                geometry={[this.state.lat, this.state.lon]}
                                options={{
                                    openBalloonOnClick: true,
                                }}
                                onClick={() => {ShowTaskOnMapDialog(() => {},this.state.id, this.state.types, this.state.date,
                                    <button data-nam='Navigator'
                                            className="MarketingButton">{getString(strings.Navigator)}</button>,
                                    <button data-nam="onMap" id="TaskOnMap"
                                            className="MarketingButton">{getString(strings.More)}</button>
                                )}}
                               />
                            <FullscreenControl />
                            <GeolocationControl options={{float: 'left'}}/>
                            <RouteButton options={{
                                float: 'right'
                            }} />
                        </Map>
                        <br/>
                        <div>
                            <Button onClick={() => {window.history.back();commentsAlreadyDownloaded = false;}}
                                    className={"YamapBack"}>
                                {getString(strings.Back)}
                            </Button>
                            <button type="button"
                                    className="btn btn-primary"
                                    onClick={() => {ShowCalendarDialog((day) =>
                                    {
                                        let id;let types;let date;
                                        let lat;let lon;
                                        let serverApi = new ServerApi();
                                        let pickedDate = formateDate(new Date(Date.parse(day)));
                                        ShowLoading();
                                        serverApi.getTaskList(User.divisionId, User.employeeId, User.keyPril, pickedDate, pickedDate).then(result => {
                                            // console.log(result)
                                            for (let aaaAddressId in result.tasks){
                                                let addressId = result.tasks[aaaAddressId].AddressId;
                                                serverApi.getAboonCoords(addressId).then((response) => {
                                                    lat = response.lat;
                                                    lon = response.lon;
                                                    let cor = [lat, lon];
                                                    let zoom = 15;
                                                    this.setState({lat: response.lat, lon: response.lon, cor, zoom});
                                                })
                                                for (let i = 0; i< result.tasks.length; i++){
                                                    if(result.tasks[i].AddressId === addressId){
                                                        id = result.tasks[i].Id
                                                        types = result.tasks[i].Type
                                                        date = result.tasks[i].Date
                                                    }
                                                }
                                            }
                                            document.addEventListener("click", function (event) {
                                                if (event.target.dataset.nam === 'onMap'){
                                                    window.open("/about/" + id, '_self', false)
                                                }
                                                if (event.target.dataset.nam === 'Navigator'){
                                                    navigator.geolocation.getCurrentPosition(function(position) {
                                                        let latGPS = position.coords.latitude
                                                        let lonGPS = position.coords.longitude
                                                        document.location.href = "yandexmaps://maps.yandex.ru/?rtext=" + latGPS + "," + lonGPS + "~" + lat +"," + lon;
                                                    });
                                                }
                                            })
                                            CloseLoading();
                                            this.setState({id, types, date});
                                        })
                                    })}}>
                                <FontAwesomeIcon icon={faCalendar} />
                            </button>
                        </div>
                    </div>
                </YMaps>
            </div>
        );
    }
}

export default TaskOnMap;