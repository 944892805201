import axios from "axios";

const send = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*',

  },
})

const sendRequest = async (url, json) => {

  const response = await send.post(url, json);

  console.log(`url - ${url}\n\njson - ${json}\n\nresponse - ${JSON.stringify(response.data)}`);

  return response.data;
}

export default sendRequest




