import './App.css';
import {User} from "./User/User";
import GetLoginForm from './Templates/LoginForm.js';
import GetCommutationVariants from './Templates/CommutationVariants.js';
import GetMainForm from "./Templates/MainForm.js";
import {formateDate} from "./Utils/String/StringUtils";
import { Routes, Route } from "react-router-dom"
import {GetAboutTaskForm} from "./Templates/AboutTaskForm.js";
import GetCommutationPhoto from "./Templates/Commutation";
import GetDivisionList from "./Templates/NewTaskForm";
import KnotCoupling from "./Templates/Maps/KnotCoupling";
import AbonMap from "./Templates/AbonMap";
import InfrastructureMap from "./Templates/InfrastructureMap";
import NewChildTask from "./Templates/NewChildTask";
import TaskOnMap from "./Templates/TaskOnMap";
import MarketingMap from "./Templates/MarketingMap";
import TaskCalendar from "./Templates/TaskCalendar";
import Supports from "./Templates/Supports";
import Worksheet from "./Templates/WorkSheet";
import TMCemployee from "./Templates/TMCemployee";
import SupportArchive from "./Templates/SupportArchive";
import ObjectsTask from "./Templates/ObjectsTask";
import SupportsAdd from "./Templates/SupportsAdd";
import SupportsMap from "./Templates/SupportsMap";
import SupportsInformation from "./Templates/SupportsInformation";
import MarketingMapInformation from "./Templates/MarketingMapInformation"
import FixingWorkingTime from "./Templates/Maps/FixingWorkingTime";
import AddressOnMap from "./Templates/AddressOnMap";
import AbonChoice from "./Templates/AbonChoice";



function App()
{
    if (formateDate(new Date()) !== User.lastSavedDate)
    {
        let savedTasksList = User.todayTasksList;

        if ((savedTasksList + '').indexOf(',') === -1)
        {
            User.removeAboutTask(savedTasksList);
        }
        else
        {
            let arr = (savedTasksList + '').split(',');
            for (let index in arr)
            {
                User.removeAboutTask(arr[index]);
            }
        }
    }



    return(
        <div className="App">
            <Routes>
                <Route path =""
                       element={<GetLoginForm/>} />
                <Route path ="login"
                       element={<GetLoginForm/>} />
                <Route path="app/main"
                       element={<GetMainForm/>} />
                <Route path="newtask"
                       element={<GetDivisionList/>} />
                <Route path="about/:taskI"
                       element={<GetAboutTaskForm/>} />
                <Route path="commutation/:taskI"
                       element={<GetCommutationVariants/>} />
                <Route path="commutationPhoto/:nodeId"
                       element={<GetCommutationPhoto/>} />
                <Route path="yamap"
                       element={<KnotCoupling/>} />
               <Route path="abonmap"
                       element={<AbonMap/>} />
                <Route path="Infrastructure"
                       element={<InfrastructureMap/>} />
                <Route path="childtask"
                       element={<NewChildTask/>} />
                <Route path="taskmap"
                       element={<TaskOnMap/>} />
                <Route path="abonchoice"
                       element={<AbonChoice/>} />
                <Route path="advertising"
                       element={<MarketingMap/>} />
                <Route path="advertising/information"
                       element={<MarketingMapInformation/>} />

                <Route path="taskcalendar"
                       element={<TaskCalendar/>} />

                <Route path="fixingworktime"
                       element={<FixingWorkingTime/>} />

                <Route path="worksheet"
                       element={<Worksheet/>} />
                <Route path="tmcemployee"
                       element={<TMCemployee/>} />

                <Route path="addressonmap"
                       element={<AddressOnMap/>} />

                <Route path="supports"
                       element={<Supports/>} />
                <Route path="supports/:archive"
                       element={<SupportArchive/>} />
                <Route path="supports/add"
                       element={<SupportsAdd/>} />
                <Route path="supports/add/map"
                       element={<SupportsMap/>} />
                <Route path="supports/information"
                       element={<SupportsInformation/>} />

                <Route path="objects"
                       element={<ObjectsTask/>} />
            </Routes>
        </div>
    );
}

export default App;
