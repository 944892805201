import React, {Component} from 'react';
import {User} from "../User/User";
import {Button, Form} from "react-bootstrap";
import {getString, strings} from "../Languages/Languages";

let commentsAlreadyDownloaded = false;
let array = JSON.parse(localStorage.getItem("customer"));
console.log(array)

class AbonChoice extends Component {
    state = {
    }

    componentDidMount() {
        for (let a in array) {
            let abonFIO = array[a].Fio
            let id = array[a].id
            let com = document.querySelector('#AbonChoiceID')
                com.innerHTML += `<div data-nam=${id} type="button" id="AbonChoiceId" class="AbonChoice">${abonFIO}</div>`
        }

        let handleClick = (event) => {
            console.log(event.target.dataset.nam)
            User.Customer = event.target.dataset.nam
            window.open("/abonmap",'_self',false)
        }

        let comClick = document.querySelectorAll('.AbonChoice')
        comClick.forEach(comClick => {
            comClick.addEventListener('click', handleClick)
        })
    }

    render() {
        return (
            <div>
                <div className={"CommutationForm"}>
                    <Form >
                        <br/>
                       {/*<div className={'choiceId'}>{getString(strings.SubscriberSelection)}:</div>*/}
                        <div id={'AbonChoiceID'}></div>
                        <br/><br/>
                        <Button type={"button"} onClick={() => {window.history.back();commentsAlreadyDownloaded = false;}}
                                className={"CommutationClose"}>{getString(strings.Close)}</Button>
                    </Form>
                </div>
            </div>
        );
    }
}
export default AbonChoice;

