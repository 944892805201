import React from "react";
import taskLoginIcon from '../../../../Resources/Icons/AboutTask/login.png';
import copyIcon from '../../../../Resources/Icons/copy.svg';
import copyToClipBoard from "../../functions/copyToClipBoard";

export default function AbonLogin(props) {


  return (
    <div id={"loginHolder"} className="col-sm-12">
      <label                                          //Логин в Абоненте
        className="float-left text-primary mb-0">{props.login}</label>
      <div className="input-group mb-3">
        <div className="input-group-prepend">
          <span className="input-group-text bg-primary"><img src={taskLoginIcon} alt={taskLoginIcon} width="20px" /></span>
        </div>
        <div id={"login"}
          className={"form-control h-auto smallText card d-block border-1  px-2 py-2"} />
        <div className="input-group-append"
          onClick={() => copyToClipBoard(document.getElementById('login').innerText)}>
          <span className="input-group-text"><img src={copyIcon} alt={copyIcon} width="20px" /></span>
        </div>
      </div>
    </div>
  )
}