// import {UserSideApi} from "../../UserSide/UserSideApi";
import {ServerApi} from "../../Server/ServerApi";
import {User} from "../../User/User";
import {render} from "react-dom";
import {GetShortTaskTemplate} from "../../Templates/Tasks/ShortTaskTemplate";
import {ShowSimpleDialog} from "../../Utils/Dialog/Dialog";
import {getString, strings} from "../../Languages/Languages";
import {formateDate} from "../../Utils/String/StringUtils";
import {CloseLoading, ShowLoading} from "../../Utils/Dialog/LoadingDialog";

async function getTasks(dateDoFrom, dateDoTo) {
    ShowLoading();
    let serverApi = new ServerApi();
    let tasks = [];
    let isOnline = navigator.onLine;
    if (isOnline) {
        let taskListData = {};
        let tasksAndListData = {};
        // let tasksAndListDataState = {};

            tasksAndListData = await serverApi.getTaskList(User.divisionId, User.employeeId, User.keyPril, dateDoTo, dateDoFrom);




        if (tasksAndListData["res"] === false) return;              //в массиве res
        //       console.log(tasksAndListData)
        taskListData = tasksAndListData["list"];                    // в массиве list
        tasks = tasksAndListData["tasks"];                         // в массиве tasks

        if (tasks !== null && tasks !== undefined)            //если таск не равно null и не равно undefined
            //   console.log(tasks)
        {
            let tasks_da = [];
            for (let j = 0; j < tasks.length; j++) {
                for (let i = 0; i < tasks.length; i++) {
                    if ((tasks[i]['Id'] + '') === (taskListData['list'].split(',')[j] + '')) //true


                    {
                        tasks_da.push(tasks[i]);  // задания количество 1,2,3,4 ....
                    }
                }
            }
            tasks = tasks_da;   //чисто task из массива выводят


        }

        // if (formateDate(dateDoFrom) === formateDate(new Date()) && formateDate(dateDoTo) === formateDate(new Date()))
        // {
        User.todayTasksList = taskListData['List'];   //Список задач на сегодня
        User.lastSavedDate = formateDate(new Date());   //дата последнего сохранения
        User.shortTasks = JSON.stringify(tasks);        //короткая задача


        // }
    } else {
        // if (formateDate(dateDoFrom) === formateDate(new Date()) && formateDate(dateDoTo) === formateDate(new Date()))
        // {
        try {
            tasks = JSON.parse(User.shortTasks);

        } catch (e) {
            console.error('No tasks - ' + e);
        }
        // }
    }

    let tasksTags = [];


    for (let task in tasks) {
        tasksTags.push(GetShortTaskTemplate(tasks[task])); //   GetShortTaskTemplate- где сам дизайн заданий
        //  console.log( tasksTags.push(GetShortTaskTemplate(tasks[task])))  //выводит 2,4,6,8,10,12,14  все задания по 2 штуки дублируются
    }
    let amount = User.amount;
    if (amount !== '') {
        let newArray = tasksTags.slice(0, amount)
        console.log(newArray)
        tasksTags = newArray
    }

    render(tasksTags, document.getElementById('TasksHolder'));


    let taskCount = tasksTags.length.toString(); // количество заданий в данном случае в просроченных 7, на основной 0

    if (taskCount === '0')  //если количество заданий равняется 0

    {
        document.getElementById('TasksHolder').innerText = ''; //пустота

        ShowSimpleDialog(getString(strings.Attention), getString(strings.NoTasksToDay));   //выводится окно внимвние На сегодня нет заданий


    }
    try {
        document.getElementById('taskCountText').innerText = `${getString(strings.Tasks)}${taskCount}`; //Заданий: 7 Заданий: 0
    } catch (e) {
    }

    CloseLoading();
    return tasks
}

export {getTasks};


async function getTasksState(dateDoFrom, dateDoTo, stateId) {

    ShowLoading();

    let serverApi = new ServerApi();
    let taskss = [];
    let isOnline = navigator.onLine;
    //  console.log(isOnline) ///true
    if (isOnline) {
        let taskListData = {};
        let tasksAndListDataState = {};

        tasksAndListDataState = await serverApi.getTaskListState(User.divisionId, User.employeeId, User.keyPril, dateDoTo, dateDoFrom, stateId);

        if (tasksAndListDataState["res"] === false) return;
        //       console.log(tasksAndListData)
        taskListData = tasksAndListDataState["list"];
        taskss = tasksAndListDataState["tasks"];

        if (taskss !== null && taskss !== undefined) {
            let tasks_da = [];
            for (let j = 0; j < taskss.length; j++) {
                for (let i = 0; i < taskss.length; i++) {
                    if ((taskss[i]['Id'] + '') === (taskListData['list'].split(',')[j] + '')) {
                        tasks_da.push(taskss[i]);
                    }
                }
            }
            taskss = tasks_da;
        }
        User.todayTasksList = taskListData['List'];
        User.lastSavedDate = formateDate(new Date());
        User.shortTasks = JSON.stringify(taskss);
    } else {
        try {
            taskss = JSON.parse(User.shortTasks);

        } catch (e) {
            console.error('No tasks - ' + e);
        }
    }

    let tasksTags = [];

    for (let task in taskss) {
        tasksTags.push(GetShortTaskTemplate(taskss[task]));
    }

    let amount = User.amount;
    if (amount !== '') {
        let newArray = tasksTags.slice(0, amount)
        console.log(newArray)
        tasksTags = newArray
    }

    render(tasksTags, document.getElementById('TasksHolder'));

    let taskCount = tasksTags.length.toString();
    console.log(taskCount)
    try {
        document.getElementById('taskCountText').innerText = `${getString(strings.Tasks)}${taskCount}`;
    } catch (e) {
    }

    CloseLoading();

}

export {getTasksState};

