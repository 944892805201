import React from "react";
import { ServerApi } from "../../../Server/ServerApi";
import { User } from "../../../User/User";
import { getString } from "../../../Languages/Languages";
import { strings } from "../../../Languages/Languages";
import AbonDataNetwork from "./AbonDataNetwork";
import { ShowLoading, CloseLoading } from "../../../Utils/Dialog/LoadingDialog";
import setData from "./setData";

const shortid = require('shortid');
// props['CustomerId'],

let FioId;
let taskData;
let CustomerID;

export default async function LoadAboutTaskData(props) {
  let serverApi = new ServerApi();
  document.getElementById('backBtn').hidden = true;
  document.getElementById('ObserversId').hidden = true;

  //Получение Объектов
  await serverApi.ShowEmployee(User.taskId).then(result => {
    if (result) {
      let objectId = String(result[0].data.cable_line_id)
      serverApi.list_lines(objectId).then(result1 => {
        for (let aaaobject in result1.data) {
          let objectName = result1.data[aaaobject]
          let nameA = objectName.object_a_name
          let nameB = objectName.object_b_name
          let code = objectName.code
          // console.log(objectName.path)

          document.querySelector('#objectsID').innerHTML +=
            `<button id='ObjectsButton' data-code=${code} type='button' class='ObjectsButton'>
                                               ${nameA + `<br/>` + '-  - -' + `<br/>` + nameB} 
                                            </button>`
        }
        let handleClick = (event) => {
          User.code = event.target.dataset.code
          window.open("/objects", '_self', false);
        }

        let objectClick = document.querySelectorAll('#ObjectsButton')
        objectClick.forEach(objectClick => {
          objectClick.addEventListener('click', handleClick)
        })
      })

    }

  })
      .catch((error) => {
        console.log(error)
      });


  let historyCount = props['HistoryCount'];
  let savedHistoryCount = parseInt(User.getAboutTaskHistoryCount(props['Id']));

  try {
    historyCount = parseInt(historyCount);
  } catch (e) {
    historyCount = 0;
  }

  let abonData;
  let addressData;
  let networkData;
  let otherData;
  if (historyCount >= savedHistoryCount) {
    User.setAboutTaskHistoryCount(props['Id'], historyCount)
    let CustomerFio;
    let size = '1'
    let Customer = props['Customer']
    for (let CustomerKey in Customer) {
      CustomerFio = Customer[CustomerKey].Fio
      console.log(CustomerFio)
      CustomerFio = getString(strings.Abon) + ' ' + CustomerFio.substr(0, size) + '.';
      FioId = Customer[CustomerKey].id

      let subscriber = document.querySelector('#myTab');
      subscriber.innerHTML += `<li key=${shortid.generate()} id='abonTabButton' class="nav-item">
                                         <a class="nav-link" id="home-tab" data-toggle="tab" href="#home" role="tab"
                                            aria-controls="home" aria-selected="true" 
                                            data-nam=${FioId}>${CustomerFio}</a>
                                     </li>`
    }

    let handleClick = (event) => {
      let namId = parseInt(event.target.dataset.nam)
      User.namId = namId

      for (let i = 0; i < Customer.length; i++) {
        if (Customer[i].id === namId) {
          document.getElementById('FIO').innerHTML = Customer[i].Fio
        }
      }
      props['taskData'] = taskData
      AbonDataNetwork(props);
    }

    let Vkladka = document.querySelectorAll('#abonTabButton')
    Vkladka.forEach(Vkladka => {
      Vkladka.addEventListener('click', handleClick)
    })


    let CustomerId = props['Customer']
    let id = '';
    for (let i = 0; i < CustomerId.length; i++) {
      id += CustomerId[i].id
      id += ','
    }
    CustomerID = id.substring(0, id.length - 1);

    ShowLoading();
    taskData = await serverApi.getTaskData(CustomerID, props['AddressId'], props["Id"], props['RelatedObjects'])

    /* crqr(1000,222,"RUB",User.taskId);*/

    User.houseId = props["AddressId"];
    User.taskId = props["Id"];
    abonData = taskData["abonData"];
    addressData = taskData["addressData"];
    networkData = taskData["networkData"];
    otherData = taskData["otherData"];
    CloseLoading();


  } else {
    let aboutTaskData = JSON.parse(User.getAboutTask(props['Id']));

    abonData = aboutTaskData['abonData'];
    addressData = aboutTaskData['addressData'];
    networkData = aboutTaskData['networkData'];
    otherData = aboutTaskData['otherData'];
  }
  setData(props["Id"], abonData, addressData, networkData, otherData);
}